import React, { Component } from 'react';
// ES module

import Modal from 'react-modal';
import { ApiService } from '../../services';
import loader from "../loading.gif";
import countries from "../RegisterComponents/countries";
import reportpng from '../../assets/img/icons/report.png';


class ProductComponents extends Component{
    constructor(props){
        super();

        this.state = {
            editting_product: false,
            fetch_error: false,
            products: [],
            list_products: true,
            country_code: '',
            org_no: '',
            level: '00',
            type: '00',
            adding_wallet: false,
            session: JSON.parse(localStorage.getItem("SESSION")),
            adding_bank: false,
            product_code: Math.floor(Math.random() * 1000),
            session: {
                bank: {
                    account_no: '',
                    bank_name: '',
                    bank_code: '',
                    sort_code: '',
                    route_number: ''
                },
                associated_banks: [],
                associated_wallets: [],
                associated_accounts: []
            },
            categories: [],
            associated_wallets: [],
            name: '',
            new_sort_code: '',
            new_route_no: '',
            callback: '',
            customStyles : {
                content : {
                  top                   : '50%',
                  left                  : '50%',
                  right                 : 'auto',
                  bottom                : 'auto',
                  marginRight           : '-50%',
                  transform             : 'translate(-50%, -50%)'
                }
            }

        }
    }

    componentDidMount(){
        this.setState({session:this.props.session});

        if(this.props.products){
            this.setState({products: this.props.products})
        }
        //this.listProducts();

        this.viewBanks(this.props.session.country_code)

        this.listCategories()
    } 

    componentDidUpdate(prevProps){
        if(prevProps.products!==this.props.products){
            this.setState({products: this.props.products}) 
        }
    }

    createWallet = (e) =>{
        e.preventDefault();

        const city = this.state.session.town;
        const name = this.state.session.reg_name;
        const zip_code = this.state.session.zip_code;
        const address = this.state.session.reg_address+" "+this.state.session.street_name+" "+this.state.session.town;
        const state = this.state.session.state;
        const merchant_id = this.state.session.merchant_id;
        const email = this.state.session.director.email;
        const currency_code = this.state.session.currency.numeric_code;
        const country_code = this.state.session.country_code;
        const country = this.state.session.currency.iso_code_2;
        let randomNumber = Math.floor(Math.random() * 1000000);
        let rand;
        if(randomNumber<10){
            rand = "00000"+randomNumber;
        }else if(randomNumber<100){
            rand = "0000"+randomNumber;
        }else if(randomNumber<1000){
            rand = "000"+randomNumber;
        }else if(randomNumber<10000){
            rand = "00"+randomNumber;
        }else if(randomNumber<100000){
            rand = "0"+randomNumber;
        }else{
            rand = randomNumber
        }


        //city,name,zip_code,address,state,merchant_id,email,currency_code,country_code
        const wallet_no = country_code+""+9999+rand;

        this.setState({adding_wallet: true})
        ApiService.createWallet(wallet_no,city,name,zip_code,address,state,merchant_id,email,currency_code,country_code,country)
        .then((response)=>{
            //console.log(response.response_message);
            /**if(response.response_code==0||response.response_code=="0"){
                ApiService.addWallet(merchant_id,wallet_no)
                .then((res)=>{
                    let session = this.state.session;

                    (session.associated_wallets).push(wallet_no);
                    this.setState({session: session});
                    localStorage.setItem('SESSION',JSON.stringify(session));
                    this.setState({new_success: 'Wallet Added',adding_wallet: false, modalIsOpen: false});
                },(error)=>{
                    let zerror = error.response? error.response.data.errors:error.toString();
                    this.setState({adding_wallet: false, new_error: zerror});
                })
                this.setState({new_error: response.response_message,adding_wallet: false});
                //add wallet to merchant on backend and on localStorage
            }else{
                this.setState({new_error: response.response_message,adding_wallet: false});
            }*/

            let session = this.state.session;
            (session.associated_accounts).push(response.data.data);
            this.setState({session: session});
            localStorage.setItem('SESSION',JSON.stringify(session));
                    
            this.setState({new_success: 'Wallet Added',adding_wallet: false, modalIsOpen: false});
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({adding_wallet: false, new_error: zerror});
        }); 
    }

    viewBanks = (country_code) => {
        ApiService.fetch_banks(country_code)
        .then((res)=>{
          console.log(res.data.data);
          if(res.data.data){
            this.setState({banks: res.data.data});
          }
        },(error)=>{
          console.log(error)
          let zerror = error.response? error.response.data.errors:error.toString();
          this.setState({banks_error: zerror, banks: false});
        })
    }

    createModulrAccount = (e) =>{
        e.preventDefault();
        this.setState({adding_wallet: true});
        ApiService.createModulrAcct(this.state.session.customer_id)
        .then((response)=>{
            console.log(response);
            if(response.data.data.response_message!==""){
                const data = {
                    account_id: response.data.data.account_id,
                    account_number: response.data.data.account.accountNumber,
                    currency: response.data.data.account.currency,
                    sortCode: response.data.data.account.sortCode
                }
                console.log(data)
                ApiService.addMerchantModulr(this.state.session.merchant_id,data)
                .then((response)=>{
                    let session = this.state.session;
                    
                    if(data.account_number){
                        (session.associated_accounts).push(data);
                        this.setState({session: session});
                        localStorage.setItem('SESSION',JSON.stringify(session));
                    }else{
                        setTimeout(()=>{
                            ApiService.fetchModulrAccount(data.account_id)
                            .then((response)=>{
                                (session.associated_accounts).push(response.data.data);
                                this.setState({session: session});
                                localStorage.setItem('SESSION',JSON.stringify(session));
                            });
                        },5000);
                    }

                    this.setState({new_success: 'Account Added',adding_wallet: false, modalIsOpen: false});
                },(error)=>{
                    let zerror = error.response? error.response.data.errors:error.toString();
                    this.setState({adding_wallet: false, new_error: zerror});  
                });
            }else{
                this.setState({adding_wallet: false, new_error: "Something Broke"}); 
            }
        },(error)=>{
            this.setState({adding_wallet: false, new_error: "Something Broke"}); 
        });
    }

    createCategory = (e)=>{
        e.preventDefault();
        const merchant_id = this.state.session.merchant_id;
        const category = this.state.category;

        this.setState({creating_category: true});
        ApiService.createCategory(merchant_id,category)
        .then((response)=>{
            let categories = this.state.categories;
            categories.push(response.data.data);
            this.setState({categories: categories, creating_category: false, category_msg: "Category Created"})
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({category_error: zerror, creating_category: false});
        });
    }

    createBank = (e)=>{
        if(this.state.new_acct_no && this.state.new_acct_no){
        e.preventDefault();

        this.setState({adding_bank: true});
        const country_code = this.state.session.country_code;
        const bank_code = this.state.new_bank_code;
        const bank_name = this.state.new_bank_name;
        const sort_code = this.state.new_sort_code;
        const route_no = this.state.new_route_no;
        const account_no = this.state.new_acct_no;
        const merchant_id = this.state.session.merchant_id;



        console.log(country_code+" , "+bank_code+" , "+bank_name+" , "+sort_code+" , "+route_no+" , "+account_no);

        ApiService.addBank(country_code,bank_code,account_no,bank_name,sort_code,route_no,merchant_id)
        .then((response)=>{
            let session = this.state.session;

            (session.associated_banks).push({
                country_code: country_code,
                bank_code: bank_code,
                account_no: account_no,
                bank_name: bank_name,
                sort_code: sort_code,
                route_number: route_no
            });
            this.setState({session: session});
            localStorage.setItem('SESSION',JSON.stringify(session));
            this.setState({new_success: 'Bank Added',adding_bank: false, modalIsOpen: false});
            //this.setState({adding_bank: false});
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({adding_bank: false, new_error: zerror});
        })
        }else{
            this.setState({new_error: "Fill All Fields"})
        }
    }

    openModal = (flag) =>{
        this.setState({modalIsOpen: true, flag: flag,new_acct_name: "", valid_bank: false, new_error:"", disable_add: false})
    }

    onChange = (e) =>{
        console.log(e.target.name)
        if(e.target.name!=="modulrAcct"){
            this.setState({[e.target.name]:e.target.value});
        }else{
            if(this.state.session.country_code==="044"){
                if(e.target.value==="-1"){
                    this.setState({[e.target.name]: this.state.session.account_details, modulrAcct_index: e.target.value})
                }else{
                    this.setState({[e.target.name]: this.state.session.associated_accounts[e.target.value], modulrAcct_index: e.target.value})
                }
            }else{
                this.setState({[e.target.name]: this.state.session.associated_accounts[e.target.value], modulrAcct_index: e.target.value})
            }
        }

        if(e.target.name==="wallet"){
            //alert(e.target.value)
            this.setState({[e.target.name]: e.target.value})

        }


        if(e.target.name==="display_for"){
            if(e.target.value==="xcelpos"){
                this.setState({display_error:"NOTE: only one product can be tagged as an XCelPOS Product"});
            }else if(e.target.value==="webpay"){
                this.setState({display_error:"NOTE: only one product can be tagged as an WebPay Product"});
            }else{
                this.setState({display_error:""});
            }
        }

        if(e.target.name === "new_country"){
            this.viewBanks(e.target.value);
        }

        if(e.target.name === "new_bank"){
            this.setState({new_bank_code: this.state.banks[e.target.value].bank_code, new_bank_name: this.state.banks[e.target.value].bank_name});
        }

        if(e.target.name === "new_sort_code"){
            this.setState({new_sort_code: e.target.value})
        }

        if(e.target.name === "new_bank_name"){
            this.setState({new_bank_name: e.target.value})
        }

        if(e.target.name==="type" && e.target.value!=="01"){
            this.setState({level: "00"})
        }

        if(e.target.name === "amount"){
            this.setState({amount: e.target.value})
        }

        if(e.target.name === "category_id"){
            this.setState({category_id: e.target.value})
        }

        if(e.target.name === "level"){
            this.setState({level: e.target.value})
        }
    }

    onChangeBank = (e) =>{
        if(e.target.value==="0"){
            console.log(e.target.value)
            this.setState({bank: this.state.session.bank});
        }else{
            this.setState({bank: this.state.session.associated_banks[parseInt(e.target.value)-1]})
        }
    }

    onChangeEdit = (e) =>{
        let product = this.state.edit_product;
        if(e.target.name === "name"){
            product.name = e.target.value;
            console.log(e.target.value);
            this.setState({edit_product: product});
        }else if(e.target.name === "bank"){
            //product.bank = e.target.value;
            if(e.target.value==="0"){
                product.bank = this.state.session.bank;
            }else{
                console.log(e.target.value);
                product.bank = this.state.session.associated_banks[parseInt(e.target.value)-1];
            }
            this.setState({product: product});
            //this.setState({edit_product: product});
        }else if(e.target.name === "desc"){
            product.product_description = e.target.value;
            this.setState({edit_product: product});
        }else if(e.target.name === "callback"){
            product.callback = e.target.value;
            this.setState({edit_product: product});
        }else if(e.target.name === "amount"){
            product.amount = e.target.value;
            this.setState({edit_product: product});
        }else if(e.target.name === "category_id"){
            product.category_id = e.target.value;
            this.setState({edit_product: product});
        }
    }

    /**onChangeEditBank = (e) =>{
        if(e.target.value==="0"){
            console.log(e.target.value)
            this.setState({edit_bank: this.state.session.bank});
        }else{
            console.log(e.target.value)
            this.setState({edit_bank: this.state.session.associated_banks[parseInt(e.target.value)-1],edit_bank_index:parseInt(e.target.value)-1})
        }
    }*/

    listProducts = () =>{
        ApiService.fetchProductss(this.props.session.merchant_id)
        .then((res)=>{
            console.log(res.data.data);
            if(res.data.data){
                this.setState({products: res.data.data});
            }
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({fetch_error: zerror});
        })
    }

    listCategories = () =>{
        ApiService.fetchCategories(this.props.session.merchant_id)
        .then((res)=>{
            console.log(res.data.data);
            if(res.data.data){
                this.setState({categories: res.data.data});
            }
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({categories:[]});
        })        
    }


    editProduct = (e)=>{
        e.preventDefault();
        this.setState({editting: true});
        const name = this.state.edit_product.name;
        const bank = this.state.edit_product.bank;
        const description = this.state.edit_product.product_description;
        const product_id = this.state.edit_product.product_id;
        const merchant_id = this.state.edit_product.merchant_id;
        const callback = this.state.edit_product.callback;
        const amount = this.state.edit_product.amount;
        const category_id = this.state.edit_product.category_id;

        console.log("boom!")
        ApiService.editProduct(name, bank, '',product_id, merchant_id,callback, category_id, amount)
        .then((res)=>{
            this.listProducts();
            this.setState({editting: false,list_products:true,edit_error: false,editting_product: false})
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({edit_error: zerror, editting: false});
        })
    }

    createProduct = (e)=>{
        e.preventDefault();
        this.setState({creating: true});
        //console.log(this.state.bank);
        const pmt_code = this.state.session.country_code+this.state.type+this.state.level+this.state.session.org_no+this.state.product_code;
        const name = this.state.name;
        const wallet = this.state.wallet;
        const bank = this.state.bank;
        const type = this.state.type;
        const level = this.state.level;
        const product_description = this.state.desc;
        let modulrAcct = this.state.modulrAcct;
        const product_no = this.state.product_no;
        const org_no = this.state.session.org_no;
        const country_code = this.state.session.country_code;
        const session_id = this.state.session.session_id;
        const merchant_id = this.state.session.merchant_id;
        const callback = this.state.callback;
        const amount = this.state.amount;
        const category_id = this.state.category_id;
        const display_for = this.state.display_for;

        if(modulrAcct===undefined){
            modulrAcct = {
                account_id: "",
                accountNumber: "",
                currency: "",
                sortCode: ""
            }
        }
        console.log(modulrAcct);
        //country_code, org_no, name, wallet,bank, type, level, pmt_code, product_description,product_no, merchant_id,modulrAcct
        ApiService.createProduct(country_code, org_no, name, wallet,bank, type, level, pmt_code, '',product_no, merchant_id, modulrAcct, callback, category_id, amount,display_for)
        .then((res)=>{
            this.listProducts(); 
            this.setState({name:"",wallet:"",bank:"",level:"",creating: false,list_products:true,create_error: false, product_code: Math.floor(Math.random() * 1000)})
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({create_error: zerror, creating: false});
        })
    }


    validateBank = (e) =>{
        e.preventDefault();
        //alert("Validate Bank");
        this.setState({modalIsOpen: true, adding_bank: true})
        ApiService.validate_account(this.state.new_acct_no,this.state.new_bank_code)
        .then((res)=>{
            //let data = JSON.parse(res.data)
            if(res.data.response_code===0 || res.data.response_code==="0"){
                console.log(res.data)

                this.setState({new_acct_name: res.data.response_message, valid_bank: true,new_error: false, adding_bank: false, disable_add: true})
            }else{
                this.setState({new_error: res.data.response_message,new_acct_name: false, adding_bank: false});
            }
        },(error)=>{
            console.log(error);
            this.setState({new_error: "Error Validating Bank",new_acct_name:false, adding_bank: false});
        })
    }



    render(){
        //console.log(this.state.session.merchant_id);
        let wallets, banks, products, categories;
        console.log(this.state.session);
        if(this.state.session.country_code!=="044" && this.state.session.country_code!=="001"){
            if(Object.keys(this.state.session.associated_accounts).length>0){
                wallets = this.state.session.associated_accounts.map((wallet,index)=>{
                    return <option value={index}>{wallet.account_number}</option>
                });
            }
        }else{
            console.log(this.state.session.associated_accounts)
            if(Object.keys(this.state.session.associated_accounts).length>0){
                wallets = this.state.session.associated_accounts.map((wallet,index)=>{
                return <option value={index}>{wallet.account_number} -- {wallet.sortCode}</option>
                });
            }            
        }

        if(Object.keys(this.state.categories).length>0){
            categories = this.state.categories.map((category,index)=>{
                return <option value={category.category_id}>{category.category}</option>
            })
        }

        if(Object.keys(this.state.session.associated_banks).length>0){
            banks = this.state.session.associated_banks.map((bank,index)=>{
            return <option value={index+1}>{bank.account_no} -- {bank.sort_code} ({bank.bank_name})</option>
            });
        }

        if(Object.keys(this.state.products).length>0){
            products = this.state.products.map((product, index)=>{
                return <tr>
                    <td className="whiteBG">{index+1}</td>
                    <td><b>{product.name}</b></td>
            {this.state.session.country_code==="044"?<td>{product.account.accountNumber} -- {product.account.sortCode} {product.account.currency}</td>:<td>{product.wallet}</td>}
                    <td>({product.bank.bank_name}) {product.bank.account_no} {product.bank.sort_code? "--": null} {product.bank.sort_code}</td>
                    <td>{product.amount || "Not Set"}</td>
                    <td>
                        <center>
                        <a onClick={()=>{this.setState({editting_product: true, edit_product: this.state.products[index]})}}>
                            <big><i className="fa fa-fw fa-pencil"></i></big>
                        </a>
                        </center>
                    </td>
                </tr>
            })
        }

        let edit_bank_index;
        if(this.state.edit_product){
            if(this.state.session.bank.account_no == this.state.edit_product.bank.account_no && this.state.session.bank.bank_code == this.state.edit_product.bank.bank_code){
                console.log(1);
                edit_bank_index= 0;
            }else{
                console.log(2);
                banks = this.state.session.associated_banks.map((bank,index)=>{
                    if(bank===this.state.edit_product.bank){
                        edit_bank_index= index+1;
                    }
                })  
            }
        }

        let countriesList = countries.map((country, index)=>{
            if(country.calling_code!==null){
              return <option value={country.calling_code}>{country.country}</option>
            }else return ""
        });
        
        let banksList;
        if(this.state.banks){
          banksList= this.state.banks.map((bank, index)=>{
              return <option value={index}>{bank.bank_name}</option>
          });
        }
        
        return<div className="p-3"> <div className="row">
        <div className="col-lg-2">
            <br/><br/>
            <ul className="list-group list-group-divider faq-tabs">
              <li className="list-group-item" onClick={()=>{this.setState({list_products: true,editting_product: false,edit_product:false,create_category: false})}}>
                <a href="javascript:void(0);" data-toggle="tab"><i className="badge-point badge-primary mr-3"></i>View {!this.state.session.p_name?'Products':this.state.session.p_name}</a>
              </li>
              <li className="list-group-item" onClick={()=>{this.setState({list_products: false,editting_product: false,edit_product:false, create_category: false})}}>
                <a className="active" href="javascript:void(0);" data-toggle="tab"><i className="badge-point badge-warning mr-3"></i>Create {!this.state.session.p_name?'Products':this.state.session.p_name}</a>
              </li>
              <li className="list-group-item" onClick={()=>{this.setState({list_products: false,editting_product: false,edit_product:false, create_category: true})}}>
                <a className="active" href="javascript:void(0);" data-toggle="tab"><i className="badge-point badge-info mr-3"></i>Create Category</a>
              </li>
              
              <br/>
            </ul>
        </div>
        <div className="col-lg-10">
            <div className="p-3">
                {this.state.editting_product?<div>
                    <div className="row">
                        <div className="col-8">
                            <h4><b>View {!this.state.session.p_name?'Products':this.state.session.p_name}</b></h4>
                            <small><label>Products your customers can interact with on our platforms</label></small>
                        </div>
                    </div>
                    <div className="float-right mr-3"></div>
                    <div className="ibox-body tab-content solidBorder">

                        <div>
                        <form onSubmit={this.editProduct}>
                            <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Product Name</label>
                            <input name="type" type="text" placeholder="Product Name" name="name" onChange={this.onChangeEdit} value={this.state.edit_product.name} className=" form-control "/>    
                            </div>
                            <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Bank Account</label>
                            <select className=" form-control" name="bank" required onChange={this.onChangeEdit} value={edit_bank_index}>
                                <option>Select Bank Account</option>
                                <option value={0}>{this.state.session.bank.account_no} -- {this.state.session.bank.sort_code} ({this.state.session.bank.bank_name})</option>
                                {banks}
                            </select>    
                            <b><small><a className="float-right text-primary" onClick={()=>{this.openModal(1)}}>Add New Bank Account?</a></small></b>
                            </div>
                            <div className="form-group mb-4">
                                <label><label for="form16" className="text-dark bold">Success Callback URL</label> *optional</label>
                                <input type="url" onChange={this.onChangeEdit} name="callback" value={this.state.edit_product.callback} className=" form-control "/>    
                            </div>
                            <div className="form-group mb-4">
                                <label for="form16"><label className="text-dark bold">Product Category</label> *optional</label>
                                <select className=" form-control" name="category_id" onChange={this.onChangeEdit} value={this.state.edit_product.category_id}>
                                    <option></option>
                                    {categories}
                                </select>
                            </div>
                            <div className="form-group mb-4">
                                <label><label for="form16" className="text-dark bold">Price ({this.state.session.currency.currency_code})</label> *optional</label>
                                <input type="number" onChange={this.onChangeEdit} name="amount" value={this.state.edit_product.amount} className=" form-control "/>    
                            </div>
                            {!this.state.editting?<button className="btn btn-dark btn-md float-right text-capitalize">Edit {!this.state.session.p_name?'Products':this.state.session.p_name}</button>:<img src={loader} className="smallLoader"/>}

                        </form>
                        {this.state.edit_error?<center><label className="text-danger">{this.state.edit_error}</label></center>:null}
                        </div>
                    </div>
                </div>:this.state.create_category?
                    <div className="ibox-body tab-content solidBorder">
                        <div>
                            <div className="row">
                                <div className="col-8">
                                    <h4><b>Add {!this.state.session.p_name?'Products':this.state.session.p_name} Category</b></h4>
                                    <small><label>Products Categories</label></small>
                                </div>
                            </div>
                            <form onSubmit={this.createCategory}>
                                <div className="form-group mb-4">
                                    <label><label for="form16" className="text-dark bold">Category</label></label>
                                    <input type="Category" onChange={this.onChange} name="category" value={this.state.category} className=" form-control "/>    
                                </div>
                                {!this.state.creating_category?<button className="btn btn-dark btn-md float-right text-capitalize">Add Category</button>:<img src={loader} className="smallLoader"/>}
                                {this.state.category_error?<label className="text-danger">{this.state.category_error}</label>:null} 
                                {this.state.category_msg?<label className="text-primary">{this.state.category_msg}</label>:null}        
                            </form>
                        </div>

                    </div>
                :!this.state.list_products?<div className="col">                            
                    <span>
                        <div className="col-8">
                            <h4><b>Create {!this.state.session.p_name?'Products':this.state.session.p_name}</b></h4>
                            <small><label>Create products your customers can interact with on our platforms</label></small>
                        </div>
                    </span>
                    
                    <div className="ibox-body tab-content solidBorder">

                        <div>
                        <form onSubmit={this.createProduct}>
                            <div className="form-group mb-4">
                                <label for="form16" className="text-dark bold">{!this.state.session.p_name?'Products':this.state.session.p_name} Name</label>
                                <input type="text" onChange={this.onChange} required name="name" value={this.state.name} className=" form-control "/>    
                            </div>
                            {this.state.session.country_code!=="044"?<div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Wallet Number</label>
                            <select className=" form-control " required name="modulrAcct" onChange={this.onChange} value={this.state.modulrAcct_index}>
                                <option></option>
                                {wallets}
                            </select>  
                            <b><small><a className="float-right text-primary" onClick={()=>{this.openModal(0)}}>Create New Wallet?</a></small></b> 
                            </div>:<div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Merchant Account</label>
                            <select className=" form-control " required name="modulrAcct" onChange={this.onChange} value={this.state.modulrAcct_index}>
                                <option></option>
        <option value={-1}>{this.state.session.account_details.account_number} -- {this.state.session.account_details.sortCode}</option>
                                {wallets}
                            </select>  
                            <b><small><a className="float-right text-primary" onClick={()=>{this.openModal(2)}}>Create Merchant Account?</a></small></b> 
                            </div>}
                            <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Settlement Account</label>
                            <select className=" form-control" name="bank" required onChange={this.onChangeBank} name={this.state.bank}>
                                <option></option>
                                <option value={0}>{this.state.session.bank.account_no} ({this.state.session.bank.bank_name})</option>
                                {banks}
                            </select>    
                            <b><small><a className="float-right text-primary" onClick={()=>{this.openModal(1)}}>Add New Settlement Account?</a></small></b>
                            </div>
                            <div className="form-group mb-4">
                                <label for="form16"> <label className="text-dark bold">Price ({this.state.session.currency.currency_code})</label> *optional</label>
                                <input type="number" onChange={this.onChange} name="amount" value={this.state.amount} className="form-control"/>
                            </div>
                            <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Product Type</label>
                            <select className=" form-control " name="type" onChange={this.onChange} required value={this.state.type}>
                                <option></option>
                                <option value="01">Education</option>
                                <option value="02">General Merchant</option>
                                <option value="04">Charity</option>
                                <option value="05">Taxes</option>
                                <option value="03">B2B</option>
                            </select>
                            </div>
                            {this.state.type==="01"?<div className="form-group mb-4">
                                <label for="form16" className="text-dark bold">Education Level</label>
                                <select className=" form-control" name="level" required onChange={this.onChange} value={this.state.level}>
                                    <option>Level</option>
                                    <option value="01">Primary</option>
                                    <option value="02">Secondary</option>
                                    <option value="03">Tertiary</option>
                                </select>
                            </div>:null}
                            <div className="form-group mb-4">
                                <label for="form16"><label className="text-dark bold">Product Category</label> *optional</label>
                                <select className=" form-control" name="category_id" onChange={this.onChange} value={this.state.category_id}>
                                    <option></option>
                                    {categories}
                                </select>
                            </div>
                            <div className="form-group mb-4">
                                <label for="form16"><label className="text-dark bold">Display For?</label></label>
                                <select className=" form-control" name="display_for" onChange={this.onChange} required value={this.state.display_for}>
                                    <option></option>
                                    <option value="mobile" selected="selected">Mobile</option>
                                    <option value="webpay">WebPay</option>
                                    <option value="xcelpos">XCelPOS</option>
                                </select>
                            </div>
                            <h5>{this.state.display_error}</h5>
                            <div className="form-group mb-4">
                                <label><label for="form16" className="text-dark bold">Success Callback URL</label> *optional</label>
                                <input type="url" onChange={this.onChange} name="callback" value={this.state.callback} className=" form-control "/>    
                            </div>
                            {!this.state.creating?<button className="btn btn-dark btn-md text-capitalize float-right">Create {!this.state.session.p_name?'Products':this.state.session.p_name}</button>:<img src={loader} className="smallLoader"/>}
                        </form>
                        {this.state.create_error?<center><label className="text-danger">{this.state.create_error}</label></center>:null}
                        </div>
                    </div>
                </div>:<div>
                    <span>
                        <div className="row">
                            <div className="col-8">
                                <h4><b>View {!this.state.session.p_name?'Products':this.state.session.p_name}</b></h4>
                                <small><label>Products your customers can interact with on our platforms</label></small>
                            </div>
                        </div>
                    </span>
                    <div className="ibox-body tab-content">
                            <div className="limit-width">  
                            <center>
                            <table className="specialTable col-12">
                                <thead>
                                <tr className="specialTable">
                                    <th>S/N</th>
                                    <th>{!this.state.session.p_name?'Products':this.state.session.p_name} Name</th>
                                    {this.state.session.country_code==="044"?<th>Merchant Account</th>:<th>Wallet</th>}
                                    <th>Bank Details</th>
                                    <th>Price</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>{products}</tbody>
                            </table>
                            </center>
                            {!products?<div className="solidBorder">
                                <center><img src={reportpng} className="m-3 smallImg"/></center>
                                <center><h3 className="eee"><b>No Products</b></h3></center>
                            </div>:null}
                            </div>
                    </div>
                </div>}
                </div>
            </div>
        </div>
                
        
        
    
            <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            shouldCloseOnOverlayClick={false}
            style={this.state.customStyles}
            contentLabel="Example Modal">
                {this.state.flag===1?<div className="m-3">
                    <form onSubmit={this.createBank}>
                    <h5><b>Add a New Bank Account</b></h5>
                    {this.state.session.country_code==="044"?null:<div className="form-group mb-4">
                    <label for="form16" className="text-dark bold">Bank</label>
                    <select className=" form-control " required name="new_bank" disabled={this.state.disable_add} value={this.state.new_bank} onChange={this.onChange}>
                        <option>Bank</option>
                        {banksList}
                    </select>
                    </div>}
                    
                    {this.state.session.country_code==="044"?<div className="form-group mb-4">
                    <label for="form16" className="text-dark bold">Bank Name</label>
                    <input type="text" required onChange={this.onChange} placeholder="Bank Name" name="new_bank_name" disabled={this.state.disable_add} value={this.state.new_bank_name} className=" form-control "/>    
                    </div>:null}
                    <div className="form-group mb-4">
                    <label for="form16" className="text-dark bold">Account Number</label>
                    <input type="text" required onChange={this.onChange} placeholder="Account Number" name="new_acct_no" disabled={this.state.disable_add} value={this.state.new_acct_no} className=" form-control "/>    
                    </div>
                    {this.state.session.country_code==="044"?<div className="form-group mb-4">
                    <label for="form16" className="text-dark bold">Sort Code</label>
                    <input type="text" required pattern=".{6,6}"   onChange={this.onChange} placeholder="Sort Code" name="new_sort_code" value={this.state.new_sort_code} className=" form-control "/>    
                    </div>:null}
                    <h5>{this.state.new_acct_name}</h5>
                    <h5 className="text-danger">{this.state.new_error}</h5>
                    {this.state.adding_bank?<img src={loader} className="smallLoader"/>:
                    !this.state.valid_bank && this.state.session.country_code==="234"?<div>
                        <button className="btn btn-dark-primary text-capitalize" onClick={()=>{this.setState({modalIsOpen:false})}}>Close</button>
                        <button className="ml-3 btn btn-dark text-capitalize" onClick={this.validateBank}>Validate</button>
                    </div>:
                    <div className="float-right">
                        <button className="btn btn-dark-primary text-capitalize" onClick={()=>{this.setState({modalIsOpen:false})}}>Close</button>
                        <button className="ml-3 btn btn-dark text-capitalize">Add Bank</button>
                    </div>}  
                    </form>
                </div>:this.state.flag===0?<div className="m-3">
                    <h4 className="p-3">Create a New Wallet?</h4> 
                    {this.state.adding_wallet?<img src={loader} className="smallLoader"/>:<div className="float-right">
                        <button className="btn btn-dark-primary text-capitalize" onClick={()=>{this.setState({modalIsOpen:false})}}>No</button>
                        <button className="ml-3 btn btn-dark text-capitalize" onClick={this.createWallet}>Yes</button>
                    </div>}   
                    {this.state.new_error?<div><br/><label className="text-danger">{this.state.new_error}</label></div>:null}
                </div>:this.state.flag===2?<div className="m-3">
                    <h4 className="p-3">Create a Merchant Account</h4> 
                    {this.state.adding_wallet?<img src={loader} className="smallLoader"/>:<div className="float-right">
                        <button className="btn btn-dark-primary text-capitalize" onClick={()=>{this.setState({modalIsOpen:false})}}>No</button>
                        <button className="ml-3 btn btn-dark text-capitalize" onClick={this.createModulrAccount}>Yes</button>
                    </div>}   
                    {this.state.new_error?<div><br/><label className="text-danger">{this.state.new_error}</label></div>:null}
                </div>:null}
            </Modal>
            
        </div>

    }

}

export default ProductComponents;