import React, { Component } from 'react';
// ES module

import Modal from 'react-modal';
import { ApiService } from '../../services';
import loader from "../loading.gif";
import countries from "../RegisterComponents/countries";
import reportpng from '../../assets/img/icons/report.png';


class CorporatePayComponents extends Component{
    constructor(props){
        super();

        this.state = {
            editting_product: false,
            fetch_error: false,
            recipients: [],
            list_recipients: true,
            country_code: '',
            org_no: '',
            level: '00',
            type: '00',
            adding_wallet: false,
            session: JSON.parse(localStorage.getItem("SESSION")),
            adding_bank: false,
            product_code: Math.floor(Math.random() * 1000),
            bank: {
                country_code: '',
                account_no: '',
                bank_name: '',
                bank_code: '',
                sort_code: '',
                route_number: ''
            },
            session: {
                bank: {
                    account_no: '',
                    bank_name: '',
                    bank_code: '',
                    sort_code: '',
                    route_number: ''
                },
                associated_banks: [],
                associated_wallets: []
            },
            categories: [],
            associated_wallets: [],
            name: '',
            new_sort_code: '',
            new_route_no: '',
            callback: '',
            customStyles : {
                content : {
                  top                   : '50%',
                  left                  : '50%',
                  right                 : 'auto',
                  bottom                : 'auto',
                  marginRight           : '-50%',
                  transform             : 'translate(-50%, -50%)'
                }
            }

        }
    }

    componentDidMount(){
        this.setState({session:this.props.session});

        if(this.props.recipients){
            this.setState({recipients: this.props.recipients})
        }
        //this.listRecipients();

        this.viewBanks(this.props.session.country_code)

        this.listCategories()
    }

    componentDidUpdate(prevProps){
        if(prevProps.recipients!==this.props.recipients){
            this.setState({recipients: this.props.recipients})
        }
    }

    viewBanks = (country_code) => {
        ApiService.fetch_banks(country_code)
        .then((res)=>{
          console.log(res.data.data);
          if(res.data.data){
            this.setState({banks: res.data.data});
          }
        },(error)=>{
          console.log(error)
          let zerror = error.response? error.response.data.errors:error.toString();
          this.setState({banks_error: zerror, banks: false});
        })
    }


    createCategory = (e)=>{
        e.preventDefault();
        const merchant_id = this.state.session.merchant_id;
        const category = this.state.category;

        this.setState({creating_category: true});
        ApiService.createRecipientCategory(merchant_id,category)
        .then((response)=>{
            let categories = this.state.categories;
            categories.push(response.data.data);
            this.setState({categories: categories, creating_category: false, category_msg: "Category Created"})
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({category_error: zerror, creating_category: false});
        });
    }

    createBank = (e)=>{
        if(this.state.new_acct_no && this.state.new_acct_no){
        e.preventDefault();

        this.setState({adding_bank: true});
        const country_code = this.state.session.country_code;
        const bank_code = this.state.new_bank_code;
        const bank_name = this.state.new_bank_name;
        const sort_code = this.state.new_sort_code;
        const route_no = this.state.new_route_no;
        const account_no = this.state.new_acct_no;
        const merchant_id = this.state.session.merchant_id;



        console.log(country_code+" , "+bank_code+" , "+bank_name+" , "+sort_code+" , "+route_no+" , "+account_no);

        ApiService.addBank(country_code,bank_code,account_no,bank_name,sort_code,route_no,merchant_id)
        .then((response)=>{
            let session = this.state.session;

            (session.associated_banks).push({
                country_code: country_code,
                bank_code: bank_code,
                account_no: account_no,
                bank_name: bank_name,
                sort_code: sort_code,
                route_number: route_no
            });
            this.setState({session: session});
            localStorage.setItem('SESSION',JSON.stringify(session));
            this.setState({new_success: 'Bank Added',adding_bank: false, modalIsOpen: false});
            //this.setState({adding_bank: false});
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({adding_bank: false, new_error: zerror});
        })
        }else{
            this.setState({new_error: "Fill All Fields"})
        }
    }

    openModal = (flag) =>{
        this.setState({modalIsOpen: true, flag: flag})
    }

    onChange = (e) =>{
        console.log(e.target.name)
        if(e.target.name!=="modulrAcct"){
            this.setState({[e.target.name]:e.target.value});
        }else{
            if(e.target.value==="-1"){
                this.setState({[e.target.name]: this.state.session.account_details, modulrAcct_index: e.target.value})
            }else{
                this.setState({[e.target.name]: this.state.session.associated_accounts[e.target.value], modulrAcct_index: e.target.value})
            }
        }

        if(e.target.name==="wallet"){
            //alert(e.target.value)
            this.setState({[e.target.name]: e.target.value})

        }

        if(e.target.name==="bank"){
            console.log(e.target.value);
            this.setState({bankSelected: this.state.banks[e.target.value],bank: e.target.value});
        }

        if(e.target.name === "new_country"){
            this.viewBanks(e.target.value);
        }

        if(e.target.name === "new_bank"){
            this.setState({new_bank_code: this.state.banks[e.target.value].bank_code, new_bank_name: this.state.banks[e.target.value].bank_name});
        }

        if(e.target.name === "new_sort_code"){
            this.setState({new_sort_code: e.target.value})
        }

        if(e.target.name === "new_bank_name"){
            this.setState({new_bank_name: e.target.value})
        }

        if(e.target.name==="type" && e.target.value!=="01"){
            this.setState({level: "00"})
        }

        if(e.target.name === "amount"){
            this.setState({amount: e.target.value})
        }

        if(e.target.name === "category_id"){
            this.setState({category_id: e.target.value})
        }

        if(e.target.name === "level"){
            this.setState({level: e.target.value})
        }
    }

    onChangeBank = (e) =>{
        if(e.target.value==="0"){
            console.log(e.target.value)
            this.setState({bank: this.state.session.bank});
        }else{
            this.setState({bank: this.state.session.associated_banks[parseInt(e.target.value)-1]})
        }
    }

    onChangeEdit = (e) =>{

        //console.log(this.state.edit_product);
        //let edit_bank_account= this.state.edit_product.bank.account_no

        let product = this.state.edit_product;
        if(e.target.name === "name"){
            product.name = e.target.value;
            console.log(e.target.value);
            this.setState({edit_product: product});
        }else if(e.target.name === "desc"){
            product.product_description = e.target.value;
            this.setState({edit_product: product});
        }else if(e.target.name === "callback"){
            product.callback = e.target.value;
            this.setState({edit_product: product});
        }else if(e.target.name === "amount"){
            product.amount = e.target.value;
            this.setState({edit_product: product});
        }else if(e.target.name === "category_id"){
            product.category_id = e.target.value;
            this.setState({edit_product: product});
        }else if(e.target.name === "wallet"){
            product.wallet = e.target.value;
            this.setState({edit_product: product});
        }else if(e.target.name === "sort_code"){
            product.sort_code = e.target.value;
            this.setState({edit_product: product});
        }else if(e.target.name === "account_no"){
            //alert("boogie")
            product.account_no = e.target.value;
            //console.log
            this.setState({edit_product: product});
        }else if(e.target.name === "bank_name"){
            product.bank_name = e.target.value;
            this.setState({edit_product: product});
        }else if(e.target.name === "payment_type"){
            product.payment_type = e.target.value;
            this.setState({edit_product: product});
        }else if(e.target.name === "bank"){
            product.bank = this.state.banks[e.target.value];
            
            this.setState({edit_product: product, edit_bank_index: e.target.value});
        }
    }

    /**onChangeEditBank = (e) =>{
        if(e.target.value==="0"){
            console.log(e.target.value)
            this.setState({edit_bank: this.state.session.bank});
        }else{
            console.log(e.target.value)
            this.setState({edit_bank: this.state.session.associated_banks[parseInt(e.target.value)-1],edit_bank_index:parseInt(e.target.value)-1})
        }
    }*/

    listRecipients = () =>{
        ApiService.fetchRecipients(this.props.session.merchant_id)
        .then((res)=>{
            console.log(res.data.data);
            if(res.data.data){
                this.setState({recipients: res.data.data});
            }
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({fetch_error: zerror});
        })
    }

    listCategories = () =>{
        ApiService.fetchRecipientCategories(this.props.session.merchant_id)
        .then((res)=>{
            console.log(res.data.data);
            if(res.data.data){
                this.setState({categories: res.data.data});
            }
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({categories:[]});
        })
    }


    editRecipient = (e)=>{
        e.preventDefault();
        this.setState({editting: true});
        const name = this.state.edit_product.name;
        const wallet = this.state.edit_product.wallet;
        let bank = this.state.edit_product.bank;
        const country_code = this.state.session.country_code;
        const callback = this.state.callback;
        const merchant_id = this.state.edit_product.merchant_id;
        const amount = this.state.edit_product.amount;
        const category_id = this.state.edit_product.category_id;
        const type = this.state.edit_product.payment_type;
        const product_id = this.state.edit_product.product_id;

        if(this.state.edit_product.bank){
            console.log(this.state.edit_product.bank);
            console.log(this.state.edit_product.bank_code);
            console.log(this.state.edit_product.account_no);
            console.log(this.state.edit_product.bank_name);
            /**if(this.state.edit_product.sort_code){
                bank.sort_code = this.state.edit_product.sort_code || "";
            }

            if(this.state.edit_product.bank_name){
                bank.bank_name = this.state.edit_product.bank_name || "";
            }
            bank.account_no = this.state.edit_product.account_no || "";**/

            bank = {
                country_code: country_code,
                bank_code: this.state.edit_product.bank_code || this.state.edit_product.bank.bank_code || "",
                sort_code: this.state.edit_product.sort_code || this.state.edit_product.bank.sort_code || "",
                account_no: this.state.edit_product.account_no || this.state.edit_product.bank.account_no|| this.state.edit_bank_account || "",
                bank_name: this.state.edit_product.bank_name ||this.state.edit_product.bank.bank_name || ""
            }
        }

        console.log("boom!")
        ApiService.editRecipient(product_id,country_code,name, wallet,bank, merchant_id, callback, category_id, amount,type)
        .then((res)=>{
            this.listRecipients();
            this.setState({editting: false,list_recipients:true,edit_error: false,editting_product: false})
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({edit_error: zerror, editting: false});
        })
    }

    createProduct = (e)=>{
        e.preventDefault();
        this.setState({creating: true,create_error:""});
        //console.log(this.state.bank);
        let name = this.state.name;
        const wallet = this.state.wallet;
        let bank = this.state.bankSelected;
        const country_code = this.state.session.country_code;
        const merchant_id = this.state.session.merchant_id;
        const callback = this.state.callback;
        const amount = this.state.amount;
        const category_id = this.state.category_id;
        const type = this.state.payment_type;

        console.log(bank)
        if(this.state.payment_type === "Account"){
            if(this.state.sort_code){
                bank.sort_code = this.state.sort_code || "";
            }

            if(this.state.bank_name){
                bank.bank_name = this.state.bank_name || "";
            }
            bank.account_no = this.state.account_no || "";
        }

        console.log(this.state.payment_type);

        if(this.state.payment_type==="Account"){
            
            if(this.state.sort_code){
                this.createFn(country_code,name, '', wallet,bank, merchant_id, callback, category_id, amount,type);
            }else{
                ApiService.validate_account(bank.account_no,bank.bank_code)
                .then((res)=>{
                    if(res.data.response_code=="0"){
                        console.log(res.data);
                        name = res.data.response_message;
                        this.createFn(country_code,name, '', wallet,bank, merchant_id, callback, category_id, amount,type);                    
                    }else{
                        //let zerror = error.response? error.response.data.errors:error.toString();
                        this.setState({create_error: res.data.response_message, creating: false});    
                    }
                },(error)=>{
                    let zerror = error.response? error.response.data.errors:error.toString();
                    this.setState({create_error: zerror, creating: false});
                });
            }
        }else{
            ApiService.validate_wallet(wallet,country_code+"004")
            .then((res)=>{
                console.log(res.data.data);
                let card_num = res.data.data.card_num
                if(card_num){
                    name = res.data.data.first_name+" "+res.data.data.last_name
                    this.createFn(country_code,name,card_num, wallet,bank, merchant_id, callback, category_id, amount,type);
                }else{
                    console.log("Invalid wallet");
                    this.setState({create_error: "Outdated Wallet", creating: false});
                }
            },(error)=>{
                let zerror = error.response? error.response.data.errors:error.toString();
                this.setState({create_error: zerror, creating: false});
            })
        }
        //country_code, org_no, name, wallet,bank, type, level, pmt_code, product_description,product_no, merchant_id,modulrAcct
        
    }


    createFn = (country_code,name, card_num, wallet,bank, merchant_id, callback, category_id, amount,type)=>{
        ApiService.createRecipient(country_code,name, card_num, wallet,bank, merchant_id, callback, category_id, amount,type)
        .then((res)=>{
            this.listRecipients();
            this.setState({name:"",wallet:"",bank:"",level:"",creating: false,list_recipients:true,create_error: false, product_code: Math.floor(Math.random() * 1000)})
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({create_error: zerror, creating: false});
        })
    }


    numberWithCommas(x) {
        x = (Math.round(x * 100) / 100).toFixed(2)
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    render(){
        //console.log(this.state.session.merchant_id);
        let wallets, banks, recipients, categories;
        if(this.state.session.country_code!=="044" && this.state.session.country_code!=="001"){
            if(Object.keys(this.state.session.associated_wallets).length>0){
                wallets = this.state.session.associated_wallets.map((wallet,index)=>{
                    return <option value={wallet}>{wallet}</option>
                });
            }
        }else{
            console.log(this.state.session.associated_accounts)
            if(Object.keys(this.state.session.associated_accounts).length>0){
                wallets = this.state.session.associated_accounts.map((wallet,index)=>{
                return <option value={index}>{wallet.account_number} -- {wallet.sortCode}</option>
                });
            }
        }

        if(Object.keys(this.state.categories).length>0){
            categories = this.state.categories.map((category,index)=>{
                return <option value={category.category_id}>{category.category}</option>
            })
        }

        if(Object.keys(this.state.session.associated_banks).length>0){
            banks = this.state.session.associated_banks.map((bank,index)=>{
            return <option value={index+1}>{bank.account_no} -- {bank.sort_code} ({bank.bank_name})</option>
            });
        }

        if(Object.keys(this.state.recipients).length>0){
            console.log("mop");
            recipients = this.state.recipients.map((product, index)=>{
                return <tr>
                    <td className="whiteBG">{index+1}</td>
                    <td><b>{product.name}</b></td>
                    <td>{product.payment_type}</td>
                     <td>{product.category[0].category}</td>
                    {this.state.session.country_code==="044" && product.payment_type!=='Wallet'?
                        <td>{product.bank.account_no} -- {product.bank.sort_code}</td>
            :product.payment_type!=='Wallet'?<td>{product.bank.account_no}</td>:<td>{product.wallet}</td>}
                    {product.payment_type!=='Wallet'?<td>{product.bank.bank_name}</td>:<td>Xcel</td>}
                    <td>{this.state.session.currency.currency_code} {this.numberWithCommas(product.amount)}</td>
                    <td>
                        <center>
                        {product.payment_type!=='Wallet'?<a onClick={()=>{
                            this.setState({editting_product: true, edit_product: this.state.recipients[index], edit_bank_account: this.state.recipients[index].bank.account_no})
                        }}>
                            <big><i className="fa fa-fw fa-pencil"></i></big>
                        </a>:<a onClick={()=>{
                            this.setState({editting_product: true, edit_product: this.state.recipients[index]})
                        }}><big><i className="fa fa-fw fa-pencil"></i></big></a>}
                        </center>
                    </td>
                </tr>
            })
        }

        let edit_bank_index;
        if(this.state.edit_product){
            if(this.state.session.country_code!=="044"){
                this.state.banks.map((bank,i)=>{
                    if(this.state.edit_product.bank && bank.bank_code === this.state.edit_product.bank.bank_code){
                        edit_bank_index = i;
                    }
                });   
            }
        }

        let countriesList = countries.map((country, index)=>{
            if(country.calling_code!==null){
              return <option value={country.calling_code}>{country.country}</option>
            }else return ""
        });

        let banksList;
        if(this.state.banks){
          banksList= this.state.banks.map((bank, index)=>{
              return <option value={index}>{bank.bank_name}</option>
          });
        }

        return<div className="p-3"> <div className="row">
        <div className="col-lg-2">
            <br/><br/>
            <ul className="list-group list-group-divider faq-tabs">
              <li className="list-group-item" onClick={()=>{this.setState({list_recipients: true,editting_product: false,edit_product:false,create_category: false})}}>
                <a href="javascript:void(0);" data-toggle="tab"><i className="badge-point badge-primary mr-3"></i>View Recipients</a>
              </li>
              <li className="list-group-item" onClick={()=>{this.setState({list_recipients: false,editting_product: false,edit_product:false, create_category: false})}}>
                <a className="active" href="javascript:void(0);" data-toggle="tab"><i className="badge-point badge-warning mr-3"></i>Create Recipients</a>
              </li>
              <li className="list-group-item" onClick={()=>{this.setState({list_recipients: false,editting_product: false,edit_product:false, create_category: true})}}>
                <a className="active" href="javascript:void(0);" data-toggle="tab"><i className="badge-point badge-info mr-3"></i>Create Category</a>
              </li>

              <br/>
            </ul>
        </div>
        <div className="col-lg-10">
        <div className="p-3">
            {this.state.editting_product?<div>
                <div className="row">
                    <div className="col-8">
                        <h4><b>View Recipients</b></h4>
                        <small><label>Recipients your customers can interact with on our platforms</label></small>
                    </div>
                </div>
                <div className="float-right mr-3"></div>
                <div className="ibox-body tab-content solidBorder">

                    <div>
                    <form onSubmit={this.editRecipient}>
                        <div className="form-group mb-4">
                        <label for="form16" className="text-dark bold">Product Name</label>
                        <input name="type" type="text" placeholder="Product Name" name="name" onChange={this.onChangeEdit} value={this.state.edit_product.name} className=" form-control "/>
                        </div>
                        <div className="form-group mb-4">
                            <label for="form16"><label className="text-dark bold">Recipient Account Type</label></label>
                            <select className=" form-control" required name="payment_type" onChange={this.onChangeEdit} value={this.state.edit_product.payment_type}>
                                <option></option>
                                {this.state.session.country_code!=="044"?<option value="Wallet">XCel Wallet</option>:null}
                                <option value="Account">Bank Account</option>
                            </select>
                        </div>
                        {this.state.edit_product.payment_type==="Wallet"?<div className="form-group mb-4">
                          <label for="form16" className="text-dark bold">Wallet Number</label>
                          <input type="text" onChange={this.onChangeEdit} required name="wallet" value={this.state.edit_product.wallet} className=" form-control "/>
                        </div>:this.state.edit_product.payment_type==="Account"?<div>
                        {this.state.session.country_code!=="044"?<div className="form-group mb-4">
                            <label for="form16"><label className="text-dark bold">Bank</label></label>
                            <select className=" form-control" required name="bank" onChange={this.onChangeEdit} value={edit_bank_index}>
                                <option></option>
                                {banksList}
                            </select>
                        </div>:<span>
                            <div className="form-group mb-4">
                                <label for="form16" className="text-dark bold">Bank Name</label>
                                <input type="text" onChange={this.onChangeEdit} required name="bank_name" value={this.state.edit_product.bank_name || this.state.edit_product.bank.bank_name} className=" form-control "/>
                            </div>
                            <div className="form-group mb-4">
                                <label for="form16" className="text-dark bold">Sort Code</label>
                                <input type="text" onChange={this.onChangeEdit} required name="sort_code" value={this.state.edit_product.sort_code || this.state.edit_product.bank.sort_code} className=" form-control "/>
                            </div>
                        </span>}
                        <div className="form-group mb-4">
                          <label for="form16" className="text-dark bold">Account Number</label>
                          <input type="text" onChange={this.onChangeEdit} required name="account_no" value={this.state.edit_product.account_no ||this.state.edit_product.bank.account_no} className=" form-control "/>
                          </div>
                        </div>:null}
                        <div className="form-group mb-4">
                            <label><label for="form16" className="text-dark bold">Price ({this.state.session.currency.currency_code})</label></label>
                            <input type="number" onChange={this.onChangeEdit} name="amount" value={this.state.edit_product.amount} className=" form-control "/>
                        </div>
                        <div className="form-group mb-4">
                            <label for="form16"><label className="text-dark bold">Recipient Category</label></label>
                            <select className=" form-control" required name="category_id" onChange={this.onChangeEdit} value={this.state.edit_product.category_id}>
                                <option></option>
                                {categories}
                            </select>
                        </div>
                        {!this.state.editting?<button className="btn btn-dark btn-md float-right text-capitalize">Edit Recipients</button>:<img src={loader} className="smallLoader"/>}

                    </form>
                    {this.state.edit_error?<center><label className="text-danger">{this.state.edit_error}</label></center>:null}
                    </div>
                </div>
            </div>:this.state.create_category?
                <div className="ibox-body tab-content solidBorder">
                    <div>
                        <div className="row">
                            <div className="col-8">
                                <h4><b>Add Recipients Category</b></h4>
                                <small><label>Recipients Categories</label></small>
                            </div>
                        </div>
                        <form onSubmit={this.createCategory}>
                            <div className="form-group mb-4">
                                <label><label for="form16" className="text-dark bold">Category</label></label>
                                <input type="Category" onChange={this.onChange} name="category" value={this.state.category} className=" form-control "/>
                            </div>
                            {!this.state.creating_category?<button className="btn btn-dark btn-md float-right text-capitalize">Add Category</button>:<img src={loader} className="smallLoader"/>}
                            {this.state.category_error?<label className="text-danger">{this.state.category_error}</label>:null}
                            {this.state.category_msg?<label className="text-primary">{this.state.category_msg}</label>:null}
                        </form>
                    </div>

                </div>
            :!this.state.list_recipients?<div className="col">
                <span>
                    <div className="col-8">
                        <h4><b>Create Recipients</b></h4>
                        <small><label>Create recipients your customers can interact with on our platforms</label></small>
                    </div>
                </span>

                <div className="ibox-body tab-content solidBorder">

                    <div>
                    <form onSubmit={this.createProduct}>
                        <div className="form-group mb-4">
                            <label for="form16"><label className="text-dark bold">Recipient Account Type</label></label>
                            <select className=" form-control" required name="payment_type" onChange={this.onChange} value={this.state.payment_type}>
                                <option></option>
                                {this.state.session.country_code!=="044"?<option value="Wallet">XCel Wallet</option>:null}
                                <option value="Account">Bank Account</option>
                            </select>
                        </div>
                        {this.state.payment_type==="Wallet"?<div className="form-group mb-4">
                          <label for="form16" className="text-dark bold">Wallet Number</label>
                          <input type="text" onChange={this.onChange} required name="wallet" value={this.state.wallet} className=" form-control "/>
                        </div>:this.state.payment_type==="Account"?<div>
                        {this.state.session.country_code!=="044"?<div className="form-group mb-4">
                            <label for="form16"><label className="text-dark bold">Bank</label></label>
                            <select className=" form-control" required name="bank" onChange={this.onChange} value={this.state.bank}>
                                <option></option>
                                {banksList}
                            </select>
                        </div>:<span>
                            <div className="form-group mb-4">
                                <label for="form16" className="text-dark bold">Bank Name</label>
                                <input type="text" onChange={this.onChange} required name="bank_name" value={this.state.bank_name} className=" form-control "/>
                            </div>
                            <div className="form-group mb-4">
                                <label for="form16" className="text-dark bold">Sort Code</label>
                                <input type="text" onChange={this.onChange} required name="sort_code" value={this.state.sort_code} className=" form-control "/>
                            </div>
                        </span>}
                        <div className="form-group mb-4">
                          <label for="form16" className="text-dark bold">Account Number</label>
                          <input type="text" onChange={this.onChange} required name="account_no" value={this.state.account_no} className=" form-control "/>
                          </div>
                        </div>:null}
                        <div className="form-group mb-4">
                            <label for="form16"><label className="text-dark bold">Price ({this.state.session.currency.currency_code})</label></label>
                            <input type="number" required onChange={this.onChange} name="amount" value={this.state.amount} className="form-control"/>
                        </div>
                        <div className="form-group mb-4">
                            <label for="form16"><label className="text-dark bold">Recipient Category</label></label>
                            <select className=" form-control" required name="category_id" onChange={this.onChange} value={this.state.category_id}>
                                <option></option>
                                {categories}
                            </select>
                        </div>
                        {!this.state.creating?<button className="btn btn-dark btn-md text-capitalize float-right">Create Recipient</button>:<img src={loader} className="smallLoader"/>}
                    </form>
                    {this.state.create_error?<center><label className="text-danger">{this.state.create_error}</label></center>:null}
                    </div>
                </div>
            </div>:<div>
                <span>
                    <div className="row">
                        <div className="col-8">
                            <h4><b>View Recipients</b></h4>
                            <small><label>Recipients your customers can interact with on our platforms</label></small>
                        </div>
                    </div>
                </span>
                <div className="ibox-body tab-content">
                        <div className="limit-width">
                        <center>
                          <table className="specialTable col-12">
                            <thead>
                              <tr className="specialTable">
                                <th>S/N</th>
                                <th>Recipients Name</th>
                                <th>Payment Type</th>
                                <th>Category</th>
                                {this.state.session.country_code==="044"?<th>Account</th>:<th>Wallet/Account</th>}
                                <th>Bank Name</th>
                                <th>Amount</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>{recipients}</tbody>
                         </table>
                        </center>
                        {!recipients?<div className="solidBorder">
                            <center><img src={reportpng} className="m-3 smallImg"/></center>
                            <center><h3 className="eee"><b>No Recipients</b></h3></center>
                        </div>:null}
                        </div>
                </div>
            </div>}
            </div>
        </div>
        </div>

        </div>

    }

}

export default CorporatePayComponents;
