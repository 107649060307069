import React, { Component } from 'react';
// ES module
import loader from "../load.gif";
import { Link } from 'react-router-dom';
import { ApiService } from '../../services';
import {Redirect} from 'react-router';
import ForgotComponent from '../ForgotComponents/ForgotComponent';
import Spinner from 'react-bootstrap-spinner'

class LoginComponent extends Component{


  constructor(){
    super()
    
    this.state = {
      loggingIn:false,
      redirect: false,
      email: '',
      password: ''
    }
  }

  loggingIn = (e)=>{
    e.preventDefault();
    const email = this.state.email;
    const password = this.state.password;
    let type;

    if(email==='Administrator'){
      type = 'admin';  
    }else{
      type = 'merchant'
    }

    console.log("booyaga!")
    this.setState({loggingIn: true, error:''});
    ApiService.login(email,password,type)
    .then((res)=>{
      localStorage.setItem("SESSION_ID",res.data.data.session_id);
      localStorage.setItem("START_TIME",Date.now());
      localStorage.setItem("TOKEN",res.data.data.token);
      localStorage.setItem("SESSION",JSON.stringify(res.data.data.data));
      this.setState({redirect: true});
    },(error)=>{
      console.log(error);
      let zerror = error.response? error.response.data.errors:error.toString();
      this.setState({error:zerror,loggingIn:false})
    })
  }

  onChange = (e)=>{
    this.setState({[e.target.name]:e.target.value});
  }

  render(){
    
    if(!this.state.redirect){
      return <div>
      <div className="row ">
        <div className="col-md-12 bg-white">
                <br/>
                <br/>
                {!this.state.showForgot?<div>
                    <form onSubmit={this.loggingIn}>
                      <div className="form-group mb-4">
                          <label className="text-dark">Director Email</label>
                          <input name="type" type="email" name="email" value={this.state.email} onChange={this.onChange} placeholder="Director Email Address" className="form-control"/>    
                      </div>
                      <div className="form-group mb-4">
                          <label className="text-dark">Password</label>
                          <input name="type" type="password" name="password" value={this.state.password} onChange={this.onChange} placeholder="Password" className="form-control"/>    
                      </div>
                      
                      <span className="mb-4 row">
                        
                          <div className="col-md-6">
                            <button disabled={!this.state.email || !this.state.password} className="btn btn-sm btn-block navbtn btn-dark white-btn p-2 text-capitalize font15">
                              Log In
                              {this.state.loggingIn?<img src={loader} className="muchSmallLoader ml-3"/>:null}
                            </button>
                          </div>
                        <div>
                          
                        </div>
                      </span>
                      <u><a className="text-dark" onClick={()=>{this.setState({showForgot: true})}}>Forgot Password?</a></u>
                      {this.state.error?<div className="alert alert-danger">{this.state.error}</div>:null}
                    </form>
                </div>:
                <div>
                  <ForgotComponent/>
                  <u><a className="text-dark" onClick={()=>{this.setState({showForgot: false})}}>Login</a></u>
                </div>}
            </div>
          </div>
        </div>
    }else{
      return <Redirect to={'/dashboard'}/>;
    }
  }
}

export default LoginComponent;
