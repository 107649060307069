import React, { Component } from 'react';

import './App.css';
import 'font-awesome/css/font-awesome.css';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import HomePage from './components/HomePageComponent';
import LoginPage from './components/LoginComponents/LoginComponent';
import ForgotPage from './components/ForgotComponents/ForgotComponent';
import RegisterPage from './components/RegisterComponents/RegisterComponent';
import DashboardPage from './components/DashboardComponents/DashboardComponent';
import ChannelsComponent from './components/ReportsComponent/ChannelsComponent';
import ProductsComponents from './components/ReportsComponent/ProductsComponent';
import ProductComponent from './components/ReportsComponent/ProductComponent';

import Webhooks from './components/WebhooksComponent/LandingComponent';

class App extends Component {

  render(){
    return(
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={ props => <HomePage {...props} />}/>
          <Route exact path="/dashboard" render={ props => <DashboardPage {...props} />}/>
          <Route exact path="/channels" render={props => <ChannelsComponent {...props}/>}/>
          <Route exact path="/products/:channel_id" render={props => <ProductsComponents {...props}/>}/>
          <Route exact path="/product/:product_id" render={props => <ProductComponent {...props}/>}/>
          <Route exact path="/register" render={ props => <RegisterPage {...props} />}/>
          <Route exact path="/login" render={ props => <LoginPage {...props} />}/>
          <Route exact path="/forgot" render={ props => <ForgotPage {...props} />}/>
          <Route exact path="/webhook" render={ props => <Webhooks {...props}/>}/>
        </Switch>
      </BrowserRouter>
    )
  }

}
/**
// Map all state to component props (for redux to connect)
const mapStateToProps = state => state;

// Export a redux connected component
export default connect(mapStateToProps)(App);
**/

export default App;
