import React, { Component } from 'react';
// ES module
import logo from '../../assets/img/blog/logo.png';
import { Link } from 'react-router-dom';
import { ApiService } from '../../services';
import reportpng from '../../assets/img/icons/report.png';


class ProductsComponent extends Component{
  constructor(){
    super();

    this.state = {
      page: 1,
      level: 1,
      session: JSON.parse(localStorage.getItem("SESSION")),
      channels: {}
    }
  }

  componentDidMount(){
    this.fetchOverview();
  }

  fetchOverview = ()=>{
    ApiService.fetchProducts(this.state.session.merchant_id, this.props.channel_id)
    .then((response)=>{
        console.log(response.data.data);
        if(response.data.data){
          this.setState({channels: response.data.data});
        }
    },(error)=>{
        console.log(error);
    });

  }

  numberWithCommas(x) {
    x = (Math.round(x * 100) / 100).toFixed(2)
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  render(){
    let Channels;
    if(Object.keys(this.state.channels).length>0){
      Channels = this.state.channels.map((data,index)=>{
          console.log(data.product[0][0]);
        return <tr>
          <td className="whiteBG"><Link to={'/product/'+data._id} target="_blank">{index+1}</Link></td>
          <td><Link to={'/product/'+data._id} target="_blank">{this.props.channel_id}</Link></td>
          <td><Link to={'/product/'+data._id} target="_blank">{data.product[0][0].name}</Link></td>
          <td><Link to={'/product/'+data._id} target="_blank">{this.numberWithCommas(data.volume)}</Link></td>
      <td><Link to={'/product/'+data._id} target="_blank">{this.state.session.currency.currency_code}<span className="float-right">{this.numberWithCommas(data.value)}</span></Link></td>
        </tr>
      });
    }

    //console.log();
    return <div>
      <div className="ibox-body tab-content">
        <label className="text-capitalize pb-3"><b>{this.props.channel_id} Earnings</b></label>
          <div className="limit-width">
            <table className="specialTable col-12">
              <thead>
                <tr className="specialTable">
                  <th>S/N</th>
                  <th>Channel</th>
                  <th>Product</th>
                  <th>Total Volume</th>
                  <th>Total Value</th>
                </tr>
              </thead>
              <tbody >
                {Channels}
              </tbody>
            </table>
          </div>

          {!Channels?<div className="solidBorder">
            <center><img src={reportpng} className="m-3 smallImg"/></center>
            <center><h3 className="eee"><b>No Data Found</b></h3></center>
          </div>:null}
      </div>
    </div>                          
  }
}

export default ProductsComponent;