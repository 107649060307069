import React, { Component } from 'react';
import './style.css';
import './table.css';
import '../assets/css/main.min.css';
import logo from '../assets/img/blog/logo.png';
import logoRSwitch from '../assets/img/blog/RSwitch_Logo.jpg';
import logoXCel from '../assets/img/blog/logoXCel.png';
import LoginComponent from './LoginComponents/LoginComponent';
// ES module

import { Link } from 'react-router-dom';


class HomePageComponent extends Component{
  
  constructor(){
    super();

    this.state = {
      logo: logo
    }
  }

  componentDidMount(){
    if(window.location.hostname==="rswitch.xcelapp.com" || window.location.hostname==="rswitch.etranzactglobal.com"){
      this.setState({logo: logoRSwitch,powered_by: true, logoClass: "logoBig ml-3 mt-3"});
    }else if((window.location.hostname==="merchant.etranzactglobal.com")){
      this.setState({logo: logo,powered_by: true, logoClass:"logoSmall ml-3 mt-3", headerClass: "header-topbar"});
    }else{
      this.setState({logo: logoXCel,powered_by: true, logoClass:"logoSmall ml-3 mt-3", headerClass: "header-topbar"});
    }
  }

  render(){
    return <div className="eee row">
        <div className="col-md-1"></div>
        <div className="col-md-10 mt-5">
        <div className="ibox row ml-2 mr-2 mt-3 shadowy">
          <div className="col-md-4">
            <br/> 
            <img src={this.state.logo} alt="logo" className={this.state.logoClass}/>
            <div className="p-4"><LoginComponent/></div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            {this.state.powered_by?<h5 className="ml-1">Powered by <img src={this.state.logo} className="tinyLogo"/></h5>:null}
          </div>  
          <div className="col-md-8 fineBG">
            <div className="m-5">
            <br/>
            <br/>
  <center><h1 className="mt-5">Powered by XCel</h1></center>
            <br/>
            <br/>
            <br/>
            <center><Link to={'/register'} className="btn btn-outline-light p-2 text-capitalize text13">Register</Link></center>
            </div>
          </div>
        </div>
        </div>
        <div className="col-md-1"></div>
      </div>
  }
}

export default HomePageComponent;
