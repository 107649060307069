import React, { Component } from 'react';
// ES module
import loader from "../load.gif";
import { Link } from 'react-router-dom';
import { ApiService } from '../../services';


class ForgotComponent extends Component{

  constructor(){
    super()
    
    this.state = {
      email: ''
    }

    this.forgot = this.forgot.bind(this);
    this.change = this.change.bind(this);
  }

  onChange = (e) =>{
    this.setState({[e.target.name]: e.target.value});
  }

  forgot = (e) =>{
    e.preventDefault();
    console.log("SCRUBS");
    this.setState({sending: true, error: ""});
    const email = this.state.email;
    ApiService.forgot(email)
    .then((response)=>{
      this.setState({sent: true, sending: false,message: "Email Sent"});
    },(error)=>{
      let zerror = error.response? error.response.data.errors:error.toString();
      this.setState({sent: false, sending: false, error: zerror})
    });
  }

  change = (e) =>{
    e.preventDefault();
    console.log("SCRUBS");
    
    const email = this.state.email;
    const pin = this.state.pin;
    const password = this.state.password;
    const cpassword = this.state.cpassword

    if(cpassword === password){
      this.setState({changing: true, cerror: "", cmessage: ""});
      ApiService.forgotPassword(email,pin,password)
      .then((response)=>{
        this.setState({changed: true, changing: false,cmessage: "Password Updated"});
      },(error)=>{
        let zerror = error.response? error.response.data.errors:error.toString();
        this.setState({changed: false, changing: false, cerror: zerror})
      });    
    }else{
      this.setState({cerror: "Passwords don't match"})
    }
  }

  render(){
    return <div>
    <div>
        <div style={{"box-shadow": "none"}}>
          <div>
            <div>
              {!this.state.sent?<form onSubmit={this.forgot}>
                <div className="form-group mb-4">
                  <label className="text-dark">Enter Director Email </label>
                  <input name="email" type="email" onChange={this.onChange} value={this.state.email} placeholder="Director Email Address" className="form-control"/>    
                </div>
                <center><p className="text-dark"><small>An account recovery message would be send to you via the email address of the registered director</small></p></center>
                <button disabled={!this.state.email} onClick={this.nextBtn} className="btn btn-sm btn-block navbtn btn-dark white-btn p-2 text-capitalize font15">
                  Send Email
                  {this.state.sending?<img src={loader} className="muchSmallLoader ml-3"/>:null}
                </button>
                {this.state.error?<div className="alert alert-danger">{this.state.error}</div>:null}
              </form>:<form onSubmit={this.change}>
                <div className="form-group mb-4">
                  <label className="text-dark">Director Email </label>
                  <input name="email" type="email" onChange={this.onChange} value={this.state.email} disabled={true} placeholder="Director Email Address" className="form-control"/>    
                </div>
                <div className="form-group mb-4">
                  <label className="text-dark">Enter Pin </label>
                  <input name="pin" type="text" onChange={this.onChange} value={this.state.pin} placeholder="Enter Pin" className="form-control"/>    
                </div>
                <div className="form-group mb-4">
                  <label className="text-dark">Enter New Password </label>
                  <input name="password" type="password" onChange={this.onChange} value={this.state.password} placeholder="Enter New Password" className="form-control"/>    
                </div>
                <div className="form-group mb-4">
                  <label className="text-dark">Confirm New Password </label>
                  <input name="cpassword" type="password" onChange={this.onChange} value={this.state.cpassword} placeholder="Confirm New Password" className="form-control"/>    
                </div>
                <button disabled={!this.state.email || !this.state.pin || !this.state.password || !this.state.cpassword} className="btn btn-sm btn-block navbtn btn-dark white-btn p-2 text-capitalize font15">
                  Change Password
                  {this.state.changing?<img src={loader} className="muchSmallLoader ml-3"/>:null}
                </button>
                {this.state.cerror?<div className="alert alert-danger">{this.state.cerror}</div>:null}
                {this.state.cmessage?<div className="alert alert-primary">{this.state.cmessage}</div>:null}
              </form>}
            </div>
            <br/>
          </div>
        </div>
    </div>
  </div>
  }
}

export default ForgotComponent;
