import React, { Component } from 'react';
import moment from 'moment';
import { ApiService } from '../../services';
import Modal from 'react-modal';
import loader from "../loading.gif";
import reportpng from '../../assets/img/icons/report.png';

class TransactionsComponent extends Component{
    constructor(){
        super();

        this.state = {
            data: [],
            customStyles : {
                content : {
                  top                   : '50%',
                  left                  : '50%',
                  right                 : 'auto',
                  bottom                : 'auto',
                  marginRight           : '-50%',
                  transform             : 'translate(-50%, -50%)'
                }
            }
        }
    }

    componentDidMount(){
        console.log(this.props.data)
        this.setState({data: this.props.data,loading_transactions: this.props.loading_transactions, transactions_error: this.state.transactions_error});
    }


    componentDidUpdate(prevProps,prevState){
        if(prevProps!==this.props){
            console.log(this.props.data)
            console.log(this.props.transactions_error)
            
            this.setState({data: (this.props.data),loading_transactions: this.props.loading_transactions, transactions_error: this.state.transactions_error})

            if(this.props.transactions_error)
                this.setState({showTransactionsError: true,modalIsOpen: true, showUser: false});
        }
    }


    numberWithCommas(x) {
        x = (Math.round(x * 100) / 100).toFixed(2)
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }


    onChange = (e)=>{
        this.setState({[e.target.name]:e.target.value});
    }

    strip = (text)=>{
        return text.replace('transaction-status-','').replace('transaction-type-','').replace('payment-type-','')
    }

    showFetchUser = (ledger_id)=>{
        this.setState({modalIsOpen: true, showUser: true,loadingUser: true, user: false,userError: false,showTransactionsError: false});

        ApiService.fetchUserByLedgerID(ledger_id)
        .then((res)=>{
            this.setState({loadingUser: false, user: res.data.data});
        },(error)=>{
            console.log(error);
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({userError: zerror,loadingUser: false});
        });
    }


    render(){

        let Data;

        console.log(this.state.data)
        if(Object.keys(this.state.data).length>0){
            Data = this.state.data.reverse().map((data,index)=>{
                let sender, receiver, sourceAccount, sourceSortCode, sourceAccountName, destAccount, destSortCode, destAccountName,settlementDate, transactionType, paymentType, transactionStatus;

                transactionType = this.strip(data.transaction_type);
                paymentType = this.strip(data.payment_type);
                transactionStatus = this.strip(data.transaction_status);

                if(data.payment_type==="payment-type-virtual-debit-card-debit"){
                    sender = data.ledger_from_id;
                    receiver = data.merchant_details;
                }else if(data.payment_type!=="payment-type-inter-ledger"){
                    sender = data.ledger_from_id;
                    receiver = data.ledger_to_id;

                    /**if(resp.data.payment_type==="payment-type-UK-BACS"){
                        sourceAccount = payment_info.bacsOriginator;
                        sourceAccount.accountName = sourceAccount.name;
                        destinationAccount = {
                            sortCode: data.sort_code,
                            accountNumber: data.account_num,
                            accountName: data.first_name+" "+data.last_name
                        },
                        p_amount = ((payment_info.amount.minorUnits)/100).toFixed(2);
                    }*/

                    

                    
                    if(data.transaction_type==="transaction-type-receive" && data.payment_type!=="payment-type-UK-BACS"){
                        
                        sourceAccount = data.payment_info.sourceAccount.accountNumber;
                        sourceSortCode = data.payment_info.sourceAccount.sortCode;
                        sourceAccountName = data.payment_info.sourceAccount.accountName;
                        destAccount = data.payment_info.destinationAccount.accountNumber;
                        destSortCode = data.payment_info.destinationAccount.sortCode;
                        destAccountName = data.payment_info.destinationAccount.accountName;
                        settlementDate = data.payment_info.fpsSettlementDate;
                    }else{
                        let meta = data.transaction_meta;
                        let transaction_printout = data.transaction_printout;

                        console.log(meta)
                        if(meta){
                            destAccount = meta.recipient_wallet_no;
                            destSortCode = meta.sort_code;
                            destAccountName = meta.recipient_name;//transaction_printout.beneficiaryname;
                        }

                        if(transaction_printout){
                            sourceAccount = (transaction_printout.ultimatesenderaccountnumber).toString().slice(14,22);
                            sourceSortCode = ((transaction_printout.ultimatesenderaccountnumber).toString().slice(8,14));
                            sourceAccountName = transaction_printout.ultimatesendername;
                        }

                    }


                    

                    console.log("<br/>"+sourceAccount+" ~ "+sourceSortCode+" <br/>"+sourceAccountName)
                }else{
                    let transaction_printout = data.transaction_printout;
                    
                    destAccount = (transaction_printout.beneficiaryiban).toString().slice(14,22);
                    destSortCode = ((transaction_printout.beneficiaryiban).toString().slice(8,14));
                    destAccountName = transaction_printout.beneficiaryname;
                    sourceAccount = (transaction_printout.ultimatesenderaccountnumber).toString().slice(14,22);
                    sourceSortCode = ((transaction_printout.ultimatesenderaccountnumber).toString().slice(8,14));
                    sourceAccountName = transaction_printout.ultimatesendername;
                }

                if(!this.state.transactions_param){
                    return <tr>
                            <td className="whiteBG">{data.settlement_date||data.transaction_info.settlement_date || settlementDate || "N/A"}</td>
                            <td>{transactionStatus}</td>
                            <td>{sourceAccountName || "N/A"}</td>
                            <td><label className="float-right">{this.numberWithCommas(data.amount)}</label></td>
                            <td>{destAccountName || receiver  || "N/A"}</td>
                            <td>{sourceAccount || "N/A"}</td>
                            <td>{sourceSortCode || "N/A"}</td>
                            <td>{destAccount || "N/A"}</td>
                            <td>{destSortCode || "N/A"}</td>
                            <td>{transactionType || "N/A"}</td>
                            <td className="whiteBG">{paymentType || "N/A"}</td>
                            <td>{data.transaction_id || "N/A"}</td>                            
                            <td><a href="#" onClick={()=>{this.showFetchUser(data.ledger_from_id);}}>{data.ledger_from_id}</a> {!data.ledger_from_id? "N/A":null} </td>
                            <td><a href="#" onClick={()=>{this.showFetchUser(data.ledger_to_id);}}>{data.ledger_to_id}</a> {!data.ledger_to_id? "N/A":null} </td>
                            <td>{data.failure_reasons? data.failure_reasons[0]:"SUCCESS"}</td>
                        </tr>    
                }else{

                    if(
                        data.transaction_id && (this.state.transactions_param===(data.transaction_id).substring(0,(this.state.transactions_param).length)) ||
                        data.ledger_from_id && (this.state.transactions_param===(data.ledger_from_id).substring(0,(this.state.transactions_param).length)) ||
                        data.ledger_to_id && (this.state.transactions_param===(data.ledger_to_id).substring(0,(this.state.transactions_param).length)) ||

                        sourceAccount && (this.state.transactions_param===(sourceAccount).substring(0,(this.state.transactions_param).length)) || 
                        sourceSortCode && (this.state.transactions_param===(sourceSortCode).substring(0,(this.state.transactions_param).length)) || 
                        sourceAccountName && (this.state.transactions_param===(sourceAccountName).substring(0,(this.state.transactions_param).length)) || 
                        
                        destAccount && (this.state.transactions_param===(destAccount).substring(0,(this.state.transactions_param).length)) ||
                        destSortCode && (this.state.transactions_param===(destSortCode).substring(0,(this.state.transactions_param).length)) ||
                        destAccountName && (this.state.transactions_param===(destAccountName).substring(0,(this.state.transactions_param).length)) ||

                        data.amount && (this.state.transactions_param===((data.amount).toString()).substring(0,(this.state.transactions_param).length)) ||

                        paymentType && (this.state.transactions_param===(paymentType).substring(0,(this.state.transactions_param).length)) ||

                        transactionType && (this.state.transactions_param===(transactionType).substring(0,(this.state.transactions_param).length)) ||

                        transactionStatus && (this.state.transactions_param===(transactionStatus).substring(0,(this.state.transactions_param).length))
                    ){
                        return <tr>
                            <td className="whiteBG">{data.settlement_date||data.transaction_info.settlement_date || settlementDate || "N/A"}</td>
                            <td>{transactionStatus}</td>
                            <td>{sourceAccountName || "N/A"}</td>
                            <td><label className="float-right">{this.numberWithCommas(data.amount)}</label></td>
                            <td>{destAccountName || receiver  || "N/A"}</td>
                            <td>{sourceAccount || "N/A"}</td>
                            <td>{sourceSortCode || "N/A"}</td>
                            <td>{destAccount || "N/A"}</td>
                            <td>{destSortCode || "N/A"}</td>
                            <td>{transactionType || "N/A"}</td>
                            <td className="whiteBG">{paymentType || "N/A"}</td>
                            <td>{data.transaction_id || "N/A"}</td>                            
                            <td><a href="#" onClick={()=>{this.showFetchUser(data.ledger_from_id);}}>{data.ledger_from_id}</a> {!data.ledger_from_id? "N/A":null} </td>
                            <td><a href="#" onClick={()=>{this.showFetchUser(data.ledger_to_id);}}>{data.ledger_to_id}</a> {!data.ledger_to_id? "N/A":null} </td>
                            <td>{data.failure_reasons? data.failure_reasons[0]:"SUCCESS"}</td>
                        </tr>                      
                    }
                 
                }
            })
        }
        return <div>
            <div className="pr-5 pl-5 pb-5 pt-3 tab-content mr-3 ml-3">                
                <h4>Transactions</h4>
                <div className="form-group">   
                    <input type="text" placeholder="Search" className="form-control" value={this.state.transactions_param} name="transactions_param" onChange={this.onChange}/>
                </div>
                <div className="limit-width">
                <table className="specialTable col-12">
                    <thead>
                        <tr className="specialTable">
                            <th>Settlement Date</th>
                            <th>Status</th>
                            <th>Sender Name</th>
                            <th>Amount (GBP)</th>
                            <th>Recipient Name</th>
                            <th>Sender Account</th>
                            <th>Sender Sort Code</th>
                            <th>Recipient Account</th>
                            <th>Recipient Sort Code</th>
                            <th>Transaction Type</th>
                            <th>Payment Type</th>
                            <th>Transaction ID</th>
                            <th>Sender (Ledger_ID)</th>
                            <th>Receiver (Ledger_ID)</th>
                            <th>Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Data}
                    </tbody>
                </table>
                {Object.keys(this.state.data).length==0?<div className="solidBorder">
                    <center><img src={reportpng} className="m-3 smallImg"/></center>
                    <center><h3 className="eee">{!this.state.loading_transactions?<b>No Transactions Fetched</b>:<img src={loader} className="smallLoader"/>}</h3></center>
                </div>:null}
                </div>
            </div>
            <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            shouldCloseOnOverlayClick={true}
            style={this.state.customStyles}
            contentLabel="Example Modal">
                {this.state.showTransactionsError?
                    <div>
                        <h3>{this.props.transactions_error}</h3>
                        <button className="btn btn-sm" onClick={()=>{this.setState({modalIsOpen: false,transaction_data: false, showTransactionsError: false,user: false, userError: false})}}>close</button>
                    
                    </div>
                :null}

                {
                    this.state.showUser?<div>
                        <h3>User Details</h3>
                        {this.state.user? <div>
                            <p><b>Fullname:</b> {this.state.user.first_name} {this.state.user.last_name}</p>
                            <p><b>Email:</b> {this.state.user.email}</p>
                            <p><b>Account:</b> {this.state.user.account_num}</p>
                            <p><b>Sort Code:</b> {this.state.user.sort_code}</p>
                        </div>: <div>
                            {this.state.loadingUser?<img src={loader} className="smallLoader"/>:this.state.userError?<h5 className="text-error">{this.state.userError}</h5>:null}
                            
                        </div>}
                        <button className="btn btn-sm" onClick={()=>{this.setState({modalIsOpen: false,user: false, showUser: false})}}>close</button>
                    </div> 
                :null}
                
            </Modal>
        </div>
    }
}


export default TransactionsComponent;