import React, { Component } from 'react';
// ES module
import logo from '../../assets/img/blog/logo.png';
import { Link } from 'react-router-dom';
import { ApiService } from '../../services';


class ChannelsComponent extends Component{
  constructor(){
    super();

    this.state = {
      page: 1,
      level: 1,
      session: JSON.parse(localStorage.getItem("SESSION")),
      channels: {}
    }
  }

  componentDidMount(){
    this.fetchOverview();
    this.fetchChannelsByDate();
  }


  fetchOverview = ()=>{
    ApiService.fetchChannels(this.state.session.merchant_id)
    .then((response)=>{ 
      if(response.data.data){
        console.log(response.data.data);
        this.setState({channels: response.data.data});
      }
    },(error)=>{
        console.log(error);
    });

  }

  numberWithCommas(x) {
    x = (Math.round(x * 100) / 100).toFixed(2)
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  render(){
    let Channels;
    if(Object.keys(this.state.channels).length>0){
      Channels = this.state.channels.map((data,index)=>{
        return <tr>
          <td><Link to={'/products/'+data._id} target="_blank">{index+1}</Link></td>
          <td><Link to={'/products/'+data._id} target="_blank">{data._id}</Link></td>
          <td><Link to={'/products/'+data._id} target="_blank">{this.numberWithCommas(data.volume)}</Link></td>
          <td><Link to={'/products/'+data._id} target="_blank">₦{this.numberWithCommas(data.value)}</Link></td>
        </tr>
      });
    }

    console.log(this.state.session);
    return <div>
            <div className="page-wrapper">
                    <header className="header">
                        <div className="header-topbar">
                            <div className="wrapper d-flex container pt-3 pb-3">
                                <div className="page-brand p-2">
                                    <a className="link" href="index-2.html">
                                        <Link to={'/'} className="text-white brand"><img alt="logo" src={logo} className="logoSmall"/></Link>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="top-navbar">
                            <div className="wrapper container p-4" id="navbar-wrapper">
                            </div>
                        </div>
                    </header>
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-lg-1">
                            
                          </div>
                          <div className="col-lg-10">
                            <div className="wrapper">
                              <div className="flexbox-b mt-3 mb-3 ml-2 row">
                                <span className="mr-4 static-badge badge-info"><i className="fa-building fa"></i></span>
                                <div>
                                  <h5 className="font-strong">{this.state.session.reg_name}</h5>
                                  <div className="text-light">{this.state.session.ent_type}</div>
                                </div>
                                <div className="col-md-4"></div>
                              </div>
                              <div className="ibox">
                                <span className="ibox-title m-2">
                                </span>
                                <div className="ibox-body tab-content">
                                    <h4><b>Income Channels</b></h4>
                                    <table className="specialTable col-12">
                                        <thead>
                                            <tr className="specialTable">
                                                <th>S/N</th>
                                                <th>Channel</th>
                                                <th>Total Volume</th>
                                                <th>Total Value</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {Channels}
                                        </tbody>
                                    </table>
                                </div>
                              </div>
                          <br/>
                          </div>
                          <footer className="page-footer wrapper content-wrapper container">
                              <div className="font-13 p-3">2019 © <b>eTranzact</b> - Global</div>
                              <div>
                              </div>
                              <div className="to-top"><i className="fa fa-angle-double-up"></i></div>
                          </footer>
                          </div>                                                    
                          <div className="col-lg-1">
                            
                          </div>
                        </div>
                      </div>

                    </div>
                </div>
    </div>
  }
}

export default ChannelsComponent;
