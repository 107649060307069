import React, { Component } from 'react';
import { ApiService } from '../../services';
import logo from '../../assets/img/blog/logo.png';
import logoXCel from '../../assets/img/blog/logoXCel.png';
import logoRSwitch from '../../assets/img/blog/RSwitch_Logo.jpg';
import '../style.css';
import '../table.css';
import '../../assets/css/main.min.css';
import moment from 'moment';
import WebhookComponent from './WebhookComponent';
import ReportsComponent from './ReportsComponent';
import Modal from 'react-modal';
import loader from "../loading.gif";
import { data } from 'jquery';
import TransactionsComponent from './TransactionsComponent';


class LandingComponent extends Component{
    constructor(){
        super();

        this.state = {
            page:1,
            country: "NG",
            webhooks: [],
            report:[],
            failed: [],
            transactions: [],
            loading_failed: false,
            loading_reports: false,
            loading_webhooks: false,
            loading_transactions: false,
            customStyles : {
                content : {
                  top                   : '50%',
                  left                  : '50%',
                  right                 : 'auto',
                  bottom                : 'auto',
                  marginRight           : '-50%',
                  transform             : 'translate(-50%, -50%)'
                }
            }
        }

        this.loadFailedWebhooks = this.loadFailedWebhooks.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){

        //localStorage.removeItem("ADMIN")

        if(!localStorage.getItem("ADMIN"))
            this.setState({modalIsOpen: true, login: true})

        let start = new Date();
        start.setHours(0,0,0,0);

        let end = new Date();
        end.setHours(23,59,59,999);
        this.setState({start: start,end: end,start_date: this.formatDate(start),end_date: this.formatDate(end)});
        this.loadFailedWebhooks(start.toISOString(), end.toISOString());
        this.loadDailyReports(this.state.country,start.toISOString(), end.toISOString());
        this.loadDailyFailed(this.state.country,start.toISOString(), end.toISOString());
        this.loadDailyTransactions(start.toISOString(), end.toISOString());

        if(window.location.hostname==="rswitch.xcelapp.com" || window.location.hostname==="rswitch.etranzactglobal.com"){
           this.setState({logo: logoRSwitch,powered_by: true, logoClass: "logoBig m-1", headerClass: "header-topbar red-BG"});
        }else if((window.location.hostname==="merchant.etranzactglobal.com")){
           this.setState({logo: logo,powered_by: true, logoClass:"logoSmall m-1", headerClass: "header-topbar"});
        }else{
           this.setState({logo: logoXCel,powered_by: true, logoClass:"logoSmall m-1", headerClass: "header-topbar"});
        }
    }

    formatDate = (date) =>{
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    onChange = (e)=>{
        if(e.target.name==="start" && e.target.value!==""){
            let start = new Date(e.target.value);
            start.setHours(0,0,0,0);

            this.setState({start: start,start_date: this.formatDate(start)});
        }else if(e.target.name==="end" && e.target.value!==""){
            let end = new Date(e.target.value);
            end.setHours(23,59,59,999);

            this.setState({end: end, end_date: this.formatDate(end)});
        }else if(e.target.value!==""){
            //alert(e.target.value)
            this.setState({[e.target.name]:e.target.value});
        }
    }


    onSubmit = (e)=>{
        e.preventDefault();


        this.setState({report:[],failed:[],webhooks:[]})

        let start = this.state.start,
            end = this.state.end;


        this.loadFailedWebhooks(start.toISOString(), end.toISOString());
        this.loadDailyReports(this.state.country,start.toISOString(), end.toISOString());
        this.loadDailyTransactions(start.toISOString(), end.toISOString());
        this.loadDailyFailed(this.state.country,start.toISOString(), end.toISOString());
    }
    loggingIn = (e)=>{
        e.preventDefault();
        const email = this.state.email;
        const password = this.state.password;
    
        console.log("booyaga!")
        this.setState({loggingIn: true, error:''});
        ApiService.adminLogin(email,password)
        .then((res)=>{
            console.log(res.data.data)
          localStorage.setItem("ADMIN",JSON.stringify(res.data));
          this.setState({modalIsOpen: false,loggingIn:false});
        },(error)=>{
          console.log(error);
          let zerror = error.response? error.response.data.errors:error.toString();
          this.setState({error:zerror||"Invalid Credentials",loggingIn:false})
        })
      }



    loadDailyReports = (country,start,end)=>{
        this.setState({loading_reports: true})
        ApiService.fetchDailyReport(country,start,end)
        .then((res)=>{
            console.log(res.data.data);
            this.setState({report: res.data.data,loading_reports: false})
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({fetch_error: zerror,loading: false,loading_reports:false})
        })
    }

    loadDailyFailed = (country,start,end)=>{
        this.setState({loading_failed: true,failed_error: ''})
        ApiService.fetchDailyFailed(country,start,end)
        .then((res)=>{
            console.log(res.data.data);
            this.setState({failed: res.data.data,loading_failed: false})
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({failed_error: zerror,loading: false, loading_failed: false})
        })
    }

    loadDailyTransactions = (start,end)=>{
        this.setState({loading_transactions: true, transactions_error: ''})
        ApiService.fetchUKTransactions(start,end)
        .then((res)=>{
            console.log(res.data.data);
            this.setState({transactions: res.data.data,loading_transactions: false})
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({transactions_error: zerror,loading: false, loading_transactions: false})
        })
    }    


    loadFailedWebhooks = (start,end)=>{
        this.setState({loading_webhooks: true, webhook_error: ''})
        ApiService.fetchFailedWebhooks(start,end)
        .then((res)=>{
            console.log(res.data.data);
            this.setState({webhooks: res.data.data, loading_webhooks: false})
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({webhook_error: zerror,loading: false, loading_webhooks: false})
        })

    }

    render(){

        const activeClass1 = `dropdown ${this.state.page===1 ? " active" : ""}`;
        const activeClass2 = `dropdown ${this.state.page===2 ? " active" : ""}`;
        const activeClass3 = `dropdown ${this.state.page===3 ? " active" : ""}`;
        /**const activeClass5 = `dropdown ${this.state.page===5 ? " active" : ""}`;
        const activeClass6 = `dropdown ${this.state.page===6 ? " active" : ""}`;
        const activeClass7 = `dropdown ${this.state.page===7 ? " active" : ""}`;
        const activeClass8 = `dropdown ${this.state.page===8 ? " active" : ""}`;**/
        return <div>
            <div className="page-wrapper">
                <header className="header">
                    <div className={this.state.headerClass}>
                            <div className="wrapper d-flex container pt-3 pb-3">
                                <div className="p-2">
                                    <a >
                                        <img alt="logo" src={logo} className={this.state.logoClass}/>
                                    </a>
                                </div>
                            </div>
                    </div>
                    <div className="top-navbar">
                        <div className="wrapper container" id="navbar-wrapper">
                            <ul className="nav-menu">
                                <li className={activeClass1} onClick={()=>{this.setState({page:1})}}><a>Failed Webhooks</a></li>
                                <li className={activeClass2} onClick={()=>{this.loadDailyReports(this.state.country,this.state.start,this.state.end);this.setState({page:2})}}><a>Railsbank Transactions</a></li>
                                <li className={activeClass3} onClick={()=>{this.loadDailyReports(this.state.country,this.state.start,this.state.end);this.setState({page:3})}}><a>Daily Reports</a></li>
                            </ul>
                        </div>
                    </div>
                </header>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-1">
                             
                        </div>
                        <div className="col-lg-10">
                            <div className="wrapper">
                                <br/>
                                <div className="flexbox-b mt-3 mb-3 ml-2 row col-md-12">
                                    <button className="btn btn-danger btn-sm text-capitalize float-right bold" onClick={()=>{localStorage.removeItem("ADMIN");this.setState({modalIsOpen: true})}}>Log Out</button>
                                </div>

                                <div className="ibox">
                                    <br/>
                                    <span className="">
                                        <center><h3><b>Data Showing for {moment(this.state.start).format('DD-MM-YYYY LT')} to {moment(this.state.end).format('DD-MM-YYYY LT')}</b></h3></center>
                                        <span>
                                            <form className="form-inline" onSubmit={this.onSubmit}>
                                                <div className="form-group mb-2 ml-4">
                                                    <input type="date" placeholder="From Date:" className="form-control" value={this.state.start_date} name="start" onChange={this.onChange}/>
                                                </div>
                                                <div className="form-group mb-2 ml-2">
                                                    <input type="date" placeholder="To Date:" className="form-control" value={this.state.end_date} name="end" onChange={this.onChange}/>
                                                </div>
                                                <button type="submit" className="btn btn-primary mb-2 ml-2">Go</button>
                                            </form>
                                        </span>
                                    </span>
                                    {this.state.page===1?<WebhookComponent webhook_error={this.state.webhook_error} loading_webhooks={this.state.loading_webhooks} data={this.state.webhooks}/>:this.state.page===2?<TransactionsComponent transactions_error={this.state.transactions_error} data={this.state.transactions} loading_transactions={this.state.loading_transactions}/>:<ReportsComponent data={this.state.report} loading_reports={this.state.loading_reports} loading_failed={this.state.loading_failed} failed={this.state.failed} failed_error={this.state.failed_error}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    shouldCloseOnOverlayClick={true}
                    style={this.state.customStyles}
                    contentLabel="Example Modal">
                        <form onSubmit={this.loggingIn}>
                            <div className="form-group mb-4">
                                <label className="text-dark">Admin Email</label>
                                <input name="type" type="email" name="email" value={this.state.email} onChange={this.onChange} placeholder="Director Email Address" className="form-control"/>    
                            </div>
                            <div className="form-group mb-4">
                                <label className="text-dark">Password</label>
                                <input name="type" type="password" name="password" value={this.state.password} onChange={this.onChange} placeholder="Password" className="form-control"/>    
                            </div>
                      
                            <span className="mb-4 row">
                                
                                <div className="col-md-6">
                                    <button disabled={!this.state.email || !this.state.password} className="btn btn-sm btn-block navbtn btn-dark white-btn p-2 text-capitalize font15">
                                    Log In
                                    {this.state.loggingIn?<img src={loader} className="muchSmallLoader ml-3"/>:null}
                                    </button>
                                </div>
                                <div>
                                
                                </div>
                            </span>
                      {this.state.error?<div className="alert alert-danger">{this.state.error}</div>:null}
                    </form>
                </Modal>
            </div>
        </div>
    }
}

export default LandingComponent;