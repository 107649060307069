import React, { Component } from 'react';
// ES module

import Modal from 'react-modal';
import { ApiService } from '../../services';
import loader from "../loading.gif";
import countries from "../RegisterComponents/countries";
import reportpng from '../../assets/img/icons/report.png';


class NotificationComponent extends Component{
    constructor(props){
        super();

        this.state = {
            session: JSON.parse(localStorage.getItem("SESSION")),
            uploadMP4: false,
            selectedFile: false,
            notifications: {},
            edit: {},
            customStyles : {
              content : {
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                bottom                : 'auto',
                marginRight           : '-50%',
                transform             : 'translate(-50%, -50%)'
              }
          }
        }
    }

    componentDidMount(){
      if(this.props.notifications){
        this.setState({notifications: this.props.notifications})
      }
    }

    componentDidUpdate(prevProps){
    }


    openModal = (flag) =>{
        this.setState({modalIsOpen: true, flag: flag})
    }

    fetchNotifications = () =>{
      ApiService.fetchNotifications(this.state.session.merchant_id)
      .then((res)=>{
        console.log(res.data.data);
        if(res.data.data!==undefined){
          this.setState({notifications: res.data.data})
        }else{
          this.setState({notifications: {}})
        }
      },(error)=>{
        let zerror = error.response? error.response.data.errors:error.toString();
        this.setState({fetch_error: zerror});
      });
    }

    editNotification = (e) =>{
      e.preventDefault()
      this.setState({updating: true, update_error: false, update_msg: false});
      ApiService.editNotification(this.state.edit._id,this.state.edit)
      .then((response)=>{
        this.fetchNotifications();
        this.setState({updating: false, update_msg: "Updated"});
      },(error)=>{
        let zerror = error.response? error.response.data.errors:error.toString();
        this.setState({updating: false, update_error: zerror})
      })
    }

    deleteNotification = ()=>{
      this.setState({deleting: true, delete_error: false});
      ApiService.deleteNotification(this.state.session.merchant_id, this.state.notif._id)
      .then((response)=>{
        this.fetchNotifications()
        this.setState({modalIsOpen: false, deleting: false});
      },(error)=>{
        let zerror = error.response? error.response.data.errors:error.toString();
        this.setState({deleting: false, delete_error: zerror});
      })
    }

    onChange = (e)=>{
        
        this.setState({[e.target.name]: e.target.value});

        if(e.target.name === "push_audio_file" || e.target.name === "push_video_file"){
          console.log(e.target.files[0]);
          this.setState({selectedFile: e.target.files[0],uploaded: false})
        }

        if(e.target.name==="push_type"){
          if(e.target.value==="text"){
            this.setState({push_multimedia: true});
          }else{
            this.setState({push_multimedia: false});
          }
        }
    
        if(e.target.name==="push_youtube"){
          if((e.target.value).startsWith("https://youtu.be/")){
            this.setState({push_multimedia: e.target.value,push_multimedia_error: false})
          }else{
            this.setState({push_multimedia_error: true,push_multimedia: false})
          }
        }
    
        if(e.target.name==="push_video"){
          if((e.target.value).endsWith(".mp4")){
            this.setState({push_multimedia: e.target.value,push_multimedia_error: false,selectedFile:""})
          }else{
            this.setState({push_multimedia_error: true,push_multimedia: false})
          }
        }    
    
        if(e.target.name==="push_soundcloud"){
          if((e.target.value).endsWith(".mp3")){
            this.setState({push_multimedia: e.target.value,push_multimedia_error: false,selectedFile:""})
          }else{
            this.setState({push_multimedia_error: true,push_multimedia: false})
          }
        }
      }

      pushMessage = (e)=>{
        e.preventDefault();
        let img = this.state.selectedFile;


          const media = this.state.push_multimedia;
          let multimedia = media;
          if(this.state.push_multimedia===true){
            multimedia = ""
          }
          const title = this.state.push_title,
                message = this.state.push_message,
                type = this.state.push_type,
                link = this.state.push_link;
      
          this.setState({push_change: true, push_success: false, push_error: false});
        if(!img){
          ApiService.pushNotification(this.state.session.reg_name,title,message,type,link,multimedia,this.state.session.merchant_id)
          .then((response)=>{
            this.setState({push_success: "Message Sent", push_error: false, push_change: false, push_title:"",push_message:"",push_type:"",push_multimedia:"",push_link:""});
          
          },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({push_success: false, push_error: zerror, push_change: false});
          });
        }else{
          let img = this.state.selectedFile;
          console.log(img)
          ApiService.upload(img)
          .then((result)=>{
            multimedia = result.data.data.url;
            console.log(result.data.data);
            ApiService.pushNotification(this.state.session.reg_name,title,message,type,link,multimedia,this.state.session.merchant_id)
            .then((response)=>{
              this.setState({push_success: "Message Sent", push_error: false, push_change: false, push_title:"",push_message:"",push_type:"",push_multimedia:"",push_link:""});
            },(error)=>{
              let zerror = error.response? error.response.data.errors:error.toString();
              this.setState({push_success: false, push_error: zerror, push_change: false});
            });
          },(error)=>{
            console.log(error);
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({push_success: false, push_error: zerror, push_change: false});
          })
        }
    
      }

    uploadFile = (e) =>{
      e.preventDefault()
      let img = this.state.selectedFile;
      console.log(img)
      ApiService.upload(img)
      .then((result)=>{
        console.log(result.data.data);
      },(error)=>{
        console.log(error);
        let zerror = error.response? error.response.data.errors:error.toString();
      })
    }

    onChangeEdit = (e) =>{

        //console.log(this.state.edit_product);
        //let edit_bank_account= this.state.edit_product.bank.account_no

        let edit = this.state.edit;
        if(e.target.name === "title"){
            edit.title = e.target.value;
            console.log(e.target.value);
            this.setState({edit: edit});
        }else if(e.target.name === "message"){
          edit.message = e.target.value;
          console.log(e.target.value);
          this.setState({edit: edit});
        }else if(e.target.name === "type"){
          edit.type = e.target.value;
          console.log(e.target.value);
          this.setState({edit: edit});
        }else if(e.target.name === "multimedia"){
          edit.title = e.target.value;
          console.log(e.target.value);
          this.setState({edit: edit});
        }else if(e.target.name === "link"){
          edit.title = e.target.value;
          console.log(e.target.value);
          this.setState({edit: edit});
        }
    }

    render(){
      let notifications
      if(Object.keys(this.state.notifications).length>0){
        notifications = this.state.notifications.map((notif, index)=>{
            return <tr>
                <td className="whiteBG">{index+1}</td>
                <td>
                    <a onClick={()=>{this.setState({modalIsOpen: true, notif: notif})}}>
                        <big><i className="fa fa-fw fa-trash"></i></big>
                    </a>
                </td>
                <td>{notif.title}</td>
                <td class="txt">{(notif.message).substring(0,80)}<label className="text-blue">...</label></td>
                <td>{notif.type}</td>
                <td>{notif.multimedia}</td>
                <td>{notif.link}</td>
            </tr>
        })
      }
        return<div className="p-3"> <div className="row">
        <div className="col-lg-2">
            <br/><br/>
            <ul className="list-group list-group-divider faq-tabs">
              <li className="list-group-item" onClick={()=>{this.setState({list_recipients: true,editting_product: false,edit_product:false,create_category: false})}}>
                <a href="javascript:void(0);" data-toggle="tab"><i className="badge-point badge-primary mr-3"></i>View Messages</a>
              </li>
              <li className="list-group-item" onClick={()=>{this.setState({list_recipients: false,editting_product: false,edit_product:false, create_category: false})}}>
                <a className="active" href="javascript:void(0);" data-toggle="tab"><i className="badge-point badge-warning mr-3"></i>Send Message</a>
              </li>
              <br/>
            </ul>
        </div>
        <div className="col-lg-10">
        <div className="p-3">
            {this.state.editting_product?<div>
                <div className="row">
                    
                </div>
                <div className="float-right mr-3"></div>
                <div className="ibox-body tab-content solidBorder">

                    <div>
                      <h3><b>Edit Notification</b></h3>
                    <form onSubmit={this.editNotification}>
                    <div className="form-group mb-4">
                                      <label for="form16">Title</label>
                                      <input name="title" required value={this.state.edit.title} onChange={this.onChangeEdit} type="text" placeholder="Message Title" className="form-control"/>
                                    </div>
                                    <div className="form-group mb-4">
                                      <label for="form16">Message</label>
                                      <textarea name="message" required value={this.state.edit.message} maxLength="400" onChange={this.onChangeEdit} type="text" placeholder="Message" className="form-control"></textarea>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label for="form16">Type</label>
                                        <select  required name="type" value={this.state.edit.type} className="form-control" onChange={this.onChangeEdit}>
                                            <option value="">Select Option</option>
                                            <option value="text">Send Message</option>
                                            <option value="youtube">Send YouTube  Video</option>
                                            <option value="video">Send Video Clips</option>
                                            <option value="docs">Send Document</option>
                                            <option value="audio">Send Audio</option>
                                        </select>
                                    </div>
                                    {this.state.push_type==="youtube"?<div className="form-group mb-2">
                                      <label for="form16">YouTube Link</label>
                                      <input name="youtube" required value={this.state.edit.youtube} onChange={this.onChangeEdit} type="url" placeholder="https://youtu.be/...." className="form-control"/>
                                    </div>:this.state.push_type==="audio"?
                                    <div className="form-group mb-4">
                                      <label for="form16">{!this.state.uploadMP3?"Link to MP3 file":"Choose an MP3 file"} <a onClick={()=>{this.setState({uploadMP3: !this.state.uploadMP3})}} className="btn btn-primary-dark btn-sm">{!this.state.uploadMP3?"Perform MP3 file upload": "Post MP3 link"}</a></label>
                                      {!this.state.uploadMP3?
                                        <input name="soundcloud" required value={this.state.edit.multimedia} onChange={this.onChangeEdit} type="url" placeholder="https://.../..mp3" className="form-control"/>
                                      :<div className="form-group mb-4">
                                        <label for="form16">Choose an MP3 file</label>
                                        <input type="file" name="audio_file" accept=".mp3" className="form-control"></input>
                                    </div>}
                                      </div>:this.state.edit.type==="video"?
                                    <span>
                                    <div className="form-group mb-4">
                                      <label for="form16">Link to MP4 file</label>
                                      <input name="video" required value={this.state.edit.multimedia} onChange={this.onChangeEdit} type="url" placeholder="https://.../..mp4" className="form-control"/>
                                    </div>
                                    <div className="form-group mb-4">  
                                      <div>
                                        <label for="form16">Choose an MP4 file</label>
                                        <input type="file" name="video_file" accept=".mp4" className="form-control"></input>
                                      </div>
                                    </div></span>:null}
                                    {this.state.edit_multimedia_error?<label className="text-danger">{this.state.edit.type==="youtube"?"Invalid Youtube Link":this.state.edit.type==="audio"?"Invalid mp3 Link":"Invalid mp4 Link"}</label>:null}
                                    <div className="form-group mb-4">
                                      <label for="form16">{this.state.edit.type!=="docs"?"Additional Link":"Document Link"}</label>
                                      <input name="link" value={this.state.edit.link} onChange={this.onChangeEdit} type="url" placeholder={this.state.edit.type!=="docs"?"Additional Link":"Document Link"} className="form-control"/>
                                    </div>
                                    {this.state.update_error?<label>{this.state.update_error}</label>:this.state.update_msg?<label>{this.state.update_msg}</label>:null}
                                    {!this.state.updating?<button className="btn btn-dark btn-md float-right text-capitalize">Edit Notification</button>:<img src={loader} className="smallLoader"/>}

                    </form>
                    {this.state.edit_error?<center><label className="text-danger">{this.state.edit_error}</label></center>:null}
                    </div>
                </div>
            </div>:this.state.create_category?
                <div></div>
            :!this.state.list_recipients?<div className="col">
                            <div className="ibox">
                                <div className="ibox-body tab-content">
                                  <h4><b>Push Messages</b></h4>
                                  <h6>Send Messages to your Customers</h6>
                                  <form className="p-3" onSubmit={this.pushMessage}>
                                    <div className="form-group mb-4">
                                      <label for="form16">Title</label>
                                      <input name="push_title" required value={this.state.push_title} onChange={this.onChange} type="text" placeholder="Message Title" className="form-control"/>
                                    </div>
                                    <div className="form-group mb-4">
                                      <label for="form16">Message</label>
                                      <textarea name="push_message" required value={this.state.push_message} maxLength="400" onChange={this.onChange} type="text" placeholder="Message" className="form-control"></textarea>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label for="form16">Type</label>
                                        <select  required name="push_type" value={this.state.push_type} disabled={this.state.uploading} className="form-control" onChange={this.onChange}>
                                            <option value="">Select Option</option>
                                            <option value="text">Send Message</option>
                                            <option value="youtube">Send YouTube  Video</option>
                                            <option value="video">Send Video Clips</option>
                                            <option value="docs">Send Document</option>
                                            <option value="audio">Send Audio</option>
                                        </select>
                                    </div>
                                    {this.state.push_type==="youtube"?<div className="form-group mb-2">
                                      <label for="form16">YouTube Link</label>
                                      <input name="push_youtube" required value={this.state.push_youtube} onChange={this.onChange} type="url" placeholder="https://youtu.be/...." className="form-control"/>
                                    </div>:this.state.push_type==="audio"?
                                    <span><div className="form-group mb-4">
                                    <label for="form16">{!this.state.uploadMP4?"Link to MP3 file":null}</label>
                                    
                                    <input name="push_soundcloud" value={this.state.push_audio} onChange={this.onChange} type="url" placeholder="https://.../..mp3" className="form-control"/>
                                    </div>  
                                    <center>-<b>OR</b>-</center>
                                      <div className="form-group mb-4">
                                        <label for="form16">Choose an MP3 file</label><br/>
                                        <input value={this.state.push_audio_file} name="push_audio_file" className="form-control" onChange={this.onChange} type="file" accept=".mp3"></input>
                                      </div>
                                  </span>
                                    :this.state.push_type==="video"?
                                    <span><div className="form-group mb-4">
                                      <label for="form16">{!this.state.uploadMP4?"Link to MP4 file":null}</label>
                                      
                                      <input name="push_video" value={this.state.push_video} onChange={this.onChange} type="url" placeholder="https://.../..mp4" className="form-control"/>
                                      </div>  
                                      <center>-<b>OR</b>-</center>
                                        <div className="form-group mb-4">
                                          <label for="form16">Choose an MP4 file</label><br/>
                                          <input value={this.state.push_video_file} name="push_video_file" className="form-control" onChange={this.onChange} type="file" accept=".mp4"></input>
                                        </div>
                                    </span>:null}
                                    {this.state.push_multimedia_error?<label className="text-danger">{this.state.push_type==="youtube"?"Invalid Youtube Link":this.state.push_type==="audio"?"Invalid mp3 Link":"Invalid mp4 Link"}</label>:null}
                                    <div className="form-group mb-4">
                                      <label for="form16">{this.state.push_type!=="docs"?"Additional Link":"Document Link"}</label>
                                      <input name="push_link" value={this.state.push_link} onChange={this.onChange} type="url" placeholder={this.state.push_type!=="docs"?"Additional Link":"Document Link"} className="form-control"/>
                                    </div>
                                    {!this.state.push_change?<button className="btn btn-dark text-capitalize" disabled={!(this.state.push_title&&this.state.push_message&&this.state.push_type&&(this.state.push_multimedia||this.state.selectedFile))}>Send Message</button>:<img src={loader} className="smallLoader"/>}
                                    {this.state.push_error?<h5 className="text-danger">{this.state.push_error}</h5>:null}
                                    {this.state.push_success?<h5 className="text-primary">{this.state.push_success}</h5>:null}
                                  </form>
                                </div>
                            </div>
            </div>:<div>
                <div className="ibox-body tab-content">
                        <div className="limit-width">
                        <center>
                          <table className="specialTable col-12">
                            <thead>
                              <tr className="specialTable">
                                <th>S/N</th>
                                <th></th>
                                <th>Title</th>
                                <th>Message</th>
                                <th>Type</th>
                                <th>Multimedia</th>
                                <th>Additional Link</th>
                              </tr>
                            </thead>
                            <tbody>{notifications}</tbody>
                         </table>
                        </center>
                        {Object.keys(this.state.notifications).length===0?<div className="solidBorder">
                            <center><img src={reportpng} className="m-3 smallImg"/></center>
                            <center><h3 className="eee"><b>No Notifications</b></h3></center>
                        </div>:null}
                        </div>
                </div>
            </div>}
            </div>
        </div>
        </div>
        <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              shouldCloseOnOverlayClick={false}
              style={this.state.customStyles}
              contentLabel="Example Modal">
              <h4><b>Delete Notification</b></h4>
              <h5>Are you sure you want to delete this notification</h5>
              {this.state.delete_error?<label className="text-danger">{this.state.delete_error}</label>:null}
              {!this.state.deleting?<div className="float-right">
                      <button onClick={()=>{this.setState({modalIsOpen: false})}} className="btn btn-primary-dark btn-md text-capitalize">No</button>
                      <button onClick={this.deleteNotification} className="ml-2 btn btn-dark btn-md text-capitalize">Yes</button>
            </div>:this.state.deleting?<div><img src={loader} className="smallLoader"/></div>:null}

        </Modal>

        </div>

    }

}

export default NotificationComponent;