
//ACTIONS
export const SET_USER = 'SET_USER';
export const TOGGLE_USER = 'TOGGLE_USER';

//ACTION CREATORS
export function setUser({username, fancy_username, fullname, bio, location, dp_bucket, dp_object, wallpaper_bucket, wallpaper_object, created, followers_num, followed_num, followed,showFollow,follow_id}){
  return { type: SET_USER, username, fancy_username, fullname, bio, location, dp_bucket, dp_object, wallpaper_bucket, wallpaper_object, created, followers_num, followed_num, followed, showFollow, follow_id }
}

export function setUserDrafts({}){

}
