import React, { Component } from 'react';
// ES module

import loader from "../load.gif";
import {ApiService} from '../../services';
import { Link } from 'react-router-dom';
import countries from './countries.json';
import States from './states.json';
import logo from '../../assets/img/blog/logo.png';
import logoXCel from '../../assets/img/blog/logoXCel.png';
import logoRSwitch from '../../assets/img/blog/RSwitch_Logo.jpg';
import shortid from 'shortid';

class RegisterComponent extends Component{

    constructor(){
        super(); 
        //console.log()
    
        this.state = {
            show: 1,
            redirect: false,
            mailing_list: 0,
            director: {
                name: '',
                phone: '',
                email: ''
            },
            mails: [],
            states: [],
            inc_no: '',
            country_code: '',
            reg_name: '',
            reg_address: '',
            street: '',
            city: '',
            county: '',
            post:'',
            nature_business: '',
            entity_type: '',
            face_remote: '',
            transaction_type: '',
            volume: '',
            value: '',
            country: '',
            bank: '',
            currency: {},
            sort_code: '',
            route_no: '',
            account_no: '',
            password: '',
            cpassword: '',
            doKYC: '',
            user_id: shortid.generate(),
            checkingKYC: false
        }

        this.receiveMessage = this.receiveMessage.bind(this);
        this.receiveDocMessage = this.receiveDocMessage.bind(this);
    }

    componentDidMount() {
        //this.checkKyc();
        //setInterval(this.checkKyc, 10000);
        if(window.location.hostname==="rswitch.xcelapp.com" || window.location.hostname==="rswitch.etranzactglobal.com"){
            this.setState({logo: logoRSwitch,powered_by: true, logoClass: "logoBig ml-3 mt-3"});
        }else if((window.location.hostname==="merchant.etranzactglobal.com")){
            this.setState({logo: logo,powered_by: true, logoClass:"logoSmall ml-3 mt-3", headerClass: "header-topbar"});
        }else{
            this.setState({logo: logoXCel,powered_by: true, logoClass:"logoSmall ml-3 mt-3", headerClass: "header-topbar"});
        }
        this.getCompany()
        this.fetchTypes();
    }

    async checkKyc(){
        if(this.state.checkingKYC){
            ApiService.checkKYCStatus(this.state.scanReference)
            .then((res)=>{
                if(res.data.data.status==="SUCCESS"){
                    this.setState({kyc_status:res.data.data.status,reason: res.data.data.reason, checkingKYC: false, kyc_error:'',kyc_pending:''})
                }else if(res.data.data.status==="ERROR"){
                    this.setState({kyc_error:res.data.data.status,reason: res.data.data.reason, checkingKYC: false})
                }else if(res.data.data.status==="PENDING"){
                    this.setState({kyc_pending:res.data.data.status,reason: res.data.data.reason, kyc_error: false})
                }  
                
            },(error)=>{

            })
        }
    }

    receiveMessage(event) {
        var data = window.JSON.parse(event.data);
        console.log('Netverify Web was loaded in an iframe.');
        console.log('auth token:', data.authorizationToken);
        console.log('transaction reference:', data.transactionReference);
        console.log('customer internal reference:', data.customerInternalReference);
        console.log('event type:', data.eventType);
        console.log('date-time:', data.dateTime);
        console.log('event value:', data.payload.value);
        console.log('event metainfo:', data.payload.metainfo);

        if(data.payload.value==='success'){
            this.setState({id_valid: true});
        }
    }

    receiveDocMessage(event) {
        var data = window.JSON.parse(event.data);
        console.log('Netverify Web was loaded in an iframe.');
        console.log('auth token:', data.authorizationToken);
        console.log('transaction reference:', data.transactionReference);
        console.log('customer internal reference:', data.customerInternalReference);
        console.log('event type:', data.eventType);
        console.log('date-time:', data.dateTime);
        console.log('event value:', data.payload.value);
        console.log('event metainfo:', data.payload.metainfo);

        if(data.payload.value==='success'){
            this.setState({doc_valid: true});
        }
    }


    fetchTypes = ()=>{
        /**ApiService.fetch_categories()
        .then((res)=>{
            console.log(res.data.data);
            this.setState({type: res.data.data})
        },(error)=>{
            console.log(error);
        });*/
    }


    viewBanks = (country_code) => {
        ApiService.fetch_banks(country_code)
        .then((res)=>{
          console.log(res.data.data);
          this.setState({banks: res.data.data});
        },(error)=>{
          console.log(error)
          let zerror = error.response? error.response.data.errors:error.toString();
          this.setState({banks_error: zerror, banks: false});
        })
    }

    getCompany = ()=>{
        const companyRegNumber = "05543195";
        ApiService.getCompany(companyRegNumber)
        .then((res)=>{
            console.log(res)
            this.setState({})
        },(error)=>{
            console.log(error.toString);
            this.setState({});
        })
    }

    fetchCountryStates= (Country) =>{
        console.log("STATES")
        States.countries.filter((country)=>{
            if(country.country==Country){
                console.log(country.states);
                this.setState({states: country.states})
            }
        });
    }

    nextBtn = (e)=>{
        e.preventDefault();
        this.setState({error: ""});
        if(this.state.show===1){
            if(this.state.country_code!=="" && this.state.inc_no!=="" && this.state.reg_name!=="" && this.state.reg_address!=="" && this.state.street!=="" && this.state.city!=="" && this.state.county!=="" && this.state.post!==""){
                if(this.state.country_code==="001" || this.state.country_code==="044"){this.doKYC()}
                this.setState({show: this.state.show+1});
            }else{
                this.doDocKYC();
                this.setState({error: "Fill all fields"});
            }
        }else if(this.state.show===2){
            if(this.state.entity_type!=="" && this.state.nature_business!==""){
                this.setState({show: this.state.show+1})
            }else{
                this.setState({error: "Fill all fields"});
            }
        }else if(this.state.show===3){
            if(this.state.director.email !=="" && this.state.director.firstName!=="" && this.state.director.lastName!=="" && this.state.director.phone!==""){
                console.log(this.state.director);
                //alert(Object.keys(this.state.director).length);
                if((this.state.country_code==="001" || this.state.country_code==="044")){
                    //this.setState({show: this.state.show+1});
                    //this.setState({show:this.state.show+1});
                    this.setState({show:6});
                }else{
                    this.setState({show:6});
                }                
            }else{
                this.setState({error: "Fill all fields"});
            }
        }else if(this.state.show===4){
            if(this.state.id_valid){
                console.log("triggering");
                this.setState({show: this.state.show+1});
                if(!this.state.checkingDocKYC){
                    this.doDocKYC();
                }
            }else{
                this.setState({error: "Complete Director Verification"});
            }
        }else if(this.state.show===5){
            if(this.state.doc_valid){
                console.log("triggering");
                this.setState({show: this.state.show+1});
            }else{
                this.setState({error: "Complete Document Verification"});
            }
        }else if(this.state.show===6){
            if(this.state.face_remote!=="" &&  this.state.transaction_type!=="" && this.state.volume!=="" && this.state.value!==""){
                this.setState({show: this.state.show+1})
            }else{
                this.setState({error: "Fill all fields"});
            }
        }else if(this.state.show===7){
            console.log(this.state.country+" "+this.state.bank+" "+this.state.account_no);
            if(this.state.country!=="" && this.state.account_no!==""){
                this.setState({show: this.state.show+1})
            }else{
                this.setState({error: "Fill all fields"});
            }
        }else if(this.state.show===8){
            if(this.state.password!=="" && this.state.cpassword){
                if(this.state.password===this.state.cpassword){
                    this.createMerchant();
                }else{
                    this.setState({error: "Passwords don't match"});
                }
            }else{
                this.setState({error: "Fill all fields"});
            }
        }
    }

    prevBtn = (e)=>{
        if(this.state.show>1){
            if(this.state.country_code==="044"||this.state.country_code==="001"){
                this.setState({show: this.state.show-1, error: ""})
            }else{
                if(this.state.show===5){
                    this.setState({show: 3, error: ""})
                }else{
                    this.setState({show: this.state.show-1, error: ""})
                }
            }
            /**if(this.state.show!==5){
                this.setState({show: this.state.show-1, error: ""})
            }if(this.state.show===5 && (this.state.country_code==="044"||this.state.country_code==="001")){
                this.setState({show: this.state.show-1, error: ""})
            }else(this.state.show===5 && (this.state.country_code==="044"||this.state.country_code==="001")){
                this.setState({show: 3, error: ""})
                
            }*/
        }
    }

    fetchCountries = () =>{
        ApiService.fetch_countries()
        .then((res)=>{

        },(error)=>{

        })
    } 

    createWalletNo = ()=>{

    }

    merchantCreator = (merchant_id, mails, inc_no, country_code, reg_name, reg_address, street, city, county, post, entity_type, nature, director, face_remote, transaction_type, volume, value, country, bank_name, bank, sort_code, route_no, account_no,currency, password, wallet_no, account_data)=>{
        ApiService.createMerchant(merchant_id, mails, inc_no, country_code, reg_name, reg_address, street, city, county, post, entity_type, nature, director, face_remote, transaction_type, volume, value, country, bank_name, bank, sort_code, route_no, account_no,currency, password, wallet_no,account_data)
        .then((res)=>{                
            //alert("Bomb");
            this.setState({redirect: true, submitting: false});
        },(error)=>{
            //alert("Snoopi Ferguson")
            console.log(error);
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({error: zerror, submitting: false});
        }) 
    }

    createMerchant = ()=>{
        console.log(this.state);
        const mails = this.state.mails;
        const inc_no = this.state.inc_no;
        const country_code = this.state.country_code;
        const reg_name = this.state.reg_name;
        const reg_address = this.state.reg_address;
        const street = this.state.street;
        const city = this.state.city;
        const county = this.state.county;
        const post = this.state.post;
        const entity_type = this.state.entity_type;
        const nature = this.state.nature_business;
        const director = this.state.director;
        const face_remote = this.state.face_remote;
        const transaction_type =  this.state.transaction_type;
        const volume = this.state.volume;
        const value = this.state.value;
        const country = this.state.country;
        const bank = this.state.bank;
        const bank_name = this.state.bank_name;
        const sort_code = this.state.sort_code;
        const route_no = this.state.route_no;
        const account_no = this.state.account_no;
        const currency = this.state.currency;
        const password = this.state.password;
        const cpassword = this.state.cpassword;
        const merchant_id = this.state.user_id;

        console.log(password);
        if(password===cpassword){
            this.setState({submitting: true});
            let randomNumber = Math.floor(Math.random() * 1000000);
            let rand;
            if(randomNumber<10){
                rand = "00000"+randomNumber;
            }else if(randomNumber<100){
                rand = "0000"+randomNumber;
            }else if(randomNumber<1000){
                rand = "000"+randomNumber;
            }else if(randomNumber<10000){
                rand = "00"+randomNumber;
            }else if(randomNumber<100000){
                rand = "0"+randomNumber;
            }else{
                rand = randomNumber
            }


            //city,name,zip_code,address,state,merchant_id,email,currency_code,country_code
            const wallet_no = country_code+""+9999+rand;
            if(this.state.country_code=="044"){
                //alert(post);
                ApiService.createCustomer(inc_no,reg_name,reg_address,street,post,director,city,country_code,entity_type)
                .then((response)=>{
                    let account_data = response.data.data
                    console.log(account_data); 
                    //alert("Bomber");
                    this.merchantCreator(merchant_id, mails, inc_no, country_code, reg_name, reg_address, street, city, county, post, entity_type, nature, director, face_remote, transaction_type, volume, value, country, bank_name, bank, sort_code, route_no, account_no,currency, password, wallet_no,account_data)
                },(error)=>{
                    this.setState({error: "Error Creating UK Customer and Account",submitting: false});
                })
            }else{
                this.merchantCreator(merchant_id, mails, inc_no, country_code, reg_name, reg_address, street, city, county, post, entity_type, nature, director, face_remote, transaction_type, volume, value, country, bank_name, bank, sort_code, route_no, account_no,currency, password, wallet_no)
            }
        }else{
            this.setState({error: "Passwords don't match",submitting: false});
        }
    }

    onChange = (e) =>{
        if(e.target.name!=="country_code"){
            this.setState({[e.target.name]:e.target.value});
        }else{
            console.log(e.target.value);
            this.setState({country: countries[e.target.value].country,currency: countries[e.target.value],country_code:countries[e.target.value].calling_code,country_index: e.target.value});
            console.log("BAAAAAAAAAAAAAD: "+countries[e.target.value].country);
            this.fetchCountryStates(countries[e.target.value].country);
            this.viewBanks("234");
            //this.viewBanks(countries[e.target.value].calling_code);
        }

        if(e.target.name==="country"){
            console.log("ABIMBOLU");
            this.viewBanks(e.target.value)
        }

        if(e.target.name==="bank"){
            console.log(e.target.value);
            let bank_name = this.state.banks.filter((bank)=>{
                console.log(bank);
                console.log(bank.bank_name)
                if(bank.bank_code===e.target.value){
                    return bank.bank_name;
                }
            });

            //console.log(bank_name[0].bank_name)

            this.setState({bank_name: bank_name[0].bank_name})
        }

        if(e.target.name==="bank_name"){
            this.setState({[e.target.name]:e.target.value});
        }

        console.log(this.state.bank);

    }

    onChangeDirector = (e) =>{

        let director = this.state.director;

        if(e.target.name==="contact_first_name"){

            director.firstName = e.target.value;
            this.setState({director: director});
        
        }else if(e.target.name==="contact_last_name"){

            director.lastName = e.target.value;
            this.setState({director: director});
        
        }else if(e.target.name==="contact_email"){
        
            director.email = e.target.value;
            this.setState({director: director});
        
        }else if(e.target.name==="contact_phone"){
        
            director.phone = e.target.value;
            this.setState({director: director});

        }

        console.log(this.state.director);
    }

    onChangeMailList = (e) =>{
        let index = parseInt(e.target.name);
        let mails = this.state.mails;
        mails[index] = e.target.value;
        console.log(mails);
        this.setState({mails: mails})
    }

    pushChange = (e)=>{
        e.preventDefault();

        let length = this.state.mails.length;

        let arr = this.state.mails;

        arr.push('');

        this.setState({mails: arr});
    }

    doKYC = ()=>{

        this.setState({loadingKYC: true});

        ApiService.kycInit(this.state.user_id)
        .then((response)=>{
            console.log(response);
            let res = JSON.parse(response);
            console.log(window);
            window.addEventListener("message", this.receiveMessage, false);
            this.setState({loadingKYC: false, kyc: res,checkingKYC:true, scanReference: res.transactionReference, redirectUrl: res.redirectUrl});
            //window.open(res.redirectUrl,"_blank");
        },(error)=>{
            this.setState({error: 'KYC Initiation Failed',loadingKYC: false})
        });
    }

    doDocKYC = ()=>{
        let country = false;
        if(this.state.country_code==="001"){
            country = "USA"
        }else if(this.state.country_code==="044"){
            country = "GBR"
        }else{
            this.setState({show: this.state.show+1});
        }

        if(country){
            console.log("Blue")
        ApiService.kycDocInit(this.state.user_id,country,this.state.user_id)
        .then((response)=>{
            console.log("I am king fikky!");
            console.log("Grey");
            let res = JSON.parse(response);
            console.log(response);
            window.addEventListener("message", this.receiveDocMessage, false);
            this.setState({loadingKYC: false, kyc: res,checkingDocKYC:true, scanReference: res.scanReference, redirectDocUrl: res.clientRedirectUrl});
            //window.open(res.redirectUrl,"_blank");
        },(error)=>{
            console.log("Black");
            console.log(error);
            this.setState({error: 'KYC Initiation Failed',loadingKYC: false})
        });    
        }
    }

    validateBank = (e) =>{
        e.preventDefault();
        //alert("Validate Bank");
        this.setState({adding_bank: true})
        ApiService.validate_account(this.state.account_no,this.state.bank)
        .then((res)=>{
            //let data = JSON.parse(res.data)
            if(res.data.response_code===0 || res.data.response_code==="0"){
                console.log(res.data)

                this.setState({new_acct_name: res.data.response_message, valid_bank: true,new_error: false, adding_bank: false, disable_add: true})
            }else{
                this.setState({new_error: res.data.response_message,new_acct_name: false, adding_bank: false});
            }
        },(error)=>{
            console.log(error);
            this.setState({new_error: "Error Validating Bank",new_acct_name:false, adding_bank: false});
        })
    }


    render(){
        let countryList = countries.map((country, index)=>{
            if(country.calling_code!==null){
              return <option value={index}>{country.country}</option>
            }else return ""
        });

        let countriesList = countries.map((country, index)=>{
            if(country.calling_code!==null){
              return <option value={country.calling_code}>{country.country}</option>
            }else return ""
        });  

        let statesList = this.state.states.map((state,index)=>{
            return <option value={state}>{state}</option>
        })
        
        let currenciesList = countries.map((country, index)=>{
            if(country.calling_code!==null && country.currency_code!==""){
              return <option value={index}>({country.currency_code}) {country.country}</option>
            }else return ""
        });  

        let mailingList = this.state.mails.map((country, index)=>{
            //console.log(this.state.mailing_list)
            return <div className="form-group mb-4 row col-10">
                <label for="form16" className="text-dark bold">Director Email</label>
                <input  type="text" placeholder="Director Email Address" value={this.state.mails[index]} onChange={this.onChangeMailList} required className="form-control" name={index}/>    
            </div>
        });

        let banksList;
        if(this.state.banks){
          banksList= this.state.banks.map((bank, index)=>{
              return <option value={bank.bank_code}>{bank.bank_name}</option>
          });
        }
        console.log(this.state.currency)
        
        const activeClass1 = `p-3 pl-5 pr-5 ${this.state.show===1 ? " activate" : ""}`;
        const activeClass2 = `p-3 pl-5 pr-5 ${this.state.show===2 ? " activate" : ""}`;
        const activeClass3 = `p-3 pl-5 pr-5 ${this.state.show===3 ? " activate" : ""}`;
        const activeClass4 = `p-3 pl-5 pr-5 ${this.state.show===4 ? " activate" : ""}`;
        const activeClass5 = `p-3 pl-5 pr-5 ${this.state.show===5 ? " activate" : ""}`;
        const activeClass6 = `p-3 pl-5 pr-5 ${this.state.show===6 ? " activate" : ""}`;
        const activeClass7 = `p-3 pl-5 pr-5 ${this.state.show===7 ? " activate" : ""}`;
        const activeClass8 = `p-3 pl-5 pr-5 ${this.state.show===8 ? " activate" : ""}`;
        
        return <div className="eee row">
        <div className="col-md-1"></div>
        <div className="col-md-10 mt-5">
        <div className="ibox row shadowy round-border-box">
          <div className="col-md-6">
            <br/> 
            <br/>
            <div className="pl-5 pr-5 p-md-2">
                <img src={this.state.logo} alt="logo" className={this.state.logoClass}/>
            </div>
            <div className="pl-5 pr-5 p-md-2">
                <div className="p-5">
                    {this.state.show===1?<div className="p-3">
                        <div>
                                <div className="form-group mb-4">
                                    <label for="form16" className="text-dark bold">Country of Incorporation</label>
                                    <select className="form-control"  required onChange={this.onChange} value={this.state.country_index} name="country_code">
                                        <option value=""></option>
                                        {countryList}
                                    </select>   
                                </div>                                
                                <div className="form-group mb-4">
                                    <label for="form16" className="text-dark bold">Incorporation Number</label>
                                    <input  type="text" required onChange={this.onChange} value={this.state.inc_no} className="form-control" name="inc_no"/>    
                                </div>
                                <div className="form-group mb-4">
                                    <label for="form16" className="text-dark bold">Registration Name</label>
                                    <input  type="text" required onChange={this.onChange} value={this.state.reg_name} className="form-control" name="reg_name"/>    
                                </div>
                                <div className="row">
                                    <div className="form-group mb-4 col-md-8">
                                        <label for="form16" className="text-dark bold">House/Flat No</label>
                                        <input  type="text" required onChange={this.onChange} value={this.state.reg_address} className="form-control" name="reg_address"/>    
                                    </div>
                                    <div className="form-group mb-4 col-md-4">
                                        <label for="form16" className="text-dark bold">Street Name</label>
                                        <input  type="text" required onChange={this.onChange} value={this.state.street} className="form-control" name="street"/>    
                                    </div>
                                </div>
                                <div className="row col-md-12">
                                    <div className="form-group mb-4 col-md-4">
                                        <label for="form16" className="text-dark bold">Town/City</label>
                                        <input  type="text" required onChange={this.onChange} value={this.state.city} className="form-control" name="city"/>    
                                    </div>
                                    <div className="form-group mb-4 col-md-4">
                                        <label for="form16" className="text-dark bold">State/County</label>
                                        <select className="form-control"  required onChange={this.onChange} value={this.state.county} name="county">
                                        <option value=""></option>
                                        {statesList}
                                    </select>
                                    </div>
                                    <div className="form-group mb-4 col-md-4">
                                        <label for="form16" className="text-dark bold">Post/Zip Code</label>
                                        <input  type="text" required onChange={this.onChange} value={this.state.post} className="form-control" name="post"/>    
                                    </div>
                                </div>
                        </div>
                    </div>
                    :this.state.show===2?
                    <div>
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Entity Type</label>
                            <select  type="text" required onChange={this.onChange} value={this.state.entity_type} className="form-control" name="entity_type">
                                <option></option>
                                <option value="LLC">Limited Liability Company (LLC)</option>
                                <option value="SOLE_PROPRIETORSHIP">Sole Proprietorship</option>
                                <option value="CHARITY">Charity</option>
                                <option value="RELIGIOUS">Religious Entity</option>
                                <option value="NON_PROFIT">Non Profit</option>
                                <option value="PLC">Public Liability Company (PLC)</option>
                                <option value="GOVERNMENT">Government Institution</option>
                                <option value="EDUCATIONAL">Educational</option>
                                <option value="MINSTRELS">Minstrels</option>
                            </select>    
                        </div>
                        <div className="form-group mb-4">
                            <label for="form16"className="text-dark bold">Nature of Business</label>
                            <input  type="text" required onChange={this.onChange} value={this.state.nature_business} className="form-control" name="nature_business"/>    
                        </div>
                    </div>:this.state.show===3?
                    <div>
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Director First Name</label>
                            <input  type="text" required onChange={this.onChangeDirector} value={this.state.director.firstName} className="form-control" name="contact_first_name"/>    
                        </div>
                        
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Director Last Name</label>
                            <input  type="text" required onChange={this.onChangeDirector} value={this.state.director.lastName} className="form-control" name="contact_last_name"/>    
                        </div>
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Director Telephone</label>
                            <input  type="text" required onChange={this.onChangeDirector} value={this.state.director.phone} className="form-control" name="contact_phone"/>    
                        </div>
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Director Email</label>
                            <input  type="email" required onChange={this.onChangeDirector} value={this.state.director.email} className="form-control" name="contact_email"/>    
                        </div>
                        <h3 className="text-dark"><b>Mailing List</b></h3>
                        <h5 className="text-dark bold">Who should we mail everytime money moves?</h5>                        
                        <div className="form-group mb-4 row">
                            <br/>    
                            <div className="col-10">
                                <label for="form16" className="text-dark bold">Director Email</label>
                                <input  type="text" required onChange={this.onChangeDirector} value={this.state.director.email} className="form-control" name="contact_email"/> 
                            </div>
                            <div className="col-2 mt-3">
                            <button className="btn btn-sm btn-dark mt-4" onClick={this.pushChange}><i className="fa fa-plus"></i></button>
                            </div>
                        </div>
                        {mailingList}
                    </div>:this.state.show===4?<div>
                            <center><h3 className="text-dark"><b>Please Validate Director's Identity</b></h3></center>
                            <iframe src={this.state.redirectUrl} className="limit-width vh50"></iframe>
                    </div>:this.state.show===5?<div>
                            <center><h3 className="text-dark"><b>Please Upload Your Valid Utility Bills</b></h3></center>
                            <iframe src={this.state.redirectDocUrl} className="limit-width vh50"></iframe>
                    </div>:this.state.show===6?
                    <div>
                        <div className="form-group mb-4">
                        <label for="form16" className="text-dark bold">Face to Face or Remote?</label>
                            <select className="form-control"  required onChange={this.onChange}  name="face_remote">
                                <option value=""></option>
                                <option value={false}>Face to Face</option>
                                <option value={true}>Remote</option>
                            </select> 
                        </div>
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Transaction Type</label>

                            <select className="form-control"  required onChange={this.onChange}  name="transaction_type">
                                <option value=""></option>
                                <option value={"OnlineGiving"}>Giving</option>
                                <option value={"SchoolPayments"}>School Payments</option>
                                <option value={"Cable"}>Cable</option>
                                <option value={"General"}>Others</option>
                                <option value={"SchoolPayments"}>Utilities</option>
                            </select>     
                        </div>
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Estimated Volume</label>
                            <input  type="number" required value={this.state.volume} onChange={this.onChange} className="form-control" name="volume"/>    
                        </div>
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Estimated Value (in {this.state.currency.currency_code})</label>
                            <input  type="number" value={this.state.value} required onChange={this.onChange} className="form-control" name="value"/>    
                        </div>
                    </div>:this.state.show===7?
                    <div>
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Bank</label>
                            <select type="text" required value={this.state.bank} name="bank" disabled={this.state.country_code==="044"} onChange={this.onChange} className="form-control">
                                <option></option>
                                {banksList}    
                            </select>    
                        </div>
                        {this.state.country_code==="044" || this.state.country_code==="001"|| this.state.country_code==="250"?<div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Bank Name</label>
                            <input  type="text" required value={this.state.bank_name} name="bank_name" disabled={this.state.disable_add} onChange={this.onChange} className="form-control"/>    
                        </div>:null}
                        {this.state.country_code==="044"?<div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Sort Code</label>
                            <input  type="text" required value={this.state.sort_code} name="sort_code" onChange={this.onChange} className="form-control"/>    
                        </div>:this.state.country_code==="001"?
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Route Number</label>
                            <input  type="text" required value={this.state.route_no} name="route_no" onChange={this.onChange} className="form-control"/>    
                        </div>
                        :null}
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Account Number</label>
                            <input  type="text" required value={this.state.account_no} disabled={this.state.disable_add} onChange={this.onChange} className="form-control" name="account_no"/>    
                        </div>
                        <h5>{this.state.new_acct_name}</h5>
                       <h5 className="text-danger">{this.state.new_error}</h5>
                    </div>:this.state.show===8?
                    <div>
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Director Email Address</label>
                            <input  type="text" value={this.state.director.email} disabled={true} required className="form-control"/>    
                        </div>
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Password</label>
                            <input  type="password" required value={this.state.password} onChange={this.onChange} className="form-control" name="password"/>    
                        </div>
                        <div className="form-group mb-4">
                            <label for="form16" className="text-dark bold">Confirm Password</label>
                            <input  type="password" required value={this.state.cpassword} onChange={this.onChange} className="form-control" name="cpassword"/>    
                        </div>          
                        <div className="form mb-4 text-dark">
                            <input type="checkbox" name="check" value="" required/> I accept all <u><a href="#" target="_blank">Terms and conditions</a></u><br/>
                        </div>              
                    </div>:null}




                    {this.state.redirect?<div>
                        <div className="alert alert-primary">Merchant Account created, <Link className="whiteText" to={'/'}>Click here to Login</Link> </div>
                    </div>:null}
                    {this.state.error?<div class="alert alert-danger" role="alert">
                        {this.state.error}
                    </div>:null}
                    <span className="col-md-9 row">
                        <div className="col-md-6">
                            <button onClick={this.prevBtn} disabled={this.state.show===1} className="btn btn-sm btn-block navbtn btn-dark-outline whiteBtn p-2 text-capitalize font15">
                                Prev
                            </button>
                        </div>
                        <div className="col-md-6">
                            {this.state.show===7 && this.state.country_code==="234" && !this.state.valid_bank?<button onClick={this.validateBank} className="btn btn-sm btn-block navbtn btn-dark white-btn p-2 text-capitalize font15">
                                Validate Bank
                                {this.state.adding_bank?<img src={loader} className="muchSmallLoader ml-3"/>:null}
                            </button>:<button onClick={this.nextBtn} className="btn btn-sm btn-block navbtn btn-dark white-btn p-2 text-capitalize font15">
                                Next
                                {this.state.submitting?<img src={loader} className="muchSmallLoader ml-3"/>:null}
                            </button>}
                        </div>
                    </span>
                </div>
                <center><small><u><Link to={'/'} className="blueText">Already have an account? Login</Link></u></small></center>
                <br/>
                {this.state.powered_by?<h5 className="ml-1">Powered by <img src={this.state.logo} className="tinyLogo"/></h5>:null}
            </div>
            
          </div>  
          <div className="col-md-6 dashed-border">
            <div className="p-5 col-12">
                <div className={activeClass1}>
                    <h5><b>Company Information</b></h5>
                    <h6 className="text-muted">Details required includes: </h6>
                </div>
                <div className={activeClass2}>
                    <h5><b>Business Details and Entity Type</b></h5>
                    <h6 className="text-muted">Details required includes: </h6>
                </div>
                <div className={activeClass3}>
                    <h5><b>Contact Information and Mailing List</b></h5>
                    <h6 className="text-muted">Details required includes: </h6>
                </div>
                {this.state.country_code==="044" || this.state.country_code==="001"?<div className={activeClass4}>
                    <h5><b>Validate Director's Identity</b></h5>
                    <h6 className="text-muted">Details required includes: </h6>
                </div>:null}
                {this.state.country_code==="044" || this.state.country_code==="001"?<div className={activeClass5}>
                    <h5><b>Validate Director's Document</b></h5>
                    <h6 className="text-muted">Details required includes: </h6>
                </div>:null}
                <div className={activeClass6}>
                    <h5><b>Organizational Details</b></h5>
                    <h6 className="text-muted">Details required includes: </h6>
                </div>
                <div className={activeClass7}>
                    <h5><b>Bank Details</b></h5>
                    <h6 className="text-muted">Details required includes: </h6>
                </div>
                <div className={activeClass8}>
                    <h5><b>Login Details</b></h5>
                    <h6 className="text-muted">Details required includes: </h6>
                </div>

            </div>
          </div>
        </div>
        </div>
        <div className="col-md-1"></div>
      </div>
    
    }
}

export default RegisterComponent;
