import {combineReducers} from 'redux'

import {SET_USER} from './actions';

const initialState = {
  username: "",
  fullname: "Anon Anonymous",
  bio: "No Bio",
  location: "Somewhere out there",
  dp_bucket: "",
  dp_object: "",
  wallpaper_bucket: "",
  wallpaper_object: "",
  created: "",
  followers_num: 0,
  followed_num: 0,
  followed: false,
  showFollow: false,
  follow_id: ""
}

const users = (state=initialState, action) =>{
  switch (action.type) {
    case SET_USER:
      return Object.assign({},state,{
        username: action.username,
        fancy_username: action.fancy_username || initialState.fancy_username,
        fullname: action.fullname || initialState.fullname,
        bio: action.bio || initialState.bio,
        location: action.location || initialState.location,
        dp_bucket: action.dp_bucket || initialState.dp_bucket,
        dp_object: action.dp_object || initialState.dp_bucket,
        wallpaper_bucket: action.wallpaper_bucket || initialState.wallpaper_bucket,
        wallpaper_object: action.wallpaper_object || initialState.wallpaper_object,
        created: action.created || initialState.created,
        followers_num: action.followers_num || initialState.followers_num,
        followed_num: action.followed_num || initialState.followed_num,
        followed: action.followed || initialState.followed,
        showFollow: action.showFollow,
        follow_id: action.follow_id || initialState.follow_id
      });
    default:
      return state
  }
}

export default combineReducers({
  user: users
})
