import React, { Component } from 'react';
// ES module
import logo from '../../assets/img/blog/logo.png';
import { Link } from 'react-router-dom';
import { ApiService } from '../../services';
import moment from 'moment';
import reportpng from '../../assets/img/icons/report.png';

class ProductsComponent extends Component{
  constructor(){
    super();

    this.state = {
      page: 1,
      level: 1,
      session: JSON.parse(localStorage.getItem("SESSION")),
      payments: {},
      searching: false,
      product: {
          name: ''
      }
    }
  }

  componentDidMount(){
    this.fetchOverview();
    this.fetchProduct();
  }

  fetchOverview = ()=>{
    ApiService.fetchProduct(this.props.product_id,this.state.session.merchant_id,this.props.date)
    .then((response)=>{
        console.log(response.data.data);
        if(response.data.data){
          this.setState({payments: response.data.data});
        }
    },(error)=>{
        console.log(error);
    });

  }

  fetchProduct = ()=>{
    ApiService.fetchSingleProduct(this.props.product_id,this.props.date)
    .then((response)=>{
        console.log(response.data.data);
        if(response.data.data){
          this.setState({product: response.data.data});
        }
    },(error)=>{
        console.log(error);
    });     
  }

  onSearch = (e)=>{
        if(e.target.value === ""){
            this.setState({searching: false});
        }else{
            console.log(e.target.value);
            this.setState({searching: true, param: e.target.value});
        }
  }

  numberWithCommas(x) {
    x = (Math.round(x * 100) / 100).toFixed(2)
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  render(){
    let Payments;
    if(Object.keys(this.state.payments).length>0){
        if(!this.state.searching){
            Payments = this.state.payments.map((data,index)=>{
                return <tr>
                    <td className="whiteBG">{index+1}</td>
                    <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                    <td>{moment(data.date).format('LT')}</td>
                    <td>{data.ref_no || data.src_account}</td>
                    <td>{data.channel_id}</td>
                    <td>{data.src_first_name} {data.src_last_name}</td>
                    <td>{this.state.session.currency.currency_code}<span className="float-right">{this.numberWithCommas(data.des_amount_paid)}</span></td>
                     <td>{data.description}</td>
                     <td><center><input type="checkbox" checked={data.sent_status} disabled/></center></td>
                </tr>
             });
        }else{
            let count = 0;
            Payments = this.state.payments.map((data,index)=>{
                console.log(data)
                if(this.state.param === (data.ref_no).substring(0,(this.state.param).length) || this.state.param === (data.src_account).substring(0,(this.state.param).length)){     
                    count = count+1;
                    return <tr>
                        <td className="whiteBG">{count}</td>
                        <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                        <td>{moment(data.date).format('LT')}</td>
                        <td>{data.ref_no || data.src_account}</td>
                        <td>{data.channel_id}</td>
                        <td>{data.src_first_name} {data.src_last_name}</td>
                        <td>{this.state.session.currency.currency_code}<span className="float-right">{this.numberWithCommas(data.des_amount_paid)}</span></td>
                        <td>{data.description}</td>
                        <td><center><input type="checkbox" checked={data.sent} disabled/></center></td>
                    </tr>
                }
             });
        }
    }

    //console.log();
    return <div className="ibox-body tab-content">
      <div className="float-right mb-3"><input className="form-control" placeholder={this.state.session.p_refname?'Enter '+this.state.session.p_refname+'':'Enter Reference Number'} onChange={this.onSearch}/></div>
  <h4 className="text-capitalize"><b>{this.props.product_name} Earnings {moment(this.props.date).format('DD-MM-YYYY')}</b></h4>
        <div className="limit-width">
          <table className="specialTable col-12">
            <thead>
              <tr className="specialTable">
                <th>S/N</th>
                <th>Date</th>
                <th>Time</th>
                <th>{this.state.session.p_refname?''+this.state.session.p_refname+'':'Reference Number'}</th>
                <th>Channel</th>
                <th>Full Name</th>
                <th>Amount Received</th>
                <th>Description</th>
                <th>Settled</th>
              </tr>
            </thead>
            <tbody >
              {Payments}
            </tbody>
          </table>
          {!Payments?<div className="solidBorder">
            <center><img src={reportpng} className="m-3 smallImg"/></center>
            <center><h3 className="eee"><b>No Data Found</b></h3></center>
        </div>:null}
      </div>
    </div>
  }
}

export default ProductsComponent;