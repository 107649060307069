import React, { Component } from 'react';
import moment from 'moment';
import { ApiService } from '../../services';
import Modal from 'react-modal';
import loader from "../loading.gif";
import reportpng from '../../assets/img/icons/report.png';

class ReportsComponent extends Component{
    constructor(){
        super();

        this.state = {
            data: [],
            failed: [],
            transactions: [],
            customStyles : {
                content : {
                  top                   : '50%',
                  left                  : '50%',
                  right                 : 'auto',
                  bottom                : 'auto',
                  marginRight           : '-50%',
                  transform             : 'translate(-50%, -50%)'
                }
            },
            country: {
                currency: "NGN",
                country: "NG"
            }
        }
    }


    onChange = (e)=>{
        this.setState({[e.target.name]:e.target.value});
    }

    componentDidMount(){
        console.log(this.props.data)
        console.log(this.props.failed)
        this.setState({data: this.props.data,failed:this.props.failed,loading_reports:this.props.loading_reports, loading_failed:this.props.loading_failed});
    }


    componentDidUpdate(prevProps,prevState){
        if(prevProps!==this.props){
            console.log(this.props.data)
            console.log(this.props.failed)
            this.setState({data: this.props.data,failed:this.props.failed,loading_reports:this.props.loading_reports, loading_failed:this.props.loading_failed});
        }
    }

    showDetails = (transaction_id)=>{

    }

    showTransactions = (index)=>{
        this.setState({showTransactions: true, index: index, transactions:this.state.data[index].transactions, trans_type:this.state.data[index]._id})
    }

    reprocess = (data)=>{
    }

    numberWithCommas(x) {
        x = (Math.round(x * 100) / 100).toFixed(2)
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }


    //type; total volume; total value; total successful; successful value; total failed; failed value


    render(){

        let Data,headers,Failed,Transactions;

        console.log(this.state.data)
        if(Object.keys(this.state.data).length>0){
            Data = this.state.data.map((data,index)=>{
                let length = (data.transactions).length;                
                console.log(data);
                let value =0 ,success = 0,success_value = 0,failed = 0,failed_value = 0;

                for(let i=0;i<data.transactions.length;i++){
                    if(data.transactions[i].resolved===true){
                        success++
                        success_value+=data.transactions[i].amount
                    }

                    if(data.transactions[i].resolved!==true){
                        failed++
                        failed_value+=data.transactions[i].amount
                    }

                    value+=data.transactions[i].amount
                }

                return<tr onClick={()=>{this.showTransactions(index)}}> 
                    <td className="whiteBG text-capitalize"><b>{data._id}</b></td>
                    <td>{length}</td>
                    <td> <label className="float-right">{this.numberWithCommas(value)}</label></td>
                    <td>{success}</td>
                    <td> <label className="float-right">{this.numberWithCommas(success_value)}</label></td>
                    <td>{failed}</td>
                    <td> <label className="float-right">{this.numberWithCommas(failed_value)}</label></td>
                </tr>
            }).filter(Boolean);
        }

        if(Object.keys(this.state.transactions).length>0){
            
                Transactions = this.state.transactions.map((data,index)=>{
                    //console.log(data.payer_details[0].first_name);
                    if(!this.state.transactions_param){
                        return <tr>
                            <td className="whiteBG">{data.received}</td>
                            <td>{data.trans_id}</td>
                            <td>{(data.resolved).toString()}</td>
                            <td>{data.reason}</td>
                            <td> <label className="float-right">{this.numberWithCommas(data.amount)}</label></td>
                            <td>{data.src_wallet}</td>
                            <td>{data.payer}</td>
                            <td>{(data.payer_details&& data.payer_details[0])?data.payer_details[0].first_name+" "+data.payer_details[0].last_name:"N/A"}</td>
                            <td>{data.payee}</td>
                            <td>{(data.payee_details&& data.payee_details[0])?data.payee_details[0].first_name+" "+data.payee_details[0].last_name:"N/A"}</td>
                        </tr>
                    }else{
                        if(
                            (data.src_wallet && (this.state.transactions_param===(data.src_wallet).substring(0,(this.state.transactions_param).length))) ||
    
                            (data.trans_id && (this.state.transactions_param===(data.trans_id).substring(0,(this.state.transactions_param).length))) ||
                            
                            (data.reason && (this.state.transactions_param===(data.reason).substring(0,(this.state.transactions_param).length))) ||
    
                            (data.payee && (this.state.transactions_param===(data.payee).substring(0,(this.state.transactions_param).length))) ||
    
                            ((data.payee_details && data.payee_details[0]) && (this.state.transactions_param===(data.payee_details[0].first_name).substring(0,(this.state.transactions_param).length) || this.state.transactions_param===(data.payee_details[0].last_name).substring(0,(this.state.transactions_param).length))) ||
                        
                            ((data.payer_details && data.payer_details[0]) &&(this.state.transactions_param===(data.payer_details[0].first_name).substring(0,(this.state.transactions_param).length) || this.state.transactions_param===(data.payer_details[0].last_name).substring(0,(this.state.transactions_param).length) || this.state.transactions_param===(data.payer_details[0].first_name).substring(0,(this.state.transactions_param).length)))
                        ){
                        return <tr>
                            <td className="whiteBG">{data.received}</td>
                            <td>{data.trans_id}</td>
                            <td>{(data.resolved).toString()}</td>
                            <td>{data.reason}</td>
                            <td> <label className="float-right">{this.numberWithCommas(data.amount)}</label></td>
                            <td>{data.src_wallet}</td>
                            <td>{data.payer}</td>
                            <td>{(data.payer_details&& data.payer_details[0])?data.payer_details[0].first_name+" "+data.payer_details[0].last_name:"N/A"}</td>
                            <td>{data.payee}</td>
                            <td>{(data.payee_details&& data.payee_details[0])?data.payee_details[0].first_name+" "+data.payee_details[0].last_name:"N/A"}</td>
                        </tr>
                        }
                    }
                }).filter(Boolean);
            
        }


        if(Object.keys(this.state.failed).length>0){
            Failed = this.state.failed.map((data,index)=>{
                if(!this.state.failed_param){
                console.log(data);
                return <tr>
                    <td className="whiteBG">{data.received}</td>
                    <td>{data.trans_id}</td>
                    <td>{(data.resolved).toString()}</td>
                    <td>{data.reason}</td>
                    <td> <label className="float-right">{this.numberWithCommas(data.amount)}</label></td>
                    <td>{data.src_wallet}</td>
                    <td>{data.payer}</td>
                    <td>{(data.payer_details&& data.payer_details[0])?data.payer_details[0].first_name+" "+data.payer_details[0].last_name:"N/A"}</td>
                    <td>{data.payee}</td>
                    <td>{(data.payee_details && data.payee_details[0])?data.payee_details[0].first_name+" "+data.payee_details[0].last_name:"N/A"}</td>
                    <td><button className="primary">Investigate</button></td>
                </tr>
                }else{
                    if(
                        (data.src_wallet && (this.state.failed_param===(data.src_wallet).substring(0,(this.state.failed_param).length))) ||

                        (data.trans_id && (this.state.failed_param===(data.trans_id).substring(0,(this.state.failed_param).length))) ||

                        (data.reason && (this.state.failed_param===(data.reason).substring(0,(this.state.failed_param).length))) ||

                        (data.payee && (this.state.failed_param===(data.payee).substring(0,(this.state.failed_param).length))) ||

                        ((data.payee_details && data.payee_details[0]) && (this.state.failed_param===(data.payee_details[0].first_name).substring(0,(this.state.failed_param).length) || this.state.failed_param===(data.payee_details[0].last_name).substring(0,(this.state.failed_param).length))) ||
                    
                        ((data.payer_details && data.payer_details[0]) &&(this.state.failed_param===(data.payer_details[0].first_name).substring(0,(this.state.failed_param).length) || this.state.failed_param===(data.payer_details[0].last_name).substring(0,(this.state.failed_param).length) || this.state.failed_param===(data.payer_details[0].first_name).substring(0,(this.state.failed_param).length)))
                    ){
                        return <tr>
                            <td className="whiteBG">{data.received}</td>
                            <td>{data.trans_id}</td>
                            <td>{(data.resolved).toString()}</td>
                            <td>{data.reason}</td>
                            <td> <label className="float-right">{this.numberWithCommas(data.amount)}</label></td>
                            <td>{data.src_wallet}</td>
                            <td>{data.payer}</td>
                            <td>{(data.payer_details&& data.payer_details[0])?data.payer_details[0].first_name+" "+data.payer_details[0].last_name:"N/A"}</td>
                            <td>{data.payee}</td>
                            <td>{(data.payee_details && data.payee_details[0])?data.payee_details[0].first_name+" "+data.payee_details[0].last_name:"N/A"}</td>
                            <td><button className="primary">Investigate</button></td>
                        </tr>                    
                    }
                }
            }).filter(Boolean);
        }
        
        return <div>
            <div className="pr-5 pl-5 pb-5 pt-3 tab-content mr-3 ml-3">
                
                <h4>Summary</h4>
                <div className="limit-width">
                <table className="specialTable col-12">
                    <thead>
                        <tr className="specialTable">
                            <th>Type</th>
                            <th>Total Volume</th>
                            <th>Total Value ({this.state.country.currency})</th>
                            <th>Total Success</th>
                            <th>Total Success Value ({this.state.country.currency})</th>
                            <th>Total Failed</th>
                            <th>Total Failed Value ({this.state.country.currency})</th>
                        </tr>
                    </thead>
                    <tbody >
                        {Data}
                    </tbody>
                </table>
                {Object.keys(this.state.data).length==0?<div className="solidBorder">
                    <center><img src={reportpng} className="m-3 smallImg"/></center>
                    <center><h3 className="eee">{!this.state.loading_failed?<b>No Report Available</b>:<img src={loader} className="smallLoader"/>}</h3></center>
                </div>:null}
                </div>
                
                {this.state.showTransactions?<span>
                <br/>
                <br/>
                <h4 className="text-capitalize">{this.state.trans_type} Transactions</h4>
                <div className="form-group">   
                    <input type="text" placeholder="Search" className="form-control" value={this.state.transactions_param} name="transactions_param" onChange={this.onChange}/>
                </div>
                <div className="limit-width">
                <table className="specialTable col-12">
                    <thead>
                        <tr className="specialTable">
                            <th>Received</th>
                            <th>Trans ID</th>
                            <th>Status</th>
                            <th>Reason</th>
                            <th>Amount ({this.state.country.currency})</th>
                            <th>Wallet</th>
                            <th>Payer</th>
                            <th>Payer Name</th>
                            <th>Payee</th>
                            <th>Payee Name</th>
                        </tr>
                    </thead>
                    <tbody >
                        {Transactions}
                    </tbody>
                </table>
                {Object.keys(this.state.transactions).length==0?<div className="solidBorder">
                    <center><img src={reportpng} className="m-3 smallImg"/></center>
                    <center><h3 className="eee"><b>No {this.state.trans_type} Transactions</b></h3></center>
                </div>:null}
                </div>
                </span>:null}

                <br/>
                <br/>
                <h4>Failed Transactions</h4>
                <div className="form-group">  
                    <input type="text" placeholder="Search" className="form-control" value={this.state.failed_param} name="failed_param" onChange={this.onChange}/>
                </div>
                <div className="limit-width">
                <table className="specialTable col-12">
                    <thead>
                        <tr className="specialTable">
                            <th>Received</th>
                            <th>Trans ID</th>
                            <th>Status</th>
                            <th>Reason</th>
                            <th>Amount</th>
                            <th>Wallet</th>
                            <th>Payer</th>
                            <th>Payer Name</th>
                            <th>Payee</th>
                            <th>Payee Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody >
                        {Failed}
                    </tbody>
                </table>
                {Object.keys(this.state.failed).length==0?<div className="solidBorder">
                    <center><img src={reportpng} className="m-3 smallImg"/></center>
                    <center><h3 className="eee">{!this.state.loading_failed?<b>No Failed Transactions</b>:<img src={loader} className="smallLoader"/>}</h3></center>
                </div>:null}
                </div>
            </div>
            <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            shouldCloseOnOverlayClick={true}
            style={this.state.customStyles}
            contentLabel="Example Modal">
                
            </Modal>
        </div>
    }
}


export default ReportsComponent;