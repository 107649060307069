import React, { Component } from 'react';
import moment from 'moment';
import { ApiService } from '../../services';
import Modal from 'react-modal';
import reportpng from '../../assets/img/icons/report.png';
import loader from "../loading.gif";

class WebhookComponent extends Component{
    constructor(){
        super();

        this.state = {
            data: [],
            customStyles : {
                content : {
                  top                   : '50%',
                  left                  : '50%',
                  right                 : 'auto',
                  bottom                : 'auto',
                  marginRight           : '-50%',
                  transform             : 'translate(-50%, -50%)'
                }
            }
        }

        this.showDetails = this.showDetails.bind(this);
        this.showReprocess = this.showReprocess.bind(this);
    }

    componentDidMount(){
        console.log(this.props.data)
        this.setState({data: this.props.data,loading_webhooks: this.props.loading_webhooks, webhook_error: this.state.webhook_error});

    }


    componentDidUpdate(prevProps,prevState){
        if(prevProps!==this.props){
            console.log(this.props.data)
            console.log(this.props.failed)
            console.log(this.props.webhook_error)
            
            this.setState({data: this.props.data,loading_webhooks: this.props.loading_webhooks, webhook_error: this.state.webhook_error})

            if(this.props.webhook_error)
                this.setState({showWebhookError: true,modalIsOpen: true});
            
            
        }
    }

    showDetails = (transaction_id)=>{
        this.setState({confirm_modal: false,view_modal: true,modalIsOpen: true,loading: true,reprocess_message: false, reprocess_error: false});

        ApiService.fetchUKTransaction(transaction_id)
        .then((res)=>{
            //alert("data fetched");
            console.log(res.data.data);
            this.setState({loading: false,transaction_data:res.data.data})
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            //alert(zerror)
            this.setState({fetch_error: zerror,loading: false});
        })
    }

    showReprocess = (data)=>{
        this.setState({confirm_modal: true,view_modal: false,modalIsOpen: true,reprocess: data,reprocess_message: false, reprocess_error: false});
    }

    reprocess = (data)=>{
        this.setState({loading: true,reprocess_message: false, reprocess_error: false});

        ApiService.sendWebhook(data)
        .then((res)=>{
            this.setState({reprocess_message: "Successfully reprocessed",loading: false})
        },(error)=>{
            let zerror = error.response? error.response.data.errors:error.toString();
            this.setState({reprocess_error: zerror,loading: false});            
        })
    }



    onChange = (e)=>{
        this.setState({[e.target.name]:e.target.value});
    }

    render(){

        let Data = [];

        console.log(this.state.data)
        if(Object.keys(this.state.data).length>0){
            Data = this.state.data.map((data,index)=>{
                if(data.type==="ledger-changed" || data.type==="ledger_changed"){
                    if(!this.state.transactions_param){
                        return <tr>
                            <td className="whiteBG"><small>{moment(data.created_at).format('DD-MM-YYYY')}</small></td>
                            <td className="whiteBG"><small>{moment(data.created_at).format('LT')}</small></td>
                            <td>{data.type}</td>
                            <td className="whiteBG"><small>{data.ledger_id}</small></td>
                            <td><small>{data.notification_id}</small></td>
                            <td><small>{data.transaction_id}</small></td>
                            <td><button onClick={()=>{this.setState({reprocess: data});this.showDetails(data.transaction_id)}} className="btn btn-sm btn-primary">View Details</button>
                            <button onClick={()=>{this.showReprocess(data)}} className="btn btn-sm btn-primary-outline">Reprocess</button></td>
                        </tr>
                    }else{
                        if(this.state.transactions_param===(data.ledger_id).substring(0,(this.state.transactions_param).length||
                        this.state.transactions_param===(data.transaction_id).substring(0,(this.state.transactions_param).length))){
                            
                            return <tr>
                                <td className="whiteBG"><small>{moment(data.created_at).format('DD-MM-YYYY')}</small></td>
                                <td className="whiteBG"><small>{moment(data.created_at).format('LT')}</small></td>
                                <td>{data.type}</td>
                                <td className="whiteBG"><small>{data.ledger_id}</small></td>
                                <td><small>{data.notification_id}</small></td>
                                <td><small>{data.transaction_id}</small></td>
                                <td><button onClick={()=>{this.setState({reprocess: data});this.showDetails(data.transaction_id)}} className="btn btn-sm btn-primary">View Details</button>
                                <button onClick={()=>{this.showReprocess(data)}} className="btn btn-sm btn-primary-outline">Reprocess</button></td>
                            </tr>
                        }
                    }
                }
              }).filter(Boolean)
        }
        return <div>
            <div className="pr-5 pl-5 pb-5 pt-3 tab-content mr-3 ml-3">
                <div className="form-group">   
                    <input type="text" placeholder="Search" className="form-control" value={this.state.transactions_param} name="transactions_param" onChange={this.onChange}/>
                </div>
                <div className="limit-width">
                <table className="specialTable col-12">
                    <thead>
                        <tr className="specialTable">
                            <th>Date</th>
                            <th>Time</th>
                            <th>Type</th>
                            <th>Notification ID</th>
                            <th>Ledger ID</th>
                            <th>Transaction ID</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody >
                        {Data}
                    </tbody>
                </table>
                {Object.keys(this.state.data).length==0 || Data.length===0?<div className="solidBorder">
                    <center><img src={reportpng} className="m-3 smallImg"/></center>
                    <center><h3 className="eee">{!this.state.loading_webhooks?<b>No Webhook Fetched <label className="text-danger">{this.state.webhook_error}</label></b>:<img src={loader} className="smallLoader"/>}</h3></center>
                </div>:null}
                </div>
            </div>
            <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            shouldCloseOnOverlayClick={true}
            style={this.state.customStyles}
            contentLabel="Example Modal">
                {this.state.showWebhookError?
                    <div>
                        <h3>{this.props.webhook_error}</h3>
                        <button className="btn btn-sm" onClick={()=>{this.setState({modalIsOpen: false,transaction_data: false, showWebhookError: false})}}>close</button>
                    </div>
                :null}
                {this.state.view_modal?
                    <div>
                        {this.state.loading?<img src={loader} className="smallLoader"/>:null}
                        {this.state.transaction_data? 
                            <div>
                                <h3>Transaction Details</h3>
                                <p>
                                    <b>Amount:</b> GBP {this.state.transaction_data.amount}
                                </p>
                                <p>
                                    <b>Payment Type:</b> {this.state.transaction_data.payment_type}
                                </p>
                                <p>
                                    <b>Transaction Type:</b><br/> <textarea rows="25"  cols="50" value={JSON.stringify(this.state.transaction_data)}>                                
                                    </textarea>
                                </p>
                                <button className="btn btn-sm btn-primary" onClick={()=>{this.showReprocess(this.state.reprocess)}}>reprocess</button>
                                <button className="btn btn-sm" onClick={()=>{this.setState({modalIsOpen: false,transaction_data: false, showWebhookError: false})}}>close</button>
                            </div>
                        :null}
                        {this.state.fetch_error? <div>
                            <h3>{this.state.fetch_error}</h3>
                            <button className="btn btn-sm" onClick={()=>{this.setState({modalIsOpen: false,transaction_data: false, showWebhookError: false})}}>close</button>
                        </div>:null}
                    </div>
                :null}

                {this.state.confirm_modal?<span>
                            <h3>Are you sure you want to reprocess <br/>this failed webhook?</h3>
                            {!this.state.loading?<span>
                            <button className="btn btn-sm btn-primary" onClick={()=>{this.reprocess(this.state.reprocess)}}>Yes</button>
                                <button className="btn btn-sm" onClick={()=>{this.setState({modalIsOpen: false,reprocess: false, showWebhookError: false})}}>No</button>
                            </span>:<img src={loader} className="smallLoader"/>}

                            <label className="text-danger">{this.state.reprocess_error}</label>
                            <label className="text-primary">{this.state.reprocess_message}</label>
                        </span>:null}
            </Modal>
        </div>
    }
}


export default WebhookComponent;