import React, { Component } from 'react';
// ES module
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ApiService } from '../../services';
import loader from "../loading.gif";
import { ninvoke } from 'q';
import Modal from 'react-modal';
import ShowMoreText from 'react-show-more-text';
import reportpng from '../../assets/img/icons/report.png';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import ProductComponent from './ProductComponent';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

class ReportsComponent extends Component{
    constructor(){
        super();

        this.state = {
            show: 0,
            level: 1,
            income: {
                volume: 0,
                value: 0
            },
            aid: {
                _id: "",
                volume: 0,
                value: 0,
                data: []
            },
            process_transactions: {},
            session: JSON.parse(localStorage.getItem("SESSION")),
            channels_data: {},
            channels_dates: {},
            channels: {},
            processes: {},
            showChannels: false,
            customStyles : {
                content : {
                  top                   : '50%',
                  left                  : '50%',
                  right                 : 'auto',
                  bottom                : 'auto',
                  marginRight           : '-50%',
                  transform             : 'translate(-50%, -50%)'
                }
            }
        }

        this.fetchProcessData = this.fetchProcessData.bind(this);
    }

    componentDidMount(){
        //this.setState({income: this.props.income,channels: this.props.channels})
        if(this.props.income){
            this.setState({income: this.props.income})
        }

        if(this.props.aid){
            console.log(this.props.aid)
            this.setState({aid: this.props.aid});
        }    
        
        if(this.props.channels){
            console.log(this.props.channels)
            this.setState({channels: this.props.channels})
        }

        if(this.props.processes){
            this.setState({processes: this.props.processes})
        }
        //this.fetchOverview();
        //this.fetchChannels();
    }

    componentDidUpdate(prevProps){
        if(prevProps.income!==this.props.income){
            this.setState({income: this.props.income});
        }

        if(prevProps.aid!==this.props.aid){
            console.log(this.props.aid)
            this.setState({aid: this.props.aid});
        }        

        if(prevProps.channels!==this.props.channels){
            this.setState({channels: this.props.channels});
        }

        if(prevProps.processes !== this.props.processes){
            console.log(this.props.processes);
            this.setState({processes: this.props.processes});
        }
    }

    fetchOverview = ()=>{
        ApiService.fetchOverview(this.props.merchant_id)
        .then((response)=>{
            console.log(response);
            if(response.data.data){
             this.setState({income: response.data.data[0]});
            }
        },(error)=>{
            console.log(error);
        });

    }
    
    numberWithCommas(x) {
        x = (Math.round(x * 100) / 100).toFixed(2)
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    fetchChannels = ()=>{
        ApiService.fetchChannels(this.props.merchant_id)
        .then((response)=>{
            console.log(response.data.data);
            if(response.data.data){
                this.setState({channels: response.data.data});
            }
        },(error)=>{
            console.log(error);
        });
    
    }

    fetchChannelsByProducts = (channel_id)=>{
        ApiService.fetch_channel_products(this.state.session.merchant_id,channel_id)
        .then((response)=>{ 
          if(response.data.data){
            console.log("BOOGIE NIGHTS")
            console.log(response.data.data);
            this.setState({channels_dates: response.data.data});
          }
        },(error)=>{
            console.log(error);
        });
    }


    resendEmail = ()=>{

        this.setState({resending: true, resent_err: false});

        let sig  = this.state.sig;
        sig.merchant_name = this.state.session.reg_name;
        
        ApiService.resendProcessEmail(sig)
        .then((response)=>{

            let processes = this.state.processes;
            processes[this.state.parent_index].authorizations[this.state.child_index].sent = true;
            this.setState({processes: processes, resending: false, resent: true, resent_msg: "Mail Sent"})
        },(error)=>{
            this.setState({resending: false, resent: false, resent_err:"Error Sending!"})
        })
    }


    fetchProcessData = (process_id) => {
        if(process_id!==this.state.process_id){
            this.setState({process_id: process_id, process_transactions: false});
            
            let country_code = this.state.session.country_code;

            ApiService.fetchProcessTransactions(process_id,country_code)
            .then((response)=>{
                this.setState({process_transactions: response.data.data})
            },(error)=>{
               console.log(error);
            });
        }
    }

    fetchChannelsData = (date)=>{
        //alert("date: "+date)
        ApiService.fetchProducts(this.state.session.merchant_id,this.state.channel_id,date)
        .then((response)=>{
            console.log("Hell Proof!");
            console.log(response.data.data);
            if(response.data.data){
                this.setState({channels_data: response.data.data});
            }
        },(error)=>{
            console.log(error);
        });
    
    }

    cleanProcess = (truth,data) =>{
        
        let status;
        if(truth){status="Approved"}else{status="Awaiting Approval"}

        let exp = [];
        console.log(data.length);
        for(let i = 0; i<data.length; i++){
            
            let signatories = "";
            let not_signed = "";
            data[i].authorizations.map((sig,i)=>{
                if(sig.signed === true){
                    signatories = signatories+" "+sig.email
                }else{
                    not_signed = signatories+" "+sig.email
                }
            });
            
            exp.push({
                Date: moment(data[i].date).format('DD-MM-YYYY'),
                Time: moment(data[i].date).format('h:mm:ss a'),
                Status: status,
                Product: data[i].product_name,
                Currency: this.state.session.currency.currency_code, 
                Amount: parseFloat(data[i].value),
                Bank: data[i].bank.account_name+" "+data[i].bank.account_no+" "+data[i].bank.bank_name,
                Authorizer: data[i].authorizer,
                Signed: signatories,
                Unsigned: not_signed
            })
            
        };

        console.log(exp)

        //this.setState({export: exp})
        if(exp.length>0){
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const fileName = status+" Transactions "+exp[exp.length-1].Date+" to "+exp[0].Date;
            const exportToCSV = (csvData, fileName) => {
                const ws = XLSX.utils.json_to_sheet(csvData);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, fileName + fileExtension);
            }

            exportToCSV(exp,fileName)
        }
    }

    cleanProcessTransactions = () =>{
        const data = this.state.process_transactions;
        console.log(data)
        let status;
        if(data[0].sent_status){status="Moved"}else{status="Not Moved"}

        let exp = [];
        for(let i = 0; i<data.length; i++){
            
            exp.push({
                Date: moment(data[i].date).format('DD-MM-YYYY'),
                Time: moment(data[i].date).format('h:mm:ss a'),
                Reference_No: data[i].ref_no || data[i].src_account,
                Fullname: data[i].src_first_name+" "+data[i].src_last_name,
                Channel: data[i].channel_id, 
                Currency: this.state.session.currency.currency_code,
                Amount: data[i].des_amount_paid,
                Description: data[i].description,
                Moved: status
            })
            
        };

        /**return <tr>
                    <td className="whiteBG">{index+1}</td>
                    <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                    <td>{moment(data.date).format('LT')}</td>
                    <td>{data.ref_no || data.src_account}</td>
                    <td>{data.channel_id}</td>
                    <td>{data.src_first_name} {data.src_last_name}</td>
                    <td>{this.state.session.currency.currency_code}<span className="float-right">{this.numberWithCommas(data.des_amount_paid)}</span></td>
                    <td>{data.description}</td>
                    <td><center><input type="checkbox" checked={data.sent_status} disabled/></center></td>
                    </tr>*/

        //console.log(exp)

        //this.setState({export: exp})
        if(exp.length>0){
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const fileName = this.state.process_name+" Transactions "+exp[exp.length-1].Date+" to "+exp[0].Date;
            const exportToCSV = (csvData, fileName) => {
                const ws = XLSX.utils.json_to_sheet(csvData);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, fileName + fileExtension);
            }

            exportToCSV(exp,fileName)
        }
    }


    render(){

        let Channels;
        if(Object.keys(this.state.channels).length>0){
          Channels = this.state.channels.map((data,index)=>{
            if(data._id!==null){
                return <tr>
                <td className="whiteBG"><a onClick={()=>{this.setState({channel_id: data._id, show:1});this.fetchChannelsByProducts(data._id);}}>{data._id}</a></td>
                <td><a onClick={()=>{this.setState({channel_id: data._id, show:1});this.fetchChannelsByProducts(data._id);}}>{data.volume}</a></td>
                <td><a onClick={()=>{this.setState({channel_id: data._id, show:1});this.fetchChannelsByProducts(data._id);}}>{this.state.session.currency.currency_code}<label className="float-right">{this.numberWithCommas(data.value)}</label></a></td>
                </tr>
            }
          }).filter(Boolean);
        }

        //moment(data._id).format('DD-MM-YYYY')
        let ChannelsDates
        if(Object.keys(this.state.channels_dates).length>0){
            let i = 0;
            ChannelsDates = this.state.channels_dates.map((data,index)=>{
                console.log(data);
                if(data._id!==null){
                    i++
                    return <tr>
                    <td className="whiteBG">{i}</td>
                    <td><a onClick={()=>{this.setState({date: data._id,product_name:data.product[0].name, show:2});this.fetchChannelsData(data._id)}}>{this.state.channel_id}</a></td>
                    <td className="whiteBG"><a onClick={()=>{this.setState({date: data._id,product_name:data.product[0].name, show:2});this.fetchChannelsData(data._id)}}>
                    {data.product[0].name}</a></td>
                    <td><a onClick={()=>{this.setState({date: data._id,product_name:data.product[0].name, show:2});this.fetchChannelsData(data._id)}}>{data.volume}</a></td>
                    <td><a onClick={()=>{this.setState({date: data._id,product_name:data.product[0].name, show:2});this.fetchChannelsData(data._id)}}>{this.state.session.currency.currency_code}<label className="float-right">{this.numberWithCommas(data.value)}</label></a></td>
                    </tr>                    
                }
            }).filter(Boolean);
        }

        let ChannelsData;
        if(Object.keys(this.state.channels_data).length>0){
          ChannelsData = this.state.channels_data.map((data,index)=>{
              console.log(data);
            return <tr>
              <td className="whiteBG"><a onClick={()=>{this.setState({product_id: data._id, show:3});}}>{index+1}</a></td>
              <td><a onClick={()=>{this.setState({product_id: data._id, show:3});}}>{moment(data._id).format('DD-MM-YYYY')}</a></td>
              <td><a onClick={()=>{this.setState({product_id: data._id, show:3});}}>{this.state.channel_id}</a></td>
              <td><a onClick={()=>{this.setState({product_id: data._id, show:3});}}>{Object.keys(data.product[0]).length>0?data.product[0][0].name:null}</a></td>
              <td><a onClick={()=>{this.setState({product_id: data._id, show:3});}}>{data.volume}</a></td>
              <td><a onClick={()=>{this.setState({product_id: data._id, show:3});}}>{this.state.session.currency.currency_code}<span className="float-right">{this.numberWithCommas(data.value)}</span></a></td>
            </tr>
          });
        }

        let Pending = [], Completed = [], pend = [], complete=[], FailedMoves=[];
        //console.log(this.state.processes);
        if(Object.keys(this.state.processes).length>0){
            Pending = this.state.processes.map((data,index)=>{
                let sig_count = 0;
                let signatories = data.authorizations.map((sig,i)=>{
                    if(sig.signed === true){
                        sig_count++;
                        return <span><a href="">{sig.email}</a><br/></span>
                    }
                });

                
                let pending = data.authorizations.map((sig,i)=>{
                    if(sig.signed === false){
                        return <span>{sig.email} <a onClick={()=>{this.setState({sig: sig, modalIsOpen: true,parent_index:index,child_index:i, sig_email: sig.email, sig_name: data.product_name})}} className="text-blue pointer">{sig.sent==true?"Resend":"Resend"} Email</a><br/></span>
                    }
                });
                console.log("SIGISMUND!");
                if(sig_count<data.threshold){
                    pend.push(data);
                    let status = "AWAITING APPROVAL";
                    return<tr>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:4,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: false})}} className="whiteBG">{moment(data.date).format('DD-MM-YYYY')}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:4,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: false})}}>{moment(data.date).format('h:mm:ss a')}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:4,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: false})}}>{status}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:4,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: false})}}>{data.product_name}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:4,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: false})}}>{data.volume}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:4,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: false})}} className="col-4">{this.state.session.currency.currency_code}{" "}{"\t"}{" "}{this.numberWithCommas(data.gross || data.value)}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:4,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: false})}} className="col-4">{this.state.session.currency.currency_code}{" "}{"\t"}{" "}{this.numberWithCommas(data.value)}</td>
                    <td>({data.bank.bank_name})</td>
                    <td> {data.bank.account_no}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:4,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{data.bank.account_name}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:4,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: false})}}>{data.authorizer}</td>  
                    <td>
                    <ShowMoreText
                        lines={1}
                        more='Show more'
                        less='Show less'
                        anchorClass=''
                        expanded={false}
                    >
                        {signatories}
                    </ShowMoreText>
                    </td> 
                    <td><ShowMoreText
                        lines={1}
                        more='Show more'
                        less='Show less'
                        anchorClass=''
                        expanded={false}
                    >
                        {pending}
                    </ShowMoreText></td> 
                  </tr>
            
                }

            }).filter(Boolean);
            
            Completed = this.state.processes.map((data,index)=>{
                let sig_count = 0;
                
                let signatories = data.authorizations.map((sig,i)=>{
                    if(sig.signed === true){
                        sig_count++;
                        return <span><a href="">{sig.email}</a><br/></span>
                    }
                    //let move_date;
                                      
                });
                let date_moved = new Date("01-01-1970").getTime()
                let move_date;
                for(let i=0;i<data.authorizations.length;i++){
                    let sig = data.authorizations[i];
                    if(sig.signed===true){
                        let new_date = new Date(sig.updated_at).getTime();
                        console.log("date_init: "+new_date);
                        console.log("date_move: "+date_moved);
                        if(new_date > date_moved){
                            console.log("boom:bam")
                            move_date = sig.updated_at;
                            date_moved = new_date;
                        } 
                    }
                }
                
                let pending = data.authorizations.map((sig,i)=>{
                    if(sig.signed === false){
                        return <span>{sig.email}<br/></span>
                    }
                });
                if(sig_count>=data.threshold && data.transferred){
                    console.log("Date Moved: "+date_moved);
                    complete.push(data);
                    let status = "APPROVED";
                    return<tr>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}} className="whiteBG">{moment(data.transfer_date || move_date).format('DD-MM-YYYY')}</td>   
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{moment(data.transfer_date || move_date).format('h:mm:ss a')}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}} className="whiteBG">{moment(data.date).format('DD-MM-YYYY')}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{moment(data.date).format('h:mm:ss a')}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{status}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{data.product_name}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{data.volume}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: false})}} className="col-4">{this.state.session.currency.currency_code}{" "}{"\t"}{" "}{this.numberWithCommas(data.gross || data.value)}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}} className="col-4">{this.state.session.currency.currency_code}{" "}{"\t"}{" "}{this.numberWithCommas(data.value)}</td>
                    <td>({data.bank.bank_name})</td>
                    <td> {data.bank.account_no}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{data.bank.account_name}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{data.authorizer}</td>  
                    <td>
                    <ShowMoreText
                        lines={1}
                        more='Show more'
                        less='Show less'
                        anchorClass=''
                        expanded={false}
                    >
                        {signatories}
                    </ShowMoreText>
                    </td> 
                    <td><ShowMoreText
                        lines={1}
                        more='Show more'
                        less='Show less'
                        anchorClass=''
                        expanded={false}
                    >
                        {pending}
                    </ShowMoreText></td> 
                  </tr>
            
                }

            }).filter(Boolean)
        
            FailedMoves = this.state.processes.map((data,index)=>{
                let sig_count = 0;
                
                let signatories = data.authorizations.map((sig,i)=>{
                    if(sig.signed === true){
                        sig_count++;
                        return <span><a href="">{sig.email}</a><br/></span>
                    }
                    //let move_date;
                                      
                });
                let date_moved = new Date("01-01-1970").getTime()
                let move_date;
                for(let i=0;i<data.authorizations.length;i++){
                    let sig = data.authorizations[i];
                    if(sig.signed===true){
                        let new_date = new Date(sig.updated_at).getTime();
                        console.log("date_init: "+new_date);
                        console.log("date_move: "+date_moved);
                        if(new_date > date_moved){
                            console.log("boom:bam")
                            move_date = sig.updated_at;
                            date_moved = new_date;
                        } 
                    }
                }
                
                let pending = data.authorizations.map((sig,i)=>{
                    if(sig.signed === false){
                        return <span>{sig.email}<br/></span>
                    }
                });
                if(sig_count>=data.threshold && !data.transferred){
                    console.log("Date Moved: "+date_moved);
                    complete.push(data);
                    let status = "APPROVED-NOT-MOVED";
                    return<tr>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}} className="whiteBG">{moment(data.date).format('DD-MM-YYYY')}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{moment(data.date).format('h:mm:ss a')}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{status}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{data.product_name}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{data.volume}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: false})}} className="col-4">{this.state.session.currency.currency_code}{" "}{"\t"}{" "}{this.numberWithCommas(data.gross || data.value)}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}} className="col-4">{this.state.session.currency.currency_code}{" "}{"\t"}{" "}{this.numberWithCommas(data.value)}</td>
                    <td>({data.bank.bank_name})</td>
                    <td> {data.bank.account_no}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{data.bank.account_name}</td>
                    <td onClick={()=>{this.fetchProcessData(data.process_id);this.setState({show:5,process_name:data.product_name, process_date: moment(data.date).format('DD-MM-YYYY'), process_moved: true})}}>{data.authorizer}</td>  
                    <td>
                    <ShowMoreText
                        lines={1}
                        more='Show more'
                        less='Show less'
                        anchorClass=''
                        expanded={false}
                    >
                        {signatories}
                    </ShowMoreText>
                    </td> 
                    <td><ShowMoreText
                        lines={1}
                        more='Show more'
                        less='Show less'
                        anchorClass=''
                        expanded={false}
                    >
                        {pending}
                    </ShowMoreText></td> 
                  </tr>
            
                }

            }).filter(Boolean)
        
        }
        console.log(Completed);

        let giftAids;

        if(Object.keys(this.state.aid.data).length>0){          
            giftAids = this.state.aid.data.map((data,index)=>{
                return <tr>
                <td className="whiteBG">{index+1}</td>
                <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                <td>{moment(data.date).format('LT')}</td>
                <td>{data.ref_no}</td>
                <td>{data.channel_id}</td>
                <td>{data.src_first_name} {data.src_last_name}</td>
                <td>{this.state.session.currency.currency_code}<span className="float-right">{this.numberWithCommas(data.des_amount_paid)}</span></td>
                <td>{data.description}</td>
                <td><center><input type="checkbox" checked={data.sent_status} disabled/></center></td>
                </tr>
            })
        }

        if(this.state.show===4 || this.state.show===5){
            if(Object.keys(this.state.process_transactions).length>0){
                giftAids = this.state.process_transactions.map((data,index)=>{
                    return <tr>
                    <td className="whiteBG">{index+1}</td>
                    <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                    <td>{moment(data.date).format('LT')}</td>
                    <td>{data.ref_no || data.src_account}</td>
                    <td>{data.channel_id}</td>
                    <td>{data.src_first_name} {data.src_last_name}</td>
                    <td>{this.state.session.currency.currency_code}<span className="float-right">{this.numberWithCommas(data.des_amount_paid)}</span></td>
                    <td>{data.description}</td>
                    <td><center><input type="checkbox" checked={data.sent_status} disabled/></center></td>
                    </tr>
                })
            }
        }

        let body;
        return <div>
            {this.state.show===0?<div>
            <span className="ibox-title m-2">
            </span>
            <br/>    
            <label className="ml-5"><b>Outstanding Balance</b></label>
            <div className="pr-5 pl-5 pb-5 pt-3 tab-content mr-3 ml-3">
                <div className="limit-width">
                <table className="specialTable col-12">
                    <thead>
                        <tr className="specialTable">
                            {this.state.showChannels?<th>All</th>:null}
                            <th>Total Volume</th>
                            <th>Total Value</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr onClick={()=>{this.setState({showChannels: !this.state.showChannels})}}>
                            {this.state.showChannels?<td className="whiteBG">All Channels</td>:null}
                            <td className="whiteBG">{this.state.income.volume}</td>
                            <td>{this.state.session.currency.currency_code} <label className="float-right">{this.numberWithCommas(this.state.income.value)}</label></td>
                        </tr>
                        {this.state.showChannels? Channels :null}
                    </tbody>
                </table>
                </div>
            </div>
            <Tabs defaultIndex={0} className="p-3">
                <TabList>
                <Tab>Completed Authorizations</Tab>
                <Tab>Pending Authorizations</Tab>
                <Tab>Failed Move To Bank</Tab>
                </TabList>


                <TabPanel>
                    <div className="ibox-body tab-content mr-3 ml-3">
                    <button className="btn btn-dark mb-2" onClick={()=>{this.cleanProcess(true,complete);this.setState({exp:complete})}}>Export as Excel</button>
                        <div className="limit-width">
                            <table className="specialTable col-12">
                                <thead>
                                    <tr className="specialTable">
                                        <th>Date Moved</th>
                                        <th>Time Moved</th>
                                        <th>Date Created</th>
                                        <th>Time Created</th>                                        
                                        <th>Status</th>
                                        <th>Product</th>
                                        <th>Volume</th>
                                        <th className="col-4">Total Value</th>
                                        <th className="col-4">Net Value</th>
                                        <th>Bank Name</th>
                                        <th>Account No</th>
                                        <th>Account Name</th>
                                        <th>Authorizer</th>
                                        <th>Signed By</th>
                                        <th>Pending Signatures</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Completed}
                                </tbody>
                            </table>
                            {Object.keys(Completed).length==0?<div className="solidBorder">
                                <center><img src={reportpng} className="m-3 smallImg"/></center>
                                <center><h3 className="eee"><b>No Completed Transactions</b></h3></center>
                            </div>:null}
                        </div>
                    </div>

                </TabPanel>
                <TabPanel>
                <div className="ibox-body tab-content mr-3 ml-3">
                    <button className="btn btn-dark mb-2" onClick={()=>{this.cleanProcess(false,pend);this.setState({exp:pend})}}>Export as Excel</button>
                    <div className="limit-width">
                        <table className="specialTable col-12">
                            <thead>
                                <tr className="specialTable">
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Status</th>
                                    <th>Product</th>
                                    <th>Volume</th>
                                    <th className="col-4">Total Value</th>
                                    <th className="col-4">Net Value</th>
                                    <th>Bank Name</th>
                                    <th>Account No</th>
                                    <th>Account Name</th>
                                    <th>Authorizer</th>
                                    <th>Signed By</th>
                                    <th>Pending Signatures</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Pending}
                            </tbody>
                        </table>
                        {Object.keys(Pending).length==0?<div className="solidBorder">
                            <center><img src={reportpng} className="m-3 smallImg"/></center>
                            <center><h3 className="eee"><b>No Pending Transactions</b></h3></center>
                        </div>:null}
                    </div>
                </div>                    
                </TabPanel>
                <TabPanel>
                <div className="ibox-body tab-content mr-3 ml-3">
                    <button className="btn btn-dark mb-2" onClick={()=>{this.cleanProcess(false,pend);this.setState({exp:pend})}}>Export as Excel</button>
                    <div className="limit-width">
                        <table className="specialTable col-12">
                            <thead>
                                <tr className="specialTable">
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Status</th>
                                    <th>Product</th>
                                    <th>Volume</th>
                                    <th className="col-4">Total Value</th>
                                    <th className="col-4">Net Value</th>
                                    <th>Bank Name</th>
                                    <th>Account No</th>
                                    <th>Account Name</th>
                                    <th>Authorizer</th>
                                    <th>Signed By</th>
                                    <th>Pending Signatures</th>
                                </tr>
                            </thead>
                            <tbody>
                                {FailedMoves}
                            </tbody>
                        </table>
                        {Object.keys(FailedMoves).length==0?<div className="solidBorder">
                            <center><img src={reportpng} className="m-3 smallImg"/></center>
                            <center><h3 className="eee"><b>No Failed Move To Bank</b></h3></center>
                        </div>:null}
                    </div>
                </div>                    
                </TabPanel>

            </Tabs>


            {this.state.session.country_code==="044" && (this.state.session.ent_type==="CHARITY" || this.state.session.ent_type==="RELIGIOUS")?<div>
            <label className="ml-5"><b>Gift Aid Reports</b></label>
            <div className="pr-5 pl-5 pb-5 pt-3 tab-content mr-3 ml-3">
                <div className="limit-width">
                <table className="specialTable col-12">
                    <thead>
                        <tr className="specialTable">
                            <th>Total Volume</th>
                            <th>Total Value</th>
                            <th>Expected Gift Aid</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr>
                            <td onClick={()=>{this.setState({show: 3})}} className="whiteBG">{this.numberWithCommas(this.state.aid.volume)}</td>
                            <td onClick={()=>{this.setState({show: 3})}}>{this.state.session.currency.currency_code} <label className="float-right">{this.numberWithCommas(this.state.aid.value)}</label></td>
                            <td onClick={()=>{this.setState({show: 3})}}>{this.state.session.currency.currency_code} <label className="float-right">{this.numberWithCommas(this.state.aid.value * 0.25)}</label></td>
                            <td> <center><button disabled={this.state.aid.volume===0} className="btn-dark btn-sm btn text-capitalize"><b>Forward to HMRC</b></button></center></td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <br/>

            </div>
            </div>:null}    
        </div>:this.state.show===1?<div>
            <br/>
            <p className="pl-4 pr-4 pt-4 pb-1">
            <div className="breadcrumb"><small><a onClick={()=>{this.setState({show:0})}} className="text-blue">Channels</a> / {this.state.channel_id}</small></div>
            </p>
            <div className="ibox-body tab-content">
                <label className="text-capitalize pb-3"><b>Earnings from {this.state.channel_id}</b></label>
                <div className="limit-width">
                    <table className="specialTable col-12">
                    <thead>
                        <tr className="specialTable">
                        <th>S/N</th>
                        <th>Channel</th>
                        <th>Product</th>
                        <th>Total Volume</th>
                        <th>Total Value</th>
                        </tr>
                    </thead>
                    <tbody >
                        {ChannelsDates}
                    </tbody>
                    </table>
                </div>

                {!ChannelsDates?<div className="solidBorder">
                    <center><img src={reportpng} className="m-3 smallImg"/></center>
                    <center><h3 className="eee"><b>No Data Found</b></h3></center>
                </div>:null}
            </div>
        </div>:this.state.show===2?<div>
            <br/>
            <p className="pl-4 pr-4 pt-4 pb-1">
            <div className="breadcrumb"><small><a onClick={()=>{this.setState({show:0})}} className="text-blue">Channels</a> / <a onClick={()=>{this.setState({show:1})}} className="text-blue">{this.state.channel_id}</a> / {this.state.product_name}</small></div>
            </p>
            <div className="ibox-body tab-content">
            <label className="text-capitalize pb-3"><b>Earnings by {this.state.product_name} from {this.state.channel_id}  </b></label>
                <div className="limit-width">
                    <table className="specialTable col-12">
                    <thead>
                        <tr className="specialTable">
                        <th>S/N</th>
                        <th>Date</th>
                        <th>Channel</th>
                        <th>Product</th>
                        <th>Total Volume</th>
                        <th>Total Value</th>
                        </tr>
                    </thead>
                    <tbody >
                        {ChannelsData}
                    </tbody>
                    </table>
                </div>

                {!ChannelsData?<div className="solidBorder">
                    <center><img src={reportpng} className="m-3 smallImg"/></center>
                    <center><h3 className="eee"><b>No Data Found</b></h3></center>
                </div>:null}
            </div>
        </div>:this.state.show===3?<div>
            <br/>
            <p className="pl-4 pr-4 pt-4 pb-1">
                <div className="breadcrumb"><small><a onClick={()=>{this.setState({show:0})}} className="text-blue">Channels</a> / <a onClick={()=>{this.setState({show:1})}} className="text-blue">{this.state.channel_id}</a> / <a onClick={()=>{this.setState({show:2})}} className="text-blue">{this.state.product_name}</a> / {moment(this.state.product_id).format('DD-MM-YYYY')}</small></div>
            </p>
            <ProductComponent product_id={this.state.date} date={this.state.product_id} product_name={this.state.product_name}/>
        </div>:<div>
            <br/>
            <p className="pl-4 pr-4 pt-4 pb-1">
                <div className="breadcrumb"><small><a onClick={()=>{this.setState({show:0})}} className="text-blue">Dashboard</a> / Transactions</small></div>
            </p>
            <div className="pr-5 pl-5 pb-5">
            {this.state.show===4 || this.state.show===5?<h4><b>{this.state.process_name}</b> - Created: {this.state.process_date} | Status: {this.state.process_moved?"Approved & Moved":"Awaiting Approval"} <button className="btn btn-dark mb-2" onClick={()=>{this.cleanProcessTransactions()}}>Export as Excel</button></h4>:null}
            <div className="limit-width">
                <table className="specialTable col-12">
                    <thead>
                        <tr className="specialTable">
                            <th>S/N</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>{this.state.session.p_refname?''+this.state.session.p_refname+'':'Reference Number'}</th>
                            <th>Channel</th>
                            <th>Full Name</th>
                            <th>Amount Received</th>
                            <th>Description</th>
                            <th>Settled</th>
                        </tr>
                    </thead>
                    <tbody>
                        {giftAids}
                    </tbody>
                </table>
            </div>
            </div>
        </div>}  

        <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              shouldCloseOnOverlayClick={false}
              style={this.state.customStyles}
              contentLabel="Example Modal">

            <h3>Resend Signature</h3>
            <h5>Resend {this.state.sig_name} Signature via email to:</h5>
            <h5 className="text-blue">{this.state.sig_email}</h5>
            {this.state.resent_err? <label className="text-danger">{this.state.resent_err}</label>:this.state.resent?<label>Email Sent</label>:null}
            {!this.state.resending?<div className="float-right">
                      <button onClick={()=>{this.setState({modalIsOpen: false, resent: false,resent_err: false})}} className="btn btn-primary-dark btn-md text-capitalize">Close</button>
                      <button onClick={this.resendEmail} disabled={this.state.resent} className="ml-2 btn btn-dark btn-md text-capitalize">Yes</button>
            </div>:this.state.resending?<div><img src={loader} className="smallLoader"/></div>:null}
        </Modal>
        </div>
    }
}

export default ReportsComponent;