//setup bank admin on admin portal
  //setup users under a bank portal
  //when bank logs in with their account; only show transactions from their scheme
import axios from 'axios';
import qs from 'qs';
import shortid from 'shortid';

let XMLHttpRequest = require("xmlhttprequest").XMLHttpRequest;

const API_HOST_1 = "https://api.xcelapp.com";
const API_HOST_2 = "https://api.etranzactglobal.com"//"http://localhost:3300";
const API_HOST_3 = /**"http://52.13.171.28:28082/cuwalletbridge";*/"http://54.188.109.103:28082/cuwalletbridge";
const API_HOST_4 = "http://52.13.171.28:28082/cuwalletbridge"//process.env.REACT_APP_LIVE_ETZ_API;
const API_HOST_5 = "http://localhost:2000"//"https://api.xcelapp.com"//***/;

class ApiService {

  static resendProcessEmail(sig){
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/email/signature/resend',sig,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });
    });    
  }

  static fetchNotifications(merchant_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/merchant/'+merchant_id+'/messages/all',{headers: {Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });
    });
  }

  static upload(img){
    console.log(img);

    return new Promise((resolve,reject) => {
      const formData = new FormData();
      formData.append('file', img);
      axios.post(API_HOST_2+'/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log(error)
        reject(error);
      });
    });
  }

  static deleteNotification(merchant_id,id){
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/notification/'+merchant_id+'/messages/delete/'+id,{headers: {Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });
    })
  }

  static editNotification(id,body){
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/notification/messages/update/'+id,body,{headers: {Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });
    })
  }

  static fetchUsers(country_code){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/reports/users/all/all/NG',{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    });
  }

  static checkKYCStatus(reference){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/kyc/status/'+reference,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    })
  }

  static validate_account(number,code){
    console.log(number+" , "+code)
    return new Promise((resolve,reject)=>{

      let url = API_HOST_2+'/psp/validate/account/NGN/'+code+'/'+number;
      
      const header = {
        headers: {
            "Content-Type":"application/json",
            "Accept":"application/json"
        }
      }

      axios.get(url,header)
      .then((response)=>{
        console.log(response.data);
        resolve(response.data);
      })
      .catch((err,response)=>{
        reject(err);
      })
       
    }) 
  }

  static fetchProcessTransactions(process_id,country_code){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/merchants/process/'+process_id+"/"+country_code+"/transactions",{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response.data);
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })  
    });
  }

  static fetch_channel_products(merchant_id,channel_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/merchant/channels/products/'+channel_id+"/"+merchant_id,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response.data)
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })      
    })
  }

  static fetch_countries(){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_1+'/countries',{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })
    })
  }

  static fetch_banks(country_code){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_1+'/banks/'+country_code,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })
    })
  } 

  static pushNotification(merchant_name,title,message,type,link,multimedia,merchant_id){
    //alert(category_id+" "+amount)
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/notifications/message/topic',{
        title: title,
        topic: merchant_id,
        name: merchant_name,
        message: message,
        notif_type: "merchant",
        type: type,
        link: link,
        multimedia: multimedia
      },{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })      
    });
  }

  static filter_countries(code){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_1+'/countries/'+code,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })
    })
  }

  static editProduct(name, bank, description,product_id, merchant_id, callback, category_id, amount){
    //alert(category_id+" "+amount)
    return new Promise((resolve,reject)=>{
      axios.put(API_HOST_2+'/products',{
        name: name,
        bank: bank,
        product_description: description,
        session_id: localStorage.getItem("SESSION_ID"),
        merchant_id: merchant_id,
        product_id: product_id,
        category_id: category_id,
        amount: amount,
        callback: callback
      },{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })      
    });
  }

  static addMerchantModulr(merchat_id,data){
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/merchants/modulr/account',{
        data: data,
        merchant_id: merchat_id,
        session_id: localStorage.getItem("SESSION_ID")
      },{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      }) 
    });
  }

  static fetchModulrAccount(account_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/merchant/account/'+account_id)
      .then((response)=>{
        resolve(response); 
      })
      .catch((err,response)=>{
        reject(err,response);
      });
    });
  }

  static createCategory(merchant_id, category){
    //alert(merchant_id)
    return new Promise((resolve, reject)=>{
      axios.post(API_HOST_2+'/merchant/product',{
        merchant_id: merchant_id,
        category: category
      },{headers: {Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        console.log(err);
        reject(err);
      })
    })

  }


  static validate_wallet(wallet_no,scheme_code){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/source/details/'+wallet_no+'/'+scheme_code,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        console.log(err);
        reject(err);
      })
    });
  }


  static createRecipientCategory(merchant_id, category){
    //alert(merchant_id)
    return new Promise((resolve, reject)=>{
      axios.post(API_HOST_2+'/corporatepay/recipient/category',{
        merchant_id: merchant_id,
        category: category
      },{headers: {Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        console.log(err);
        reject(err);
      })
    })

  }

  static createRecipient(country_code,name, card_num,wallet,bank, merchant_id, callback, category_id, amount, type){
    console.log(bank)
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/corporatepay/recipient',{
        name: name,
        card_num: card_num,
        wallet: wallet || "",
        scheme_code: country_code+"004",
        country_code: country_code,
        bank: bank,
        session_id: localStorage.getItem("SESSION_ID"),
        merchant_id: merchant_id,
        category_id: category_id,
        amount: amount,
        callback: callback,
        payment_type: type
      },{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log("Boogieeee!!!");
        console.log(response);
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })      
    });
  }

  static editRecipient(product_id,country_code,name, wallet,bank, merchant_id, callback, category_id, amount, type){
    console.log(bank)
    return new Promise((resolve,reject)=>{
      axios.put(API_HOST_2+'/corporatepay/recipient',{
        name: name,
        product_id: product_id,
        wallet: wallet || "",
        scheme_code: country_code+"004",
        country_code: country_code,
        bank: bank,
        session_id: localStorage.getItem("SESSION_ID"),
        merchant_id: merchant_id,
        category_id: category_id,
        amount: amount,
        callback: callback,
        payment_type: type
      },{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log("Boogieeee!!!");
        console.log(response);
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })      
    });
  }  


  static createProduct(country_code, org_no, name, wallet,bank, type, level, pmt_code, product_description,product_no, merchant_id,modulrAcct,callback, category_id, amount, display_for){
    console.log(modulrAcct);
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/products',{
        name: name,
        wallet: modulrAcct.account_number || "",
        card_num: modulrAcct.account_id,
        scheme_code: country_code+"004",
        country_code: country_code,
        type_code: type,
        level_code: level,
        organization_no: org_no,
        product_no: product_no,
        bank: bank,
        product_description: product_description,
        payment_code: pmt_code,
        session_id: localStorage.getItem("SESSION_ID"),
        merchant_id: merchant_id,
        category_id: category_id,
        amount: amount,
        display_for: display_for,
        account: {
          type: "SCAN",
          account_id: modulrAcct.account_id || "",
          accountNumber: modulrAcct.account_number || "",
          currency: modulrAcct.currency || "",
          sortCode: modulrAcct.sortCode || "",
          providerExtraInfo: "{}"          
        },
        callback: callback
      },{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log("Boogieeee!!!");
        console.log(response);
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })      
    });
  }

  static fetchRecipients(merchant_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/corporatepay/'+merchant_id,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    });
  }

  static fetchProductss(merchant_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/products/merchant/'+merchant_id,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    });
  }

  static fetchProduct(product_id,merchant_id,date){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/merchants/product/'+product_id+'/'+merchant_id+'/'+date,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    });
  }

  static fetchSingleProduct(product_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/product/'+product_id,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    });
  }

  static getModulrBalance(customer_id){
    return new Promise((resolve,reject)=>{
      let url;
      console.log(customer_id);
      url = API_HOST_2+'/psp/customer/accounts/'+customer_id;
      axios.get(url,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log("Weeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        console.log("Feeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
        reject(err);
      })

    })

    
  }

  static fetchOutstanding(merchant_id){

    let date_id = new Date(new Date().setHours(0,0,0,0));
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/merchants/products/outstanding/'+merchant_id+"/"+date_id,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    });    
  }

  static addBank(country_code,bank_code,account_no,bank_name,sort_code,route_number,merchant_id){
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/merchant/add/bank',{
        country_code: country_code,
        bank_code: bank_code,
        account_no: account_no,
        bank_name: bank_name,
        sort_code: sort_code,
        route_number: route_number,
        session_id: localStorage.getItem("SESSION_ID"),
        merchant_id:merchant_id,
      },{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    })
  }


  static createModulrAcct(customer_id){
    console.log(customer_id)
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/psp/account',{
        customer_id: customer_id,
        currency: "GBP",
      })
      .then((response)=>{
        console.log(response.data.data);
        resolve(response)
      })
      .catch((err)=>{
        reject(err);
      })
    })
  }

  static createCustomer(inc_no,reg_name,reg_address,street,postCode,director,city,country_code,ent_type){
    
    let country, currency;
    
    if(country_code==="044"){
      country = "GB";
      currency = "GBP";
    }else{
      country = "US";
      currency = "USD";
    }

    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/psp/customer',{
        country:country,
        addressLine1: reg_address,
        addressLine2: street, 
        postCode: postCode,
        postTown:city,
        firstName:director.firstName,
        lastName:director.lastName,
        middleName:"",
        dateOfBirth:"1970-01-01",
        name: reg_name,
        phone:director.phone,
        email:director.email,
        companyRegNumber:inc_no,
        externalReference: "Ref"+director.phone,
        industryCode:"90291",
        type:ent_type,
        inc_no: inc_no,
        reg_name: reg_name
      })
      .then((response)=>{
        //resolve(response);
        console.log(response.data.data);
        console.log(response.data.data.response_message);
        if(response.data.data.response_message!==""){
          //const customerId = response.data.data.response_message.id;
          axios.post(API_HOST_2+'/psp/account',{
            customer_id: response.data.data.id,
            currency: currency,
          })
          .then((response)=>{
            resolve(response)
          })
          .catch((err)=>{
            reject(err);
          })
        }else{
          reject(response);
        }
      })
      .catch((err,response)=>{
        reject(err,response);
      })
    });
  }

  static createMerchant(merchant_id,mails, inc_no, country_code, reg_name, reg_address, street, city, county, post,  entity_type, nature, director, face_remote, transaction_type, volume, value, country, bank_name,bank, sort_code, route_no, account_no, currency, password, wallet_no,account_data){
    console.log(password);
    console.log(account_data)
    if(account_data===undefined){
      account_data = {
        customerId: "",
        account: {
          accountNumber: "",
          sortCode: ""
        }
      }
    }
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/merchants',{
        merchant_id: merchant_id,
        mailing_list: mails,
        inc_no: inc_no,
        country_code: country_code,
        reg_name: reg_name,
        reg_address: reg_address,
        street_name: street,
        town: city,
        state: county,
        zip_code: post,
        ent_type: entity_type,
        nature: nature,
        director: director,
        remote: face_remote,
        trans_type: transaction_type,
        volume: volume,
        value: value,
        currency: currency,
        bank: {
          country_code: country,
          bank_code: bank, 
          sort_code: sort_code, 
          route_no: route_no, 
          account_no: account_no,
          bank_name: bank_name
        }, 
        password: password,
        merchant_default_wallet: wallet_no,
        customer_id: account_data.customer_id,
        account_details: {
          account_id: account_data.account_id || "",
          account_number: account_data.account.accountNumber || "",
          sortCode: account_data.account.sortCode || "",
          currency: account_data.currency
        }
      })
      .then((response)=>{
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })
    })
  }

  static kycDocInit(code,country,merchantScanRef){
    return new Promise((resolve,reject)=>{
      // create a new XMLHttpRequest
      let xhr = new XMLHttpRequest();
      let url;
      //if(country==="USA"){
        //url = "https://cors-anywhere.herokuapp.com/https://upload.netverify.com/api/netverify/v2/acquisitions";
      //}else{
        url = "https://cors-anywhere.herokuapp.com/https://upload.lon.netverify.com/api/netverify/v2/acquisitions";
      //}

      // open the request with the verb and the url
      xhr.open('POST', url)
      
      //set headers
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('Content-Type','application/json');
      //xhr.setRequestHeader('Content-Length', 1234);
      xhr.setRequestHeader('User-Agent', 'etranzactglobal');
      xhr.setRequestHeader('Authorization', 'Basic YzUzNjI1MjQtNTc3Yy00ZjlmLWE3NzEtYzRkZGE5MzFhYTkxOjRwMHlWdldmZzBzdWMyUW5md1Q5STBpb3ZuVHFnMnk4');
  
      // send the request
      xhr.send(JSON.stringify(
        {
          "type": "UB",
          "country": country,
          "merchantScanReference": merchantScanRef,
          "customerId": "user_"+code,
          "merchantReportingCriteria" : "myReport"+code,
          "enableExtraction" : "true",
          "authorizationTokenLifetime" : "6000",
          "customDocumentCode" : "27B6"
        }      
      ));
  
      // get a callback when the server responds 
      xhr.addEventListener('load', () => {
        // update the state of the component with the result here
        //console.log(xhr.response);
        resolve(xhr.responseText);
      });    
    })    
  }

  static kycInit(code){

    return new Promise((resolve,reject)=>{
      // create a new XMLHttpRequest
      let xhr = new XMLHttpRequest()

      // open the request with the verb and the url
      xhr.open('POST', 'https://cors-anywhere.herokuapp.com/https://lon.netverify.com/api/v4/initiate')
      
      //set headers
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('Content-Type','application/json');
      //xhr.setRequestHeader('Content-Length', 1234);
      xhr.setRequestHeader('User-Agent', 'etranzactglobal');
      xhr.setRequestHeader('Authorization', 'Basic YzUzNjI1MjQtNTc3Yy00ZjlmLWE3NzEtYzRkZGE5MzFhYTkxOjRwMHlWdldmZzBzdWMyUW5md1Q5STBpb3ZuVHFnMnk4');
  
      // send the request
      xhr.send(JSON.stringify(
        {
          "customerInternalReference" : code,
          "userReference" : code,
          "successUrl" : API_HOST_2+"/kyc/success",
          "errorUrl" : API_HOST_2+"/kyc/error",
          "callbackUrl" : API_HOST_2+"/kyc",
          "reportingCriteria" : "myReport"+code,
          "workflowId" : 200,
          "locale" : "en-GB"
        }      
      ));
  
      // get a callback when the server responds 
      xhr.addEventListener('load', () => {
        // update the state of the component with the result here
        //console.log(xhr.response);
        resolve(xhr.responseText);
      });    
    })
  } 

  static createWallet(wallet_no,city,name,zip_code,address,state,merchant_id,email,currency_code,country_code,country){
    return new Promise((resolve, reject)=>{      
      let payload = {
        country: country_code,
        zip: zip_code, 
        dateOfBirth: "09-07-1970", 
        email: email, 
        last_name: name.split(" ")[1] || " ",  
        state: state, 
        gender: "M", 
        address: address, 
        first_name: name.split(" ")[0],
        bankName: "",
        churchId: "1", 
        city: city,
        company: name,
        country: country, 
        currency: currency_code, 
        wallet_no: wallet_no, 
        scheme_code: country_code+"004",
        merchant_id: merchant_id
      }
      console.log(payload);

      axios.post(API_HOST_2+"/merchant/wallet",payload,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((res)=>{
        resolve(res);
      },(error)=>{
        reject(error);
      })
     
    });
  }

  static login(email,password,type){
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/login',{
        email: email,
        password: password,
        type: type
      })
      .then((response)=>{
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })
    })
  }

  static forgot(email){
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/forgot',{
        email: email
      })
      .then((response)=>{
        console.log(response.data)
        resolve(response);
      })
      .catch((err,response)=>{
        console.log(err);
        reject(err,response);
      })
    })    
  }

  static addWallet(merchant_id,wallet_no){
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/merchant/add/wallet', {
        session_id: localStorage.getItem("SESSION_ID"),
        merchant_id: merchant_id, 
        wallet_no: wallet_no
      },{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err);
      });
    })
  }

  static fetchOverview(merchant_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/merchants/notsent/overview/'+merchant_id,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    })

  }

  static fetchAid(merchant_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/transactions/aid/'+merchant_id,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    })

  }

  static fetchChannels(merchant_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/merchants/channels/overview/'+merchant_id,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })      
    })
  }

  static fetchCategories(merchant_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/merchant/categories/'+merchant_id,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })      
    })
  }

  static fetchRecipientCategories(merchant_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/corporatepay/categories/'+merchant_id,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })      
    })
  }

  static fetchProducts(merchant_id,channel_id,date){
    //alert(date)
    //https://etz-merchant-api.herokuapp.com/merchants/products/overview/Nw49XN9vA/cudomino 
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/merchants/products/overview/'+merchant_id+"/"+channel_id+"/"+date,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })      
    });

  }
  
  static fetchProcesses(merchant_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+"/merchants/process/"+merchant_id,{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    })
  }

  static forgotPassword(email,pin,password){
    return new Promise((resolve, reject)=>{
      axios.put(API_HOST_2+'/changepassword',{
        email: email,
        password: password,
        pin: pin
      })
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    });
  }


  static changePassword(merchant_id,password, new_password){
    console.log(merchant_id);
    return new Promise((resolve,reject)=>{
      axios.put(API_HOST_2+'/merchant/password/'+localStorage.getItem("SESSION_ID"),{
        merchant_id: merchant_id,
        password: password,
        new_password: new_password
      })
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    });
  }

  static customize(merchant_id, p_name, p_refname, education_level, utility){
    return new Promise((resolve,reject)=>{
      axios.put(API_HOST_2+'/merchant/customize/'+localStorage.getItem("SESSION_ID"),{
        merchant_id: merchant_id,
        p_name: p_name,
        education_level: education_level,
        p_refname: p_refname,
        utility: utility
      },{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    });    
  }

  static updateLogo(merchant_id, logo){
    //alert(logo);
    return new Promise((resolve,reject)=>{
      axios.put(API_HOST_2+'/merchant/logo/'+localStorage.getItem("SESSION_ID"),{
        merchant_id: merchant_id,
        logo: logo
      },{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    });    
  }  

  static lockTransactions(merchant_id,authorizer,product_id,volume,value,fees,gross,session_id,bank,currency,product_name,merchant_name,sourceAccountId,sourceWallet, card_num){
    console.log(bank);
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/process',{
        merchant_id: merchant_id,
        authorizer: authorizer,
        product_id: product_id,
        sourceWallet: sourceWallet,
        card_num: card_num,
        sourceAccountId: sourceAccountId,
        volume: volume,
        value: value,
        fees: fees,
        gross: gross,
        session_id: session_id,
        bank: bank,
        currency: currency,
        product_name: product_name,
        merchant_name: merchant_name
      },{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })
    });     
  }

  static updateGiftAid(aid,date,merchant_id){
    console.log(aid+" "+date)
    return new Promise((resolve,reject)=>{
      axios.put(API_HOST_2+'/merchant/gift_aid/'+localStorage.getItem("SESSION_ID"),{
        merchant_id: merchant_id,
        gift_aid: aid,
        gift_aid_date: date
      },{headers: { Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      })    
    });
  }

  static getCompany(companyRegNumber){
    return new Promise((resolve,reject)=>{
      console.log('https://api.companieshouse.gov.uk/company/'+companyRegNumber);
      axios.get('https://api.companieshouse.gov.uk/company/'+companyRegNumber, {headers: { Authorization: "Basic TE1GWHRpSmp2eHNPcVdJTE85cTFYNVd5Z0c4QTBQNHBoeHJzSU1VUw=="}})
      .then((response)=>{
        console.log(response)
        resolve(response);
      })
      .catch((err)=>{
        console.log(err);
        reject(err);
      })
    })
  }

  static fetchFailedWebhooks(start,end){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/psp/webhook/failed/'+start+'/'+end,{headers: {Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });      
    })
  }

  static fetchDailyReport(country,start,end){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/transactions/reports/'+start+'/'+end+'/'+country,{headers: {Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });      
    })
  }

  static fetchDailyFailed(country,start,end){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/transactions/failed/'+start+'/'+end+'/'+country,{headers: {Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });      
    })
  }  

  static fetchUKTransactions(start,end){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/psp/transactions/'+start+'/'+end,{headers: {Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });      
    })
  }  


  static fetchUKTransaction(transaction_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/psp/transaction/'+transaction_id,{headers: {Authorization: "Bearer "+localStorage.getItem("TOKEN")}})
      .then((response)=>{
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });      
    })
  }
  
  static sendWebhook(data){
    data.secret = "D@m1Kn@w2020-February-28";  
    //.then((res))

    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/psp/webhook',data,{})
      .then((response)=>{
        console.log(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });      
    })
  }

  static adminLogin(email,password){
    return new Promise((resolve,reject)=>{
      axios.post(API_HOST_2+'/administrator/auth/login',{
        username: email,
        password: password
      })
      .then((response)=>{
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })
    })
  }

  static fetchUserByLedgerID(ledger_id){
    return new Promise((resolve,reject)=>{
      axios.get(API_HOST_2+'/source/details/ledger/'+ledger_id)
      .then((response)=>{
        resolve(response);
      })
      .catch((err,response)=>{
        reject(err,response);
      })
    })    
  }

}

export default ApiService;
