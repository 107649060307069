import React, { Component } from 'react';
// ES module
import moment from 'moment';
import logo from '../../assets/img/blog/logo.png';
import logoXCel from '../../assets/img/blog/logoXCel.png';
import logoRSwitch from '../../assets/img/blog/RSwitch_Logo.jpg';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import loader from "../loading.gif";
import ReportsComponent from '../ReportsComponent/ReportsComponent'
import ProductComponents from '../ProductComponents/ProductComponents';
import NotificationComponents from '../NotificationComponents/NotificationComponent';

import CorporatePayComponents from '../CorporatePayComponents/CorporatePayComponents';
import { ApiService } from '../../services';
import { throws } from 'assert';
import reportpng from '../../assets/img/icons/report.png'

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,.15) ';
class DashboardComponent extends Component{
  constructor(){
    super();

    this.state = {
      page: 1,
      level: 1,
      product_account_id: "",
      product_wallet_no: "",
      session: JSON.parse(localStorage.getItem("SESSION")),
      pending: {},
      accounts: {},
      notifications: {},
      userS: {},
      settingsPage: 0,
      customStyles : {
          content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)'
          }
      }

      
    }
    this.setTimeout = this.setTimeout.bind(this);
    this.logout = this.logout.bind(this);
    //this.warn = this.warn.bind(this);
  }

  componentDidMount(){
    if(localStorage.getItem("SESSION")){
      this.loadPending();
      this.fetchAid();
      this.fetchOverview();
      this.fetchChannels();
      this.listProducts();
      this.listRecipients();
      this.fetchProcesses();
      this.fetchUsers();

      this.fetchNotifications();

      if(window.location.hostname==="rswitch.xcelapp.com" || window.location.hostname==="rswitch.etranzactglobal.com"){
        this.setState({logo: logoRSwitch,powered_by: true, logoClass: "logoBig m-1", headerClass: "header-topbar red-BG"});
      }else if((window.location.hostname==="merchant.etranzactglobal.com")){
        this.setState({logo: logo,powered_by: true, logoClass:"logoSmall m-1", headerClass: "header-topbar"});
      }else{
        this.setState({logo: logoXCel,powered_by: true, logoClass:"logoSmall m-1", headerClass: "header-topbar"});
      }

      if(this.state.session.country_code==="044" || this.state.session.country_code==="001"){
        this.accountBalances();
        console.log("Boobooboo")
      }
    }else{
      this.setState({logOut: true})
    }

    //alert(localStorage.getItem("START_TIME"))

    this.setTimeout()
  }


  setTimeout() {
    this.warnTimeout = setTimeout(this.warn, 2900 * 1000);

    this.logoutTimeout = setTimeout(this.logout, 3000 * 1000);
  }

  warn() {
    alert('You will be automatically logged out in 1 minute')
  }

  logout() {
    // Send a logout request to the API
    console.log("Sending a logout request to the API...");
    localStorage.removeItem("SESSION")
    this.setState({ logOut: true });
    // this.destroy(); // Cleanup
  }

  listProducts = () =>{
    ApiService.fetchProductss(this.state.session.merchant_id)
    .then((res)=>{
      console.log(res.data.data);
      if(res.data.data!==undefined){
        this.setState({products: res.data.data});
      }else{
        this.setState({products: {}});
      }
    },(error)=>{
      let zerror = error.response? error.response.data.errors:error.toString();
      this.setState({fetch_error: zerror});
    })
  }

  fetchNotifications = () =>{
    ApiService.fetchNotifications(this.state.session.merchant_id)
    .then((res)=>{
      console.log(res.data.data);
      if(res.data.data!==undefined){
        this.setState({notifications: res.data.data})
      }else{
        this.setState({notifications: {}})
      }
    },(error)=>{
      let zerror = error.response? error.response.data.errors:error.toString();
      this.setState({fetch_error: zerror});
    });
  }

  listRecipients = () =>{
    ApiService.fetchRecipients(this.state.session.merchant_id)
    .then((res)=>{
      console.log(res.data.data);
      if(res.data.data!==undefined){
        this.setState({recipients: res.data.data});
      }else{
        this.setState({recipients: {}});
      }
    },(error)=>{
      let zerror = error.response? error.response.data.errors:error.toString();
      this.setState({fetch_error: zerror});
    })
  }

  fetchOverview = ()=>{
    ApiService.fetchOverview(this.state.session.merchant_id)
    .then((response)=>{
        console.log(response);
        if(response.data.data){
          this.setState({income: response.data.data[0]});
        }
    },(error)=>{
        console.log(error);
    });

  }

  fetchAid = ()=>{
    ApiService.fetchAid(this.state.session.merchant_id)
    .then((res)=>{
      console.log(res.data.data);
      if(res.data.data){
        this.setState({aid: res.data.data[0]});
      }
    },(error)=>{
      let zerror = error.response? error.response.data.errors:error.toString();
      this.setState({fetch_error: zerror});
    })  
  }

  fetchChannels = ()=>{
    this.setState({p_refname: this.state.session.p_refname, utility: this.state.session.utility.type,p_name: this.state.session.p_name, education_level: this.state.session.education_level});
    ApiService.fetchChannels(this.state.session.merchant_id)
    .then((response)=>{
      if(response.data.data){
        console.log(response.data.data);
        this.setState({channels: response.data.data});
      }
    },(error)=>{
        console.log(error);
    });

  }

  loadPending = ()=>{
    ApiService.fetchOutstanding(this.state.session.merchant_id)
    .then((response)=>{
      if(response.data.data){
        console.log(response.data.data);
        this.setState({pending: response.data.data});
      }
    },(error)=>{
      console.log(error)
      this.setState({pending_error: "Error fetching pending!",pending:{}})
    });
  }

  changePassword = (e)=>{
    e.preventDefault();
    const oldpassword = this.state.oldpassword;
    const newpassword = this.state.newpassword;
    const confirm = this.state.cnewpassword;
    
    if(newpassword===confirm){
      this.setState({password_change: true, password_success: false, password_error: false});
      ApiService.changePassword(this.state.session.merchant_id,oldpassword,newpassword)
      .then((response)=>{
        let session = this.state.session;
        session.password = newpassword;

        this.setState({session: session});

        localStorage.setItem("SESSION",JSON.stringify(session));
        this.setState({password_success: "Password Updated", password_change: false, password_error:false})
      },(error)=>{
        let zerror = error.response? error.response.data.errors:error.toString();
        this.setState({password_error: zerror, password_change: false, password_success: false});
      })
    }else{
      this.setState({password_error: "Passwords don't match", password_success: false});
    }
  } 

  pushMessage = (e)=>{
    e.preventDefault();
    const media = this.state.push_multimedia;
    let multimedia = media;
    if(this.state.push_multimedia===true){
      multimedia = ""
    }
    const title = this.state.push_title,
          message = this.state.push_message,
          type = this.state.push_type,
          link = this.state.push_link;

    this.setState({push_change: true, push_success: false, push_error: false});
    ApiService.pushNotification(this.state.session.reg_name,title,message,type,link,multimedia,this.state.session.merchant_id)
    .then((response)=>{
      this.setState({push_success: "Message Sent", push_error: false, push_change: false, push_title:"",push_message:"",push_type:"",push_multimedia:"",push_link:""});
    },(error)=>{
      let zerror = error.response? error.response.data.errors:error.toString();
      this.setState({push_success: false, push_error: zerror, push_change: false});
    });

  }

  customize = (e)=>{
    e.preventDefault();
    const p_name = this.state.p_name;
    const p_refname = this.state.p_refname;
    const education_level = this.state.education_level;
    const utility = this.state.utility;
    
    this.setState({custom_change: true, custom_success: false, custom_error: false});
    ApiService.customize(this.state.session.merchant_id,p_name,p_refname,education_level,utility)
    .then((response)=>{
      let session = this.state.session;
      session.p_name = p_name;
      session.p_refname = p_refname;
      session.education_level = education_level;

      this.setState({session: session});

      localStorage.setItem("SESSION",JSON.stringify(session));
      this.setState({custom_success: "Customization Updated",custom_change: false, custom_error: false})
    },(error)=>{
      let zerror = error.response? error.response.data.errors:error.toString();
      this.setState({custom_error: zerror, custom_change: false, custom_success: false});
    });
  } 

  fetchProcesses = ()=>{
    ApiService.fetchProcesses(this.state.session.merchant_id)
    .then((response)=>{
        console.log("UIADMISSIONS");
        console.log(response);
        if(response.data.data){
          this.setState({processes: response.data.data})
        }
    },(error)=>{
        console.log("ADMISSIONS");
        console.log(error);
    })
  }

  fetchUsers = ()=>{
    console.log("MAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAN")
    ApiService.fetchUsers(this.state.session.country_code)
    .then((response)=>{
        console.log("BAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAN")
        console.log(response);
        if(response.data.data){
          this.setState({userS: response.data.data});
        }
    },(error)=>{
        console.log(error);
    })
  }  

  onChange = (e)=>{
    this.setState({[e.target.name]: e.target.value});

    if(e.target.name==="push_type"){
      if(e.target.value==="text"){
        this.setState({push_multimedia: true});
      }else{
        this.setState({push_multimedia: false});
      }
    }

    if(e.target.name==="push_youtube"){
      if((e.target.value).startsWith("https://youtu.be/")){
        this.setState({push_multimedia: e.target.value,push_multimedia_error: false})
      }else{
        this.setState({push_multimedia_error: true,push_multimedia: false})
      }
    }

    if(e.target.name==="push_video"){
      if((e.target.value).endsWith(".mp4")){
        this.setState({push_multimedia: e.target.value,push_multimedia_error: false})
      }else{
        this.setState({push_multimedia_error: true,push_multimedia: false})
      }
    }    

    if(e.target.name==="push_soundcloud"){
      if((e.target.value).endsWith(".mp3")){
        this.setState({push_multimedia: e.target.value,push_multimedia_error: false})
      }else{
        this.setState({push_multimedia_error: true,push_multimedia: false})
      }
    }

    if(e.target.name === "image_file"){
      console.log(e.target.files[0]);
      this.setState({selectedFile: e.target.files[0],uploaded: false})
    }
  }

  validateBank=()=>{
    if(this.state.session.country_code!=="044"){
    this.setState({validating_account: true});
    ApiService.validate_account(this.state.product_bank.account_no,this.state.product_bank.bank_code)//this.state.product_bank.country_code)
    .then((response)=>{
      console.log(response);
      let resp = response.data;
      //let resp = JSON.parse(response)
      if(resp.response_code==0){
        this.setState({validating_account: false, account_details: resp, validated_account: true});
      }else{
        this.setState({validating_account: false, account_details_error: resp.response_message});
      }
      
    },(error)=>{
      this.setState({validating_account: false, account_details_error: "Error validating account"});
    })
    }else{
      let account_details = {
        response_data: {
          account_type: "GB",
          response_message: this.state.product_bank.bank_name,
          beneficiary_account: this.state.product_bank.account_no,
          country_code: this.state.product_bank.country_code,
          currency: this.state.session.currency.currency_code,
          bank_code: this.state.product_bank.bank_code,
          sort_code: this.state.product_bank.sort_code,
          route_no: "",
          IBAN: "",
        }
      }
      this.setState({account_details: account_details, validated_account: true})
    }
  }

  lockTrans = ()=>{
  
    console.log(this.state.account_details);
    const bank = {
      account_type: this.state.account_details.account_type,
      account_name: this.state.account_details.response_message,
      bank_code: this.state.account_details.bank_code,
      account_no: this.state.account_details.beneficiary_account,
      country_code: this.state.account_details.country_code,
      currency: this.state.account_details.currency,
      name: this.state.account_details.response_message,
      bank_name: this.state.product_bank.bank_name,
      sort_code: this.state.account_details.sort_code || "",
      route_no: "",
      IBAN: ""
    }

    console.log(bank);

    const currency = this.state.session.currency.currency_code;
    const merchant_id = this.state.session.merchant_id; 
    const authorizer = this.state.session.director.name;
    const product_id = this.state.product_id;
    const volume = this.state.product_volume;
    const value = parseFloat(this.state.product_value)-parseFloat(this.state.product_fees);
    const fees = parseFloat(this.state.product_fees);
    const gross = parseFloat(this.state.product_value);
    const session_id = localStorage.getItem("SESSION_ID");
    const product_name = this.state.product_name
    const merchant_name = this.state.session.reg_name;
    const account_id = this.state.product_account_id;
    const wallet = this.state.product_wallet_no;
    const card_num = this.state.product_card_num

    
    this.setState({locking: true});
    ApiService.lockTransactions(merchant_id,authorizer,product_id,volume,value,fees, gross, session_id,bank,currency,product_name,merchant_name,account_id,wallet,card_num)
    .then((response)=>{
      this.setState({locking: false,locked: true,pending: {},lock_message: "Signatures Emails sent: Please inform signatories to sign Transaction"});
      this.loadPending();
      this.fetchProcesses();
      console.log(response);
    },(error)=>{
      console.log(error);
      let zerror = error.response? error.response.data.errors:error.toString();
      this.setState({locking: false, lock_error: zerror})
    });
  }

  accountBalances = ()=>{
    console.log(this.state.session.customer_id)
    ApiService.getModulrBalance(this.state.session.customer_id)
    .then((res)=>{
      //console.log(res.data.availableBalance) 
      console.log("Geeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
      console.log(res.data.data);
      this.setState({accounts: res.data.data})
    },(error)=>{  
      console.log("Beeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
      console.log(error);
    })
  }

  openModal=(index)=>{
    console.log(this.state.pending[index].product[0]);
    this.setState({product_fees:this.state.pending[index].fees,product_volume:this.state.pending[index].volume,product_value:this.state.pending[index].value,product_id: this.state.pending[index].product[0].product_id,product_name:this.state.pending[index].product[0].name,modalIsOpen: true,product_bank:this.state.pending[index].product[0].bank,product_value:this.state.pending[index].value})
  }

  numberWithCommas(x) {
    x = (Math.round(x * 100) / 100).toFixed(2)
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  updateGiftAid = (e)=>{
    e.preventDefault();
    const gift_aid = this.state.gift_aid || false;
    const gift_aid_date = this.state.gift_aid_date || new Date();

    console.log(gift_aid+" "+gift_aid_date)

    this.setState({updating_gift_aid: true, gift_aid_error: "", gift_aid_success: ""});
    ApiService.updateGiftAid(gift_aid,gift_aid_date,this.state.session.merchant_id)
    .then((response)=>{
      let session = this.state.session;
      session.gift_aid = gift_aid;
      session.gift_aid_date = gift_aid_date;
      localStorage.setItem("SESSION",JSON.stringify(session));
      this.setState({gift_aid_success:"Settings Updated", updating_gift_aid: false,session:session})
    },(error)=>{
      console.log(error);
      let zerror = error.response? error.response.data.errors:error.toString();
      this.setState({gift_aid_error: zerror, updating_gift_aid: false})
    })
  }

  changeDP = (e)=>{
    e.preventDefault();
    let img = this.state.selectedFile;
    console.log(img)
    this.setState({dp_change: true, dp_error: false, dp_success: false});
    ApiService.upload(img)
    .then((result)=>{
      let multimedia = result.data.data.url;
      //alert(multimedia);
      ApiService.updateLogo(this.state.session.merchant_id,multimedia)
      .then((response)=>{
        let session = this.state.session;
        session.logo = multimedia;
        localStorage.setItem("SESSION",JSON.stringify(session));
        this.setState({dp_change: false,dp_success:"Logo Updated",session:session})
      },(error)=>{
        let zerror = error.response? error.response.data.errors:error.toString();
        this.setState({dp_error: zerror,dp_change:true});
      });        
    },(error)=>{
      let zerror = error.response? error.response.data.errors:error.toString();
      this.setState({dp_error: zerror,dp_change:true});
    });
  }

  render(){

    let Pending = [];
    if(Object.keys(this.state.pending).length>0){
      console.log(this.state.pending);
      Pending = this.state.pending.map((data,index)=>{
          console.log(data);
        if(data.volume>0){
          return <tr>
            <td className="whiteBG" onClick={()=>{this.setState({transShow:1, transIndex:index})}}>{Object.keys(data.product).length>0?data.product[0].name:null}</td>
            <td>{data.volume}</td>
            <td>{this.state.session.currency.currency_code}<label className="float-right">{this.numberWithCommas(data.value)}</label></td>
            <td><center><button className="btn btn-dark btn-sm text-capitalize" onClick={()=>{
              this.openModal(index); 
              if(data.country_code==="044" || data.country_code==="001"){
                this.setState({product_account_id: data.product[0].account_id}); 
              }else{
                this.setState({product_wallet_no: data.product[0].wallet,product_card_num: data.product[0].card_num}); 
              }
              
              }}><b>Move to Bank</b></button></center></td>
          </tr>
        }
      }).filter(Boolean);
    }

    let Users
    /**if(Object.keys(this.state.userS).length>0){
      Users = this.state.userS.map((data,index)=>{
        console.log("")
        return <tr>
          <td>{data.wallet_no}</td>
          <td>{data.first_name}</td>
          <td>{data.last_name}</td>
          <td>{data.app_name}</td>
          <td>{data.platform}</td>
        </tr>
      }); 
    }**/
    let pendingTransactions = [];

    if(this.state.transShow===1){
      if(Object.keys(this.state.pending[this.state.transIndex].transactions).length>0){
          pendingTransactions = this.state.pending[this.state.transIndex].transactions.map((data,index)=>{
              return <tr>
              <td className="whiteBG">{index+1}</td>
              <td>{moment(data.date).format('DD-MM-YYYY')}</td>
              <td>{moment(data.date).format('LT')}</td>
              <td>{data.ref_no || data.src_account}</td>
              <td>{data.channel_id}</td>
              <td>{data.src_first_name} {data.src_last_name}</td>
              <td>{this.state.session.currency.currency_code}<span className="float-right">{this.numberWithCommas(data.des_amount_paid)}</span></td>
              <td>{data.description}</td>
              <td><center><input type="checkbox" checked={data.sent} disabled/></center></td>
              </tr>
          })
      }
    }

    const activeClass1 = `dropdown ${this.state.page===1 ? " active" : ""}`;
    const activeClass2 = `dropdown ${this.state.page===2 ? " active" : ""}`;
    const activeClass3 = `dropdown ${this.state.page===3 ? " active" : ""}`;
    const activeClass5 = `dropdown ${this.state.page===5 ? " active" : ""}`;
    const activeClass6 = `dropdown ${this.state.page===6 ? " active" : ""}`;
    const activeClass7 = `dropdown ${this.state.page===7 ? " active" : ""}`;
    const activeClass8 = `dropdown ${this.state.page===8 ? " active" : ""}`;
    
    let banks, wallets;
    if(localStorage.getItem("SESSION")){

      /**if(this.state.session.country_code!=="044" && this.state.session.country_code!=="001"){
        if(Object.keys(this.state.session.associated_wallets).length>0){
            wallets = this.state.session.associated_wallets.map((wallet,index)=>{
              if(wallet!=="" && wallet!==undefined){
                return <tr><td className="whiteBG">{wallet}</td><td onClick={()=>{this.setState({})}}><label className="text-blue float-right pointer">view statement</label></td></tr>
              }
            }).filter(Boolean);
        }
      }else{**/
        console.log('green')
        console.log(this.state.session.associated_accounts)
        if(Object.keys(this.state.session.associated_accounts).length>0){
          wallets = this.state.session.associated_accounts.map((wallet,index)=>{
            //console.log(wallet.identfiers);
            return <tr><td className="whiteBG">{wallet.account_number} {wallet.sort_code}</td><td><label className="text-blue float-right pointer">view statement</label></td></tr>
          });
        }      
      //}

      if(Object.keys(this.state.session.associated_banks).length>0){
          banks = this.state.session.associated_banks.map((bank,index)=>{
            return <tr><td className="whiteBG">{bank.bank_name}</td><td>{bank.account_no}</td><td><center><button className="btn btn-dark text-capitalize">Edit</button><button className="ml-3 btn btn-outline-dark">Remove</button></center></td></tr>  
            //return <option value={index+1}>{bank.account_no} ({bank.bank_name})</option>
          });
      }
    }

    console.log(this.state.session);
    if(!this.state.logOut && localStorage.getItem("SESSION")){
      return <div>
              <div className="page-wrapper">
                      <header className="header">
                          <div className={this.state.headerClass}>
                              <div className="wrapper d-flex container pt-3 pb-3">
                                  <div className="p-2">
                                      <a >
                                          <img alt="logo" src={this.state.logo} className={this.state.logoClass}/>
                                      </a>
                                  </div>
                              </div>
                          </div>
                          <div className="top-navbar">
                              <div className="wrapper container" id="navbar-wrapper">
                                  <ul className="nav-menu">
                                    <li className={activeClass1} onClick={()=>{this.setState({page:1})}}><a>Dashboard</a></li>
                                    <li className={activeClass2} onClick={()=>{this.setState({page:2})}}><a>{!this.state.session.p_name?'Products':this.state.session.p_name}</a></li>
                                    <li className={activeClass3} onClick={()=>{this.setState({page:3})}}><a>Transactions</a></li>
                                    <li className={activeClass6} onClick={()=>{this.setState({page:6})}}><a>Notifications</a></li>
                                    <li className={activeClass7} onClick={()=>{this.setState({page:7})}}><a>Bulk Pay</a></li>
                                    <li className={activeClass8} onClick={()=>{this.setState({page:8})}}><a>API</a></li>
                                    <li className={activeClass5} onClick={()=>{this.setState({page:5})}}><a>Settings</a></li>
                                  </ul>
                              </div>
                          </div>

                      </header>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-lg-1">
                              
                            </div>
                            <div className="col-lg-10">
                              <div className="wrapper">
                                <br/>
                                <div className="flexbox-b mt-3 mb-3 ml-2 row col-md-12">
                                  <span className="mr-4 static-badge badge-info">{!this.state.session.logo?<i className="fa-building fa"></i>:<img src={this.state.session.logo}/>}</span>
                                  <div>
                                    <h5 className="font-strong">{this.state.session.reg_name}</h5>
                                    <div className="text-light">{this.state.session.ent_type} {this.state.session.country_code==="044" && this.state.truth && (this.state.session.ent_type==="CHARITY" || this.state.session.ent_type==="RELIGIOUS")?
                                    <button className="btn btn-info btn-sm ml-5 text-capitalize bold" onClick={()=>{this.setState({modalIsOpen: true, giftAid: true, gift_aid: this.state.session.gift_aid, gift_aid_date: this.state.session.gift_aid_date})}}>Manage HMRC Audit</button>:null}</div>
                                  </div>
                                  <div className="col-md-8">
                                  </div>
                                  <button className="btn btn-danger btn-sm text-capitalize float-right bold" onClick={()=>{this.setState({logginOut: true, modalIsOpen: true})}}>Log Out</button>
                                </div>
                              {this.state.page===8?
                                <div className="ibox">
                                  <span className="p-3">
                                    <h5 className="ml-4"><b>Integrate with our APIs</b></h5>
                                  </span>

                                  <span><center><button className="btn btn-dark btn-md">Request API Keys</button></center></span>   
                                  <br/>
                                  <center><h5>The API Key would be sent to your Director's Email</h5></center>
                                  <br/>                             
                                </div>
                              :this.state.page===1?<div className="ibox">
                                <ReportsComponent merchant_id={this.state.session.merchant_id} aid={this.state.aid} income={this.state.income} processes={this.state.processes} channels={this.state.channels}/>
                              </div>:this.state.page===2?<div className="ibox">
                                <ProductComponents session={this.state.session} products={this.state.products}/>
                              </div>             
                              :this.state.page===3?<div className="ibox">
                              <div>
                                <span className="p-3">
                                  <h5 className="ml-4"><b>Transactions</b></h5>
                                </span>
                                <div className="pr-3 pl-3 pb-3 tab-content">
                                  <div className="limit-width">  
                                  {this.state.transShow!==1?<table className="specialTable col-12">
                                        <thead>
                                          <tr className="specialTable">
                                            <th>Product</th>
                                            <th>Volume</th>
                                            <th>Value</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Pending}
                                        </tbody>
                                      </table>:null}
                                      {Object.keys(Pending).length===0?<div className="solidBorder">
                                          <center><img src={reportpng} className="m-3 smallImg"/></center>
                                          <center><h3 className="eee"><b>No Transactions</b></h3></center>
                                      </div>:null}
                                      {this.state.transShow===1?<div>
                                          <p className="pl-4 pr-4 pt-4 pb-1">
                                              <div className="breadcrumb"><small><a onClick={()=>{this.setState({transShow:0})}} className="text-blue">Unsettled Transactions</a> / Transactions</small></div>
                                          </p>
                                          <div className="pr-5 pl-5 pb-5">
                                          <div className="limit-width">
                                              <table className="specialTable col-12">
                                                  <thead>
                                                      <tr className="specialTable">
                                                          <th>S/N</th>
                                                          <th>Date</th>
                                                          <th>Time</th>
                                                          <th>{this.state.session.p_refname?''+this.state.session.p_refname+'':'Reference Number'}</th>
                                                          <th>Channel</th>
                                                          <th>Full Name</th>
                                                          <th>Amount Received</th>
                                                          <th>Description</th>
                                                          <th>Settled</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      {pendingTransactions}
                                                  </tbody>
                                              </table>
                                          </div>
                                          </div>
                                      </div>:null}
                                  </div>
                                </div>
                              </div>
                            </div>:this.state.page===5?<div className="ibox">
                              <div>
                                <span className="p-3">
                                  {this.state.settingsPage===0?
                                    <h5 className="ml-4"><b>Settings</b></h5>:
                                  this.state.settingsPage===1?
                                    <h5 className="ml-4" onClick={()=>{this.setState({settingsPage: 0})}}><i className="fa fa-chevron-left"></i><b> Change Pin</b></h5>:
                                  this.state.settingsPage===2?
                                    <h5 className="ml-4" onClick={()=>{this.setState({settingsPage: 0})}}><i className="fa fa-chevron-left"></i><b> Change Password</b></h5>:
                                  this.state.settingsPage===3?
                                    <h5 className="ml-4" onClick={()=>{this.setState({settingsPage: 0})}}><i className="fa fa-chevron-left"></i><b> Manage Bank Accounts</b></h5>:
                                  this.state.settingsPage===4?
                                    <h5 className="ml-4" onClick={()=>{this.setState({settingsPage: 0})}}><i className="fa fa-chevron-left"></i><b> Account Statements</b></h5>:
                                  this.state.settingsPage===5?
                                    <h5 className="ml-4" onClick={()=>{this.setState({settingsPage: 0})}}><i className="fa fa-chevron-left"></i><b> Customization</b></h5>:
                                  this.state.settingsPage===6?
                                    <h5 className="ml-4" onClick={()=>{this.setState({settingsPage: 0})}}><i className="fa fa-chevron-left"></i><b> Change Logo</b></h5>
                                  :null}
                                </span>
                                {this.state.settingsPage===0?<div className="pr-3 pl-3 pb-3 tab-content">
                                  <div className="border1 specialHover" onClick={()=>{this.setState({settingsPage: 2})}}><h5 className="p-3"><b>Change Password</b></h5></div>
                                  <div className="border1 specialHover" onClick={()=>{this.setState({settingsPage: 4})}}><h5 className="p-3"><b>Account Statements</b></h5></div>
                                  <div className="border1 specialHover" onClick={()=>{this.setState({settingsPage: 5})}}><h5 className="p-3"><b>Customization</b></h5></div>
                                  <div className="border1 specialHover" onClick={()=>{this.setState({settingsPage: 6})}}><h5 className="p-3"><b>Change Logo</b></h5></div>
                                </div> :null}
                                {this.state.settingsPage==1?<div className="ibox-body tab-content">
                                  <form onSubmit={this.changePassword}>
                                  <div className="form-group mb-4">
                                    <label for="form16">Old Pin</label>
                                    <input name="password" value={this.state.oldpin} type="password" placeholder="Old Pin" className="form-control"/>
                                  </div>
                                  <div className="form-group mb-4">
                                    <label for="form16">New Pin</label>
                                    <input name="newpassword" type="password" value={this.state.newpin} placeholder="New Pin" className="form-control"/>
                                  </div>
                                  <div className="form-group mb-4">
                                    <label for="form16">Confirm New Pin</label>  
                                    <input name="cnewpassword" type="password" value={this.state.cnewpin} placeholder="Confirm New Pin" className="form-control"/>
                                  </div>
                                  <button className="btn btn-dark text-capitalize">Change Pin</button>
                                  </form>
                                </div>:null} 
                                {this.state.settingsPage==2?<div className="ibox-body tab-content">
                                  <form onSubmit={this.changePassword}>
                                    <div className="form-group mb-4">
                                      <label for="form16">Old Password</label>
                                      <input name="oldpassword" onChange={this.onChange} value={this.state.oldpassword} type="password" placeholder="Old Password" className="form-control"/>
                                    </div>
                                    <div className="form-group mb-4">
                                      <label for="form16">New Password</label>
                                      <input name="newpassword" onChange={this.onChange} type="password" value={this.state.newpassword} placeholder="New Password" className="form-control"/>
                                    </div>
                                    <div className="form-group mb-4">
                                      <label for="form16">Confirm New Password</label>  
                                      <input name="cnewpassword" onChange={this.onChange} type="password" value={this.state.cnewpassword} placeholder="Confirm New Password" className="form-control"/>
                                    </div>
                                    {!this.state.password_change?<button className="btn btn-dark text-capitalize">Change Password</button>:<img src={loader} className="smallLoader"/>}
                                    {this.state.password_error?<h5 className="text-danger">{this.state.password_error}</h5>:null}
                                    {this.state.password_success?<h5 className="text-primary">{this.state.password_success}</h5>:null}
                                  </form>
                                </div>:null}

                                {this.state.settingsPage==3?<div className="ibox-body tab-content">
                                  <table className="specialTable col-12">
                                    <thead>
                                        <tr className="specialTable">
                                          <th>Bank Name</th>
                                          <th>Account Number</th>
                                          <th></th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                      <tr>
                                        <td className="whiteBG">{this.state.session.bank.bank_name}</td>
                                        <td>{this.state.session.bank.account_no}</td>
                                        <td><center><button className="btn btn-primary">Edit</button></center></td>
                                      </tr>
                                      {banks}
                                    </tbody>
                                  </table>  
                                </div>:null} 
                                {this.state.settingsPage==4?<div className="ibox-body tab-content">
                                  <table className="specialTable col-12">
                                    <thead>
                                        <tr className="specialTable">
                                          {this.state.session.country_code!=="044" && this.state.session.country_code!=="001"?<th>Wallet Number</th>:<th>Account</th>}
                                          <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                      {this.state.session.country_code==="044" || this.state.session.country_code==="001"?
                                        null
                                      :this.state.session.merchant_default_wallet?<tr>
                                        <td className="whiteBG">{this.state.session.merchant_default_wallet} </td> <td><a className="text-blue float-right pointer">view statement</a></td></tr>:null}
                                      {wallets}
                                    </tbody>
                                  </table>
                                </div>:null}

                                {this.state.settingsPage==5?<div className="ibox-body tab-content">
                                  <form onSubmit={this.customize}>
                                  <div className="form-group mb-4">
                                    <label for="form16">What do you call your products?</label>
                                    <input name="p_name" required value={this.state.p_name} onChange={this.onChange} type="text" placeholder="Fees? Tuition? Services?" className="form-control"/>
                                  </div>
                                  <div className="form-group mb-4">
                                    <label for="form16">Are you a Utility Service Provider?</label>
                                    <select required name="utility" value={this.state.utility} className="form-control" onChange={this.onChange}>
                                      <option placeholder="Utility Type">Utility Type</option>
                                      <option value="NONE">No</option>
                                      <option value="CABLE">Cable</option>
                                      <option value="ELECTRICITY">Electricity</option>
                                      <option value="WATER">Water</option>
                                      <option value="TAX">Taxes</option>
                                    </select>
                                  </div>
                                  <div className="form-group mb-4">
                                    <label for="form16">What is your reference identifier called?</label>
                                    <input name="p_refname" required value={this.state.p_refname} onChange={this.onChange} type="text" placeholder="Matric Number? Card Number?" className="form-control"/>
                                  </div>
                                  {this.state.session.ent_type==="EDUCATIONAL"?<div className="form-group mb-4">
                                    <label for="form16">Level of Education?</label>
                                    <select required name="education_level" value={this.state.education_level} className="form-control" onChange={this.onChange}>
                                      <option placeholder="Education Level">Education Level</option>
                                      <option value="ALL">All</option>
                                      <option value="PRIMARY">Primary</option>
                                      <option value="SECONDARY">Secondary</option>
                                      <option value="TERTIARY">Tertiary</option>
                                    </select>
                                  </div>:null}
                                  {!this.state.custom_change?<button className="btn btn-dark text-capitalize">Change</button>:<img src={loader} className="smallLoader"/>}
                                  {this.state.custom_error?<h5 className="text-danger">{this.state.custom_error}</h5>:null}
                                  {this.state.custom_success?<h5 className="text-primary">{this.state.custom_success}</h5>:null}
                                  </form>
                                </div>:null}    

                                {this.state.settingsPage==6?<div className="ibox-body tab-content">

                                  <form onSubmit={this.changeDP}>
                                    <div className="form-group mb-4">
                                      <label for="form16">Select Logo</label>
                                      <input type="file" name="image_file" accept="image/*" onChange={this.onChange} className="form-control"></input>
                                    </div>
                                    {!this.state.dp_change?<button className="btn btn-dark text-capitalize">Change</button>:<img src={loader} className="smallLoader"/>}
                                    {this.state.dp_error?<h5 className="text-danger">{this.state.dp_error}</h5>:null}
                                    {this.state.dp_success?<h5 className="text-primary">{this.state.dp_success}</h5>:null}
                                  </form>
                                </div>:null}
                              </div>  
                            </div>:this.state.page===6?<div className="ibox"><NotificationComponents notifications={this.state.notifications}/></div>:this.state.page===7?<div className="ibox">
                              <CorporatePayComponents session={this.state.session} recipients={this.state.recipients}/>
                            </div>:null}
                            
                            
                            
                            <br/>
                            </div>
                            <h5 className="ml-4">Powered by <img src={this.state.logo} className="tinyLogo"/></h5>
                            </div>                                                    
                            <div className="col-lg-2">
                              
                            </div>
                          </div>
                        </div>

                        

                      </div>
                  </div>
              <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              shouldCloseOnOverlayClick={false}
              style={this.state.customStyles}
              contentLabel="Example Modal">
                {this.state.giftAid?<div>
                  <h4><b>Manage HMRC Audit</b></h4>
                  <form className="p-3" onSubmit={this.updateGiftAid}>
                    <div className="form-group mb-4">
                      <label>Enable or Disable</label>
                      <select className="form-control" value={this.state.gift_aid || false} onChange={this.onChange} name="gift_aid">
                          <option>Enable/Disable?</option>
                          <option value={true}>Enable</option>
                          <option value={false}>Disable</option>
                      </select>
                    </div>
                    {this.state.gift_aid?<div className="form-group mb-4">
                      <label>Expiry Date?</label>
                      <input className="form-control" type="date"  value={this.state.gift_aid_date} onChange={this.onChange} name="gift_aid_date"/>
                    </div>:null}
                    {this.state.gift_aid_success?<label className="text-success">{this.state.gift_aid_success}</label>:null}
                    {this.state.gift_aid_error?<label className="text-danger">{this.state.gift_aid_error}</label>:null}<br/>
                    {!this.state.updating_gift_aid?<div className="btn-group">
                      <button className="btn" onClick={(e)=>{e.preventDefault(); this.setState({giftAid: false, modalIsOpen: false})}}>Cancel</button>
                      <button className="btn btn-dark">Submit</button>
                    </div>:<img src={loader} className="smallLoader"/>}
                    
                  </form>

                </div>:<div>{!this.state.logginOut?<div>{!this.state.validated_account?<div>
                  <div><h3><b>Moving money to the bank?</b></h3></div>
                  <p>
                    Clear & Forward pending earnings from <label className="text-primary">{this.state.product_name}</label> 
                  </p>
                  <p>Gross Amount: <b>{this.state.session.currency.currency_code} {this.numberWithCommas(this.state.product_value)}</b></p>
                  <p>Fees: <b>{this.state.session.currency.currency_code} {this.numberWithCommas(this.state.product_fees)}</b></p>
                  <p>Net Amount: <b>{this.state.session.currency.currency_code} {this.numberWithCommas((this.state.product_value-this.state.product_fees))}</b></p>
                  {this.state.product_bank?<p>Bank Account: <b>({this.state.product_bank.bank_name}) {this.state.product_bank.account_no}</b></p>:null}
                  {this.state.account_details_error?<div><label className="text-danger">{this.state.account_details_error}</label><br/></div>:null}
                  {!this.state.validating_account?<div className="btn-group"><center>
                    <button className="btn btn-dark" onClick={this.validateBank}>Yes</button><button onClick={()=>{this.setState({modalIsOpen: false, validated_account: false})}} className="btn btn-primary-dark">No</button></center></div>
                  :<img src={loader} className="smallLoader"/>}
                </div>:<div>
                  {this.state.account_details?<div>
                  <h4>Transfer {this.state.product_name} of <b>{this.state.session.currency.currency_code}{this.numberWithCommas((this.state.product_value-this.state.product_fees))}</b> to <b>{this.state.product_bank.bank_name}</b></h4>
                  <h5>Are you sure you want to transfer {this.state.session.currency.currency_code}{(this.state.product_value-this.state.product_fees)} to  <br/><label className="text-primary">{this.state.account_details.response_message}</label> ({this.state.product_bank.bank_name})?</h5>

                  {this.state.lock_message?<h5 className="text-primary">{this.state.lock_message}</h5>:null}
                  {this.state.lock_error?<h5 className="text-primary">{this.state.lock_error}</h5>:null}
                  {!this.state.locking?<div className="btn-group"><center>{!this.state.locked?<button className="btn btn-dark" disabled={this.state.locked} onClick={this.lockTrans}>Yes</button>:null}
                  <button onClick={()=>{this.setState({modalIsOpen: false, validated_account: false,locked:false, lock_message: false, lock_error: false})}} className="btn btn-primary-dark">{!this.state.locked?"No":"Close"}</button></center></div>
                  :<img src={loader} className="smallLoader"/>}
                  </div>:<div>
                    <h3><b>{this.state.account_details.message}</b></h3>
                    {this.state.lock_error?<label className="text-danger">{this.state.lock_error}</label>:null}
                    <button onClick={()=>{this.setState({modalIsOpen: false, validated_account: false})}} className="btn btn-primary-dark">Close</button>
                  </div>}
                </div>}</div>:
                  <div className="p-3">
                    <h4 className="p-3">Are you sure you want to logout?</h4>
                    <div className="float-right">
                      <button onClick={()=>{this.setState({modalIsOpen: false, logginOut: false})}} className="btn btn-primary-dark btn-md text-capitalize">No</button>
                      <button onClick={()=>{this.setState({modalIsOpen: false, logOut: true}); localStorage.removeItem("SESSION")}} className="ml-2 btn btn-dark btn-md text-capitalize">Yes</button>
                    </div>
                  </div>
                }</div>}
              </Modal>
      </div>
    }else{
      return <Redirect to="/"/>
    }  
  }
}

export default DashboardComponent;
